import styled from 'styled-components';
import {Heading6} from '../Heading/Heading';
import {Text} from '../Text/Text';
import {ActionButton} from '../Button/Button';
import {useCookieBannerContext} from '../../contexts/CookieBannerContext';
import {useTranslation} from 'react-i18next';

const StyledShouldAllowCookies = styled.article`
    padding: 80px 20px;
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    background-color: var(--color-light-blue-40);

    ${Heading6} {
        margin-bottom: 5px;
    }

    ${Text} {
        margin-bottom: 20px;
        max-width: 250px;
    }
    
    @media screen and (max-width: 768px) {
        padding: 60px 20px;
    }
`;

export const ShouldAllowCookies = () => {
    const { t } = useTranslation();
    const {openCookieBanner} = useCookieBannerContext();

    return (
        <StyledShouldAllowCookies>
            <Heading6>{t('cookieBanner.notice_third_party_title')}</Heading6>
            <Text>{t('cookieBanner.notice_third_party_text')}</Text>
            <ActionButton onClick={openCookieBanner} $variant="outline">{t('cookieBanner.notice_third_party_button')}</ActionButton>
        </StyledShouldAllowCookies>
    );
}