import styled from 'styled-components';

export const Container = styled.section`
    max-width: ${props => props.$big ? '2048px' : props.$small ? '930px' : '1360px'};
    margin: 0 auto;
    padding: ${props => props.$big ? '0' : '0 40px'};
    
    @media screen and (max-width: 1024px) {
        padding: ${props => props.$big ? '0' : '0 20px'};
    }

    @media screen and (max-width: 768px) {
        padding: 0 20px;
    }

    @media screen and (max-width: 320px) {
        padding: 0 10px;
    }
`;

export const Section = styled.section`
    background-color: ${({$backgroundColor}) => 
        $backgroundColor === 'lightBlue' ? 'var(--color-light-blue-40)' :
        $backgroundColor === 'darkBlue' ? 'var(--color-dark-blue)' :
        $backgroundColor === 'yellow' ? 'var(--color-yellow)' : 'var(--color-background)'
    };

    // Size = the spacing between block sections
    --size: ${({$pageType, $blockType}) => ($pageType === 'knowledgecenter-page' || $blockType === 'direct-to-link') ? '60px' : '80px'};

    ${({$pageType, $blockType}) => ($pageType !== 'knowledgecenter-page' && $blockType === 'direct-to-link') && `
        @media screen and (min-width: 769px) {
            margin-bottom: -20px;
            z-index: 1;
        }
    `}
    
    ${({$paddingTop}) => $paddingTop && 'padding-top: var(--size);'};
    ${({$paddingBottom}) => $paddingBottom && 'padding-bottom: var(--size);'};
    
    ${({$marginTop}) => $marginTop && 'margin-top: var(--size);'};
    ${({$marginBottom}) => $marginBottom && 'margin-bottom: var(--size);'};
    
    ${({$scroll}) => $scroll && 'scroll-margin-top: 63px'};
    
    @media screen and (max-width: 1024px) {
        ${({$scroll}) => $scroll && 'scroll-margin-top: 58px'};
    }
    
    @media screen and (max-width: 768px) {
        --size: ${({$pageType}) => $pageType === 'knowledgecenter-page' ? '40px' : '60px'};
    }
`;

export const ConditionalWrap = ({condition, wrap, fallback, children}) => {
    return condition ? wrap(children) : fallback ? fallback(children) : children;
}