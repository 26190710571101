import styled from 'styled-components';
import {Text} from '../Text/Text';
import {useNavigation} from 'react-router-dom';
import {Overlay} from '../../routes/KnowledgeCenter/KnowledgeCenterItems';
import {disableFilterEvent, enableFilterEvent} from '../../events/knowledgeCenter';
import {useTranslation} from 'react-i18next';
import {parseItalic} from '../../helpers/parseItalic';

const StyledFilterTagcloud = styled.div`
    position: relative;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
`;

const Label = styled(Text).attrs({
    as: 'label',
    $small: true,
})`
    cursor: pointer;
    display: inline-block;
    border: 1px solid var(--color-stroke);
    border-radius: 4px;
    padding: 2px 6px;
`;

const CheckboxInput = styled.input`
    display: none;

    &:checked + ${Label} {
        background-color: var(--color-dark-blue);
        border: 1px solid var(--color-dark-blue);
        color: var(--color-white);
        font-weight: var(--fw-semi-bold);
    }
`;

export const FilterTagcloud = ({filter, toggleFn}) => {
    const { t } = useTranslation();

    const handleInputChange = (dataId, itemId, checkedBool) => {
        toggleFn(dataId, itemId, checkedBool);
    }

    const navigation = useNavigation();
    const filtering = navigation.location;

    return (
        <StyledFilterTagcloud>
            {filter?.filterItems?.length > 0
                ? (
                    <>
                        {filter.filterItems.map(item => (
                            <label key={item.id}>
                                <CheckboxInput className={item.active} type='checkbox' id={item.title} name={item.title} checked={item.active ?? false} onChange={(e) => {handleInputChange(filter.id, item.id, e.target.checked); e.target.checked ? enableFilterEvent(filter.titleEn, item.titleEn) : disableFilterEvent(filter.titleEn, item.titleEn)}}/>
                                <Label htmlFor={item.title}>{parseItalic(item.title)}</Label>
                            </label>
                        ))}
                    </>
                ) : (
                    <Text $small>{t('knowledgeCenter.specify_no_tags')}</Text>
                )
            }

            {filtering &&
                <Overlay />
            }


        </StyledFilterTagcloud>
    );
}