import {useCurrentLangParam} from '../../hooks/useCurrentLangParam';
import {Link, useRouteLoaderData, useSearchParams} from 'react-router-dom';

export const CustomLink = ({className, children, to = "/", type, ...props}) => {
    const {currentLang} = useCurrentLangParam();
    const {footerData} = useRouteLoaderData("root");
    const knowledgeCenterLink = footerData?.knowledgecenter?.endsWith('/') ? footerData?.knowledgecenter?.slice(0, -1) : footerData?.knowledgecenter;
    const [searchParams,] = useSearchParams();
    const isCMSPreview = searchParams.get("CMSPreview") === "1";

    if (!to) {return null;}

    const isHashLink = type === "internal" && to?.includes("#");

    function generateHashLink(link) {
        const url = new URL(link, window.location.origin);
        url.search = currentLang;

        return url.href;
    }

    if(isCMSPreview) to = `${to}?CMSPreview=1`;

    return (
        <Link
            className={className}
            to={type === 'external' ? to : type === 'knowledge-center-detail' ? `${knowledgeCenterLink}${to}${currentLang}` : isHashLink ? generateHashLink(to) : to + currentLang}
            target={(type === 'external' || to.startsWith("http")) ? '_blank' : '_self'}
            rel={type === 'external' ? 'noreferrer' : undefined}
            download={type === 'file'}
            state={type === 'knowledge-center-detail' ? { fromInternalPage: true } : undefined}
            {...props}
        >
            {children}
        </Link>
    )
}