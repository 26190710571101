import styled from 'styled-components';

const StyledLabel = styled.div`
    display: inline-flex;
    gap: 10px;
    align-items: center;
    border-radius: 4px;
    background-color: ${({$isFeatured}) => $isFeatured ? 'var(--color-light-blue)' : 'var(--color-yellow)'};
    color: var(--color-dark-text);
    padding: 5px 10px;
    font-weight: var(--fw-semi-bold);
    font-size: var(--fs-button-small);
    text-decoration: none;
    line-height: 1.5;

    @media screen and (max-width: 768px) {
        font-size: var(--fs-mobile-button-small);
    }
`;

const IconWrapper = styled.div`
    width: 20px;
    height: 20px;
    flex-shrink: 0;
`;

const Icon = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(0) saturate(100%) invert(11%) sepia(8%) saturate(470%) hue-rotate(201deg) brightness(95%) contrast(88%); // SVG icon should always be completely black
`;

export const Label = ({icon, type, children, ...props}) => {

    return (
        <StyledLabel {...props}>
            {icon &&
                <IconWrapper>
                    <Icon src={icon} alt="" />
                </IconWrapper>
            }
            {children}
        </StyledLabel>
    );
}