import styled, {keyframes} from 'styled-components';

const rotating = keyframes`
    to {
        transform: rotate(360deg);
    }
`;

export const Spinner = styled.div`
    width: ${({$size = "default"}) => $size === "default" ? '50px' : '50px'};
    height: ${({$size = "default"}) => $size === "default" ? '50px' : '50px'};
    border-radius: 50%;
    border: 8px solid var(--color-light-blue-40);
    border-top: 8px solid var(--color-dark-blue);
    animation: ${rotating} 1.5s linear infinite;
`;