import {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import {ReactComponent as FullscreenOpen} from '../../assets/icons/fullscreen.svg';
import {ReactComponent as FullscreenExit} from '../../assets/icons/fullscreen-exit.svg';

const StyledFullscreen = styled.div`
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
`;

const Absolute = styled.div`
    position: absolute;
    right: 10px;
    bottom: 10px;
    cursor: pointer;
    background-color: var(--color-dark-blue);
    padding: 6px;
    border-radius: 4px;
    opacity: ${({$isFullscreen}) => $isFullscreen ? 1 : 0};
    transition: opacity .2s ease;
    
    &:hover {
        background-color: var(--color-dark-blue-80);
    }
    
    ${StyledFullscreen}:hover & {
        opacity: 1;
    }
`;

const StyledFullscreenOpen = styled(FullscreenOpen)`
    display: block;
    width: 22px;
    height: 22px;
`;

const StyledFullscreenExit = styled(FullscreenExit)`
    display: block;
    width: 22px;
    height: 22px;
`;

export const Fullscreen = ({children}) => {
    const ref = useRef(null);
    const [isFullscreen, setIsFullscreen] = useState(false);

    const handleFullscreenClick = () => {
        if (!isFullscreen) {
            const node = ref.current;

            if (node.requestFullscreen) {
                node.requestFullscreen();
            } else if (node.mozRequestFullScreen) {
                node.mozRequestFullScreen();
            } else if (node.webkitRequestFullscreen) {
                node.webkitRequestFullscreen();
            } else if (node.msRequestFullscreen) {
                node.msRequestFullscreen();
            }
        } else {
            if (document?.exitFullscreen) {
                document?.exitFullscreen();
            } else if (document?.mozCancelFullScreen) {
                document?.mozCancelFullScreen();
            } else if (document?.webkitExitFullscreen) {
                document?.webkitExitFullscreen();
            } else if (document?.msExitFullscreen) {
                document?.msExitFullscreen();
            }
        }

        setIsFullscreen(!isFullscreen);
    };

    useEffect(() => {
        const handleFullscreenChange = () => {
            const node = ref.current;

            if (
                (document.fullscreenElement && document.fullscreenElement === node) ||
                (document.mozFullScreenElement && document.mozFullScreenElement === node) ||
                (document.webkitFullscreenElement && document.webkitFullscreenElement === node) ||
                (document.msFullscreenElement && document.msFullscreenElement === node)
            ) {
                setIsFullscreen(true);
            } else {
                setIsFullscreen(false);
            }
        };

        document.addEventListener('fullscreenchange', handleFullscreenChange);
        document.addEventListener('mozfullscreenchange', handleFullscreenChange);
        document.addEventListener('webkitfullscreenchange', handleFullscreenChange);
        document.addEventListener('msfullscreenchange', handleFullscreenChange);

        return () => {
            document.removeEventListener('fullscreenchange', handleFullscreenChange);
            document.removeEventListener('mozfullscreenchange', handleFullscreenChange);
            document.removeEventListener('webkitfullscreenchange', handleFullscreenChange);
            document.removeEventListener('msfullscreenchange', handleFullscreenChange);
        };
    }, []);

    return (
        <StyledFullscreen ref={ref}>
            {children}
            <Absolute onClick={handleFullscreenClick} $isFullscreen={isFullscreen}>{isFullscreen ? <StyledFullscreenExit /> : <StyledFullscreenOpen />}</Absolute>
        </StyledFullscreen>
    );
}