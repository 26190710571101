import styled from 'styled-components';
import {ConditionalWrap} from '../Utils/Utils';
import {Fullscreen} from '../Fullscreen/Fullscreen';
import {useIsFullscreenEnabled} from '../../hooks/useIsFullscreenEnabled';
import {forwardRef} from 'react';

// Horizontal (16:9)
export const HorizontalOuter = styled.div`
    position: relative;
    width: 100%;
    aspect-ratio: 16 / 9;
    background-color: var(--color-background);
    border-radius: 4px;
    
    // Variant styles for usage in ContentItem
    max-width: ${({$variant}) => $variant === '75' ? '100%' : $variant === '50' ? '400px' : $variant === '25' ? '200px' : '100%'};
    ${({$variant}) => ($variant === '50' || $variant === '25') && 'margin: 0 auto;'};
    
    @supports not (aspect-ratio: 16 / 9) {
        overflow: hidden;
        padding-top: 56.25%;
    }
`;

export const HorizontalInner = styled.div`
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border: none;
    border-radius: 4px;

    @supports not (aspect-ratio: 16 / 9) {
        position: absolute;
        inset: 0;
    }
`;

export const HorizontalIframe = ({src, title, ...props}) => {
    const {isFullscreenEnabled} = useIsFullscreenEnabled();

    return (
        <HorizontalOuter {...props}>
            <ConditionalWrap condition={isFullscreenEnabled} wrap={children => <Fullscreen>{children}</Fullscreen>}>
                <HorizontalInner as="iframe" src={src} title={title} loading="lazy" {...props} />
            </ConditionalWrap>
        </HorizontalOuter>
    )
}

export const SketchfabIframe = forwardRef(function SketchfabIframe(props, ref) {
    const {isFullscreenEnabled} = useIsFullscreenEnabled();

    return (
        <HorizontalOuter {...props}>
            <ConditionalWrap condition={isFullscreenEnabled} wrap={children => <Fullscreen>{children}</Fullscreen>}>
                <HorizontalInner as="iframe" ref={ref} src="" title={props.title} loading="lazy" frameBorder="0" allowFullScreen allow="autoplay; fullscreen; xr-spatial-tracking" {...props} />
            </ConditionalWrap>
        </HorizontalOuter>
    )
})

// Square (1:1)
export const SquareOuter = styled.div`
    position: relative;
    width: 100%;
    aspect-ratio: 1 / 1;
    background-color: var(--color-background);
    
    @supports not (aspect-ratio: 1 / 1) {
        overflow: hidden;
        padding-top: 100%;
    }
`;

export const SquareInner = styled.div`
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border: none;

    @supports not (aspect-ratio: 1 / 1) {
        position: absolute;
        inset: 0;
    }
`;

export const SquareImage = ({src, alt, ...props}) => {
    return (
        <SquareOuter {...props}>
            <SquareInner as="img" src={src} alt={alt} {...props} />
        </SquareOuter>
    )
}

export const SquareIframe = ({src, title, ...props}) => {
    const {isFullscreenEnabled} = useIsFullscreenEnabled();

    return (
        <SquareOuter {...props}>
            <ConditionalWrap condition={isFullscreenEnabled} wrap={children => <Fullscreen>{children}</Fullscreen>}>
                <SquareInner as="iframe" src={src} title={title} loading="lazy" {...props} />
            </ConditionalWrap>
        </SquareOuter>
    )
}