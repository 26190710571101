import {createBrowserRouter, RouterProvider} from "react-router-dom";
import ErrorPage, {ApiFailureFallback} from "./routes/ErrorPage/ErrorPage";
import Home, {homeLoader} from "./routes/Home/Home";
import DynamicPage, {dynamicPageLoader} from "./routes/DynamicPage/DynamicPage";
import {HelmetProvider} from "react-helmet-async";
import Root, {rootLoader} from "./routes/Root/Root";
import KnowledgeCenterPage, {knowledgeCenterPageLoader} from "./routes/KnowledgeCenter/KnowledgeCenterPage";
import {KnowledgeCenterItems, KnowledgeCenterItemsBoundary, knowledgeCenterItemsLoader} from "./routes/KnowledgeCenter/KnowledgeCenterItems";
import CookieBannerProvider from "./contexts/CookieBannerContext";

function App() {

    const router = createBrowserRouter([
        {
            path: '/',
            element: <Root />,
            loader: rootLoader,
            errorElement: <ApiFailureFallback />,
            id: "root",
            children: [
                {
                    index: true,
                    element: <Home />,
                    loader: homeLoader,
                    errorElement: <ErrorPage />,
                },
                {
                    path: "/*",
                    element: <DynamicPage />,
                    loader: dynamicPageLoader,
                    errorElement: <ErrorPage />,
                    id: "dynamicPage",
                },
                {
                    path: "/:prefix?/knowledge-center",
                    element: <KnowledgeCenterPage />,
                    loader: knowledgeCenterPageLoader,
                    errorElement: <ErrorPage />,
                    children: [
                        {
                            index: true,
                            element: <KnowledgeCenterItems />,
                            loader: knowledgeCenterItemsLoader,
                            errorElement: <KnowledgeCenterItemsBoundary />,
                        },
                        {
                            path: 'filter/*?',
                            element: <KnowledgeCenterItems />,
                            loader: knowledgeCenterItemsLoader,
                            errorElement: <KnowledgeCenterItemsBoundary />,
                        },
                    ]
                },
            ],
        }
    ]);

    return (
        <HelmetProvider>
            <CookieBannerProvider>
                <RouterProvider router={router} />
            </CookieBannerProvider>
        </HelmetProvider>
    );
}

export default App;