import styled from 'styled-components';
import {Container} from '../Utils/Utils';
import {ReactComponent as Dot} from '../../assets/icons/dot.svg';
import {ReactComponent as ExternalLink} from '../../assets/icons/external-link.svg';
import {Text} from '../Text/Text';
import {Heading5} from '../Heading/Heading';
import {CustomLink} from '../CustomLink/CustomLink';
import {Trans, useTranslation} from 'react-i18next';
import {navigateFooterEvent} from '../../events/navigation';

const StyledFooter = styled.footer`
    margin-top: auto;
`;

const Main = styled.div`
    padding: 100px 0;
    background-color: var(--color-dark-blue);
    
    @media screen and (max-width: 475px) {
        padding: 40px 0;
    }
`;

const Sub = styled.div`
    padding: 30px 0;
    background-color: var(--color-dark-blue-80);

    @media screen and (max-width: 475px) {
        padding: 30px 0 60px;
    }
`;

const SubFlex = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 40px;
    
    @media screen and (max-width: 768px) {
        flex-direction: column;
    }
`;

const Info = styled.div`
    display: flex;
    gap: 15px;
    flex-wrap: wrap;

    @media screen and (max-width: 475px) {
        flex-direction: column-reverse;
    }
`;

const StyledDot = styled(Dot)`
    width: 24px;
    height: 24px;
    
    path { fill: var(--color-dark-blue-40); }

    @media screen and (max-width: 475px) {
        display: none;
    }
`;

const StyledText = styled(Text)`
    a {
        color: inherit;
        text-decoration: none;
        
        &:hover {
            text-decoration: underline;
        }
    }
`;

const StyledLink = styled(Text).attrs({
    as: CustomLink
})`
    text-decoration: none;
    
    &:hover {
        text-decoration: underline;
    }
`;

const MainGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(${({$columns}) => $columns >= 4 ? '4' : '3'}, 1fr);
    gap: 80px;
    
    ${({$columns}) => $columns >= 4 && `
        @media screen and (max-width: 1024px) {
            grid-template-columns: repeat(3, 1fr);
        }
    `};
    
    @media screen and (max-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
        gap: 40px;
    }

    @media screen and (max-width: 475px) {
        grid-template-columns: repeat(1, 1fr);
    }
`;

const Column = styled.article``;

const StyledHeading5 = styled(Heading5)`
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
`;

const Links = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const StyledExternalLink = styled(ExternalLink)`
    width: 18px;
    height: 18px;
    vertical-align: text-top;
    transition: .1s transform ease;
    
    ${StyledLink}:hover & {
        transform: translate(2px, -2px);
    }
`;

const PartnerLink = styled(CustomLink)`
    display: inline-block;
    
    &:not(:first-of-type) {
        margin-top: 20px;
    }
`;

const PartnerLogo = styled.img`
    width: 100%;
    max-width: 280px;
    height: auto;
    max-height: 80px;
    object-fit: contain;
    object-position: left;
`;

export const Footer = ({data}) => {
    const { t } = useTranslation();

    return (
        <StyledFooter>
            <Main>
                <Container>
                    <MainGrid $columns={data.columns?.length + 1}>
                        <Column>
                            {data.logos?.map(item => (
                                <PartnerLink key={item.id} to={item.link} type="external" onClick={() => navigateFooterEvent("Logos", item.link)}>
                                    <PartnerLogo src={item.icon} alt="" />
                                </PartnerLink>
                            ))}
                        </Column>
                        {data.columns?.map(item => (
                            <Column key={item.id}>
                                <StyledHeading5 $white>{item.title}</StyledHeading5>
                                <Links>
                                    {item.links?.map(subItem => (
                                        <StyledLink key={subItem.id} to={subItem.link} type={subItem.linkType} $white onClick={() => navigateFooterEvent(item.titleEn, subItem.titleEn)}>
                                            {subItem.title} {subItem.linkType === 'external' && <StyledExternalLink />}
                                        </StyledLink>
                                    ))}
                                </Links>
                            </Column>
                        ))}
                    </MainGrid>
                </Container>
            </Main>
            <Sub>
                <Container>
                    <SubFlex>
                        <Info>
                            <Text $white $small>&copy; {new Date().getFullYear()} Pollinator Academy</Text>
                            <StyledDot />
                            {data.privacyPolicy && <StyledLink to={data.privacyPolicy} $white $small onClick={() => navigateFooterEvent("Sub footer", "Privacy policy")}>{t('footer.privacyPolicy')}</StyledLink>}
                            <StyledDot />
                            {data.termsAndConditions && <StyledLink to={data.termsAndConditions} $white $small onClick={() => navigateFooterEvent("Sub footer", "Terms and conditions")}>{t('footer.termsAndConditions')}</StyledLink>}
                            <StyledDot />
                            {data.cookiePolicy && <StyledLink to={data.cookiePolicy} $white $small onClick={() => navigateFooterEvent("Sub footer", "Cookie policy")}>{t('footer.cookiePolicy')}</StyledLink>}
                        </Info>
                        <StyledText $white $small><Trans i18nKey="footer.developer">Website by <CustomLink to="https://zooma.nl" type="external" onClick={() => navigateFooterEvent("Sub footer", "Zooma")}>Zooma</CustomLink></Trans></StyledText>
                    </SubFlex>
                </Container>
            </Sub>
        </StyledFooter>
    );
}