import React, { useRef, useState, useEffect } from 'react';
import {NavBarAnimateHeight} from './NavBarAnimateHeight';

export const NavBarHeightTransition = ({ children, ...props }) => {
    const [height, setHeight] = useState('auto');
    const contentDiv = useRef(null);

    useEffect(() => {
        const resizeObserver = new ResizeObserver(() => {
            setHeight(contentDiv.current.clientHeight);
        });

        resizeObserver.observe(contentDiv.current);

        return () => resizeObserver.disconnect()
    }, []);

    return (
        <NavBarAnimateHeight height={height} contentClassName="auto-content" contentRef={contentDiv} {...props}>
            {children}
        </NavBarAnimateHeight>
    );
};