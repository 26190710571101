import styled from 'styled-components';
import {Container, Section} from '../../components/Utils/Utils';
import {Heading5} from '../../components/Heading/Heading';
import {Text} from '../../components/Text/Text';
import {parseItalic} from '../../helpers/parseItalic';
import {CustomLink} from '../../components/CustomLink/CustomLink';

const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(${({$columns}) => $columns}, 1fr);
    column-gap: 30px;
    row-gap: 20px;

    @media screen and (max-width: 1280px) {
        grid-template-columns: repeat(${({$columns}) => $columns <= 3 ? $columns : 3}, 1fr);
    }

    @media screen and (max-width: 1024px) {
        grid-template-columns: repeat(${({$columns}) => $columns <= 2 ? $columns : 2}, 1fr);
        column-gap: 20px;
        row-gap: 10px;
    }

    @media screen and (max-width: 768px) {
        grid-template-columns: repeat(${({$columns}) => $columns <= 1 ? $columns : 1}, 1fr);
    }
`;

const StyledLink = styled(CustomLink)`
    color: inherit;
    text-decoration: none;
    border: 1px solid ${({$backgroundColor}) => $backgroundColor === "darkBlue" ? 'rgba(255, 255, 255, 0.1)' : 'var(--color-stroke)'};
    border-radius: 4px;
    padding: 12px 20px;
    
    &:hover ${Heading5} {
        text-decoration: underline;
    }
`;

const IconWrapper = styled.div`
    width: 32px;
    height: 32px;
    margin-bottom: 10px;
`;

const Icon = styled.img`
    width: 100%;
    height: 100%;
    object-fit: contain;
    filter: ${({$white}) => $white ? 'brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%)' : 'brightness(0) saturate(100%) invert(22%) sepia(10%) saturate(4400%) hue-rotate(162deg) brightness(93%) contrast(85%)'};
`;

export const DirectToLinks = ({data, ...props}) => {
    if(!data?.content || data?.content?.links?.length === 0) { return null; }

    return (
        <Section id={data.hashSlug ?? undefined} $scroll={Boolean(data.hashSlug)} $backgroundColor={data.backgroundColor} $paddingTop $paddingBottom={data.isLast || (data.backgroundColor === 'lightBlue' || data.backgroundColor === 'darkBlue')} $marginTop={!data.removeSpacing && (data.backgroundColor === 'lightBlue' || data.backgroundColor === 'darkBlue')} $blockType="direct-to-link" {...props}>
            <Container>
                <Grid $columns={data.content.columns || '3'}>
                    {data.content.links.map(item => {
                        if(!item.link) { return null; }

                        return (
                            <StyledLink key={item.id} to={item.link} type={item.linkType} $backgroundColor={data.backgroundColor}>
                                {item.icon &&
                                    <IconWrapper>
                                        <Icon src={item.icon} alt="" $white={data.backgroundColor === 'darkBlue'}/>
                                    </IconWrapper>
                                }
                                <Heading5 $white={data.backgroundColor === 'darkBlue'}>{parseItalic(item.linkLabel)}</Heading5>
                                {item.linkSubLabel && <Text $small $white={data.backgroundColor === 'darkBlue'}>{item.linkSubLabel}</Text>}
                            </StyledLink>
                        )
                    })}
                </Grid>
            </Container>
        </Section>
    );
}