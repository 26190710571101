import { useState, useEffect } from 'react';
import {useSearchParams} from 'react-router-dom';

export const useCurrentLangParam = () => {
    let [searchParams,] = useSearchParams();
    const lang = searchParams?.get("lang");

    const [currentLang, setCurrentLang] = useState(lang ? `?lang=${lang}` : '')

    useEffect(() => {
        setCurrentLang(lang ? `?lang=${lang}` : '');
    }, [lang]);

    return { currentLang };
};