import styled from 'styled-components';
import {useState} from 'react';
import Cookies from 'js-cookie';
import * as S from './Form.elements';
import {Container, Section} from '../../components/Utils/Utils';
import {SubmitButton} from '../../components/Button/Button';
import {Trans, useTranslation} from 'react-i18next';
import {useCurrentLangParam} from '../../hooks/useCurrentLangParam';
import {Text} from '../../components/Text/Text';
import {Heading4} from '../../components/Heading/Heading';
import {Link, useNavigate, useRouteLoaderData, useSearchParams} from 'react-router-dom';

const StyledForm = styled(S.Form)`
    margin: 0 auto;
    max-width: 600px;
    padding: 30px 40px 40px;
    gap: 30px;
`;

const InnerForm = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

const StyledText = styled(Text)`
    a {
        color: inherit;

        &:hover {
            color: var(--color-dark-blue);
        }
    }
`;

// To have the same logic in Section as empty blocks array
const data = {
    "blocks": [],
};

export const LoginForm = () => {
    const {footerData} = useRouteLoaderData("root");
    const { t } = useTranslation();
    const {currentLang} = useCurrentLangParam();
    const [status, setStatus] = useState('idle');
    const [error, setError] = useState(false);
    const navigate = useNavigate();
    const [informed, setInformed] = useState(false);
    const [searchParams,] = useSearchParams();
    const isCMSPreview = searchParams.get("CMSPreview") === "1";

    async function handleSubmit(e) {
        e.preventDefault();
        setStatus('submitting');
        setError('false');

        const form = e.target;
        const body = JSON.stringify({
            "password": form.password.value,
            ...((informed && form.email.value) && {"email": form.email.value})
        });

        const data = await fetch(`${process.env.REACT_APP_API_BASE_URL}/v1/login`, {
            method: 'POST',
            body: body,
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((res) => {
            return res.json();
        }).catch((err) => {
            return {"error": err};
        });

        if(data?.error) {
            setStatus('idle');

            switch (data?.error) {
                case "Wachtwoord is onjuist":
                    setError({"field": "password", "message": t('trainerPortal.error_wrong_password')});
                    break;
                case "Wachtwoord is verplicht":
                    setError({"field": "password", "message": t('trainerPortal.error_required_password')});
                    break;
                case "Email adres is ongeldig":
                    setError({"field": "email", "message": t('trainerPortal.error_invalid_email')});
                    break;
                default:
                    setError({"field": "password", "message": t('trainerPortal.error_fallback')});
            }
        } else {
            Cookies.set('pollinator-academy_token', data.accessToken, {expires: new Date(data.tokenExpires), secure: true, sameSite: 'none'});
            let redirectUrl = footerData?.trainerPortal ?? "/";
            if(isCMSPreview) redirectUrl = `${redirectUrl}?CMSPreview=1`;
            navigate(redirectUrl);
        }
    }

    return (
        <Section id={data.hashSlug ?? undefined} $scroll={Boolean(data.hashSlug)} $backgroundColor={data.backgroundColor} $paddingTop $paddingBottom={data?.blocks?.length === 0 || data.isLast || (data.backgroundColor === 'lightBlue' || data.backgroundColor === 'darkBlue')} $marginTop={!data.removeSpacing && (data.backgroundColor === 'lightBlue' || data.backgroundColor === 'darkBlue')}>
            <Container>

                <StyledForm method="post" onSubmit={handleSubmit}>
                    <Heading4>{t('trainerPortal.login_title')}</Heading4>

                    <InnerForm>
                        <S.BasicField>
                            <S.LabelBox>
                                <S.Label htmlFor="password">{t('trainerPortal.password_label')}</S.Label>
                                <S.Required $small>{t('trainerPortal.required')}</S.Required>
                            </S.LabelBox>
                            <S.Input as="input" type="password" id="password" name="password" placeholder={t('trainerPortal.password_label')} autoComplete="current-password" disabled={status === 'submitting'} required $error={error?.field === "password"} />
                            {error?.field === "password" && <S.ErrorText>{error?.message}</S.ErrorText>}
                            <StyledText><Trans i18nKey="trainerPortal.password_text">For access, contact the institute responsible for organising the course or send a reasoned request via our <Link to={footerData?.contact + currentLang}>contact form</Link>.</Trans></StyledText>
                        </S.BasicField>

                        <S.CheckboxField>
                            <S.CheckboxInput type="checkbox" id="checkbox" name="checkbox" checked={informed} onChange={e => setInformed(e.target.checked)} disabled={status === 'submitting'} />
                            <S.CheckboxLabel htmlFor="checkbox">{t('trainerPortal.stay_informed')}</S.CheckboxLabel>
                        </S.CheckboxField>

                        {informed &&
                            <S.BasicField>
                                <S.LabelBox>
                                    <S.Label htmlFor="email">{t('trainerPortal.email_label')}</S.Label>
                                    <S.Required $small>{t('trainerPortal.optional')}</S.Required>
                                </S.LabelBox>
                                <S.Input as="input" type="email" id="email" name="email" placeholder={t('trainerPortal.email_label')} disabled={status === 'submitting'} $error={error?.field === "email"} />
                                {error?.field === "email" && <S.ErrorText>{error?.message}</S.ErrorText>}
                                <Text>{t('trainerPortal.email_text')}</Text>
                            </S.BasicField>
                        }
                    </InnerForm>

                    <S.SubmitBox>
                        <SubmitButton value={t('trainerPortal.btn_login')} disabled={status === 'submitting'} />
                    </S.SubmitBox>
                </StyledForm>
            </Container>
        </Section>
    );
}