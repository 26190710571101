import styled from 'styled-components';
import {ReactComponent as Chevron} from '../../assets/icons/chevron-down.svg';
import {NavLink} from 'react-router-dom';
import {useRef} from 'react';
import {Text} from '../Text/Text';
import {navigateEvent} from '../../events/navigation';
import {useCurrentLangParam} from '../../hooks/useCurrentLangParam';

const StyledAccordion = styled.section`
    user-select: none;
    
    &:first-of-type {
        margin-top: 30px;
    }
`;

const Header = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    padding: 20px 0;
    cursor: pointer;
`;

const StyledNavLink = styled(NavLink)`
    margin: 0;
    color: var(--color-white);
    font-family: var(--font-headers);
    font-weight: var(--fw-semi-bold);
    font-size: var(--fs-mobile-navigation);
    text-decoration: none;
    
    &.active {
        text-decoration: underline;
    }
`;

const StyledChevron = styled(Chevron)`
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    transition: .1s ease transform;

    ${({$open}) => $open && 'transform: rotate(-180deg);'};
`;

const Content = styled.article`
    padding: 20px 0 40px;
`;

const Item = styled(NavLink)`
    display: flex;
    align-items: center;
    gap: 20px;
    text-decoration: none;
    margin-bottom: 20px;
`;

const SubNavigationLink = styled.p`
    color: var(--color-white);
    font-family: var(--font-headers);
    font-weight: var(--fw-semi-bold);
    font-size: var(--fs-mobile-sub-navigation-link);
    margin: 0;

    ${Item}:hover &, ${Item}.active & {
        text-decoration: underline;
    }
`;

const Icon = styled.img`
    display: block;
    width: 40px;
    height: 40px;
    object-fit: contain;
`;

export const NavBarAccordion = ({item, toggleMenu, isActive, setActiveId, onShow}) => {
    const {currentLang} = useCurrentLangParam();
    let hasItems = item.childs?.length > 0;

    const ref = useRef();
    const toggleExpand = (e) => {
        // If clicked on the link itself, it shouldn't open the expandable section
        if(e.target === ref.current) {
            setActiveId(null);
        }
    }

    const handleClick = (e) => {
        onShow();
        toggleExpand(e);
    }

    return (
        <StyledAccordion>
            <Header onClick={(e) => hasItems ? handleClick(e) : undefined}>
                <StyledNavLink to={item.url + currentLang} ref={ref} onClick={() => {toggleMenu(); navigateEvent(item.titleEn, item.titleEn);}}>{item.title}</StyledNavLink>
                {hasItems && <StyledChevron $open={isActive} />}
            </Header>
            {(hasItems && isActive) &&
                <Content>
                    {item.childs.map(subItem => (
                        <Item key={subItem.id} to={subItem.url + currentLang} onClick={() => {toggleMenu(); navigateEvent(item.titleEn, subItem.titleEn);}}>
                            {subItem.icon && <Icon src={subItem.icon} alt="" />}
                            <div>
                                <SubNavigationLink>{subItem.title}</SubNavigationLink>
                                {subItem.subtitle && <Text $white>{subItem.subtitle}</Text>}
                            </div>
                        </Item>
                    ))}
                </Content>
            }
        </StyledAccordion>
    );
}