import styled from 'styled-components';
import { Transition } from 'react-transition-group';
import {useEffect, useRef, useState} from 'react';
import {useClickInside} from '../../hooks/useClickInside';
import {Link, useRouteLoaderData} from 'react-router-dom';
import {ReactComponent as ShareIcon} from '../../assets/icons/share.svg';
import {ReactComponent as CopyIcon} from '../../assets/icons/clipboard.svg';
import {ReactComponent as Twitter} from '../../assets/icons/twitter.svg';
import {ReactComponent as LinkedIn} from '../../assets/icons/linkedin.svg';
import {ReactComponent as Facebook} from '../../assets/icons/facebook.svg';
import {Heading6} from '../Heading/Heading';
import {Text} from '../Text/Text';
import {useTranslation} from 'react-i18next';

const Wrapper = styled.span`
    position: relative;
    display: inline-block;
    flex-shrink: 0;
`;

const StyledShareIcon = styled(ShareIcon)`
    path { fill: var(--color-dark-blue); }
`;

const StyledCopyIcon = styled(CopyIcon)`
    width: 18px;
    height: 18px;
    
    path { fill: var(--color-dark-text); }
`;

const StyledShareButton = styled.button`
    border: none;
    background: none;
    color: var(--color-dark-blue);
    display: inline-flex;
    gap: 5px;
    align-items: center;
    cursor: pointer;
    font-weight: var(--fw-semi-bold);
    font-size: var(--fs-button-default);
    line-height: 1.5;

    &:hover {
        color: var(--color-dark-blue-80);

        ${StyledShareIcon} {
            path { fill: var(--color-dark-blue-80); }
        }
    }

    @media screen and (max-width: 768px) {
        font-size: var(--fs-mobile-button-default);
    }
`;

const Expanded = styled.div`
    position: absolute;
    top: 20px;
    right: 0;
    width: 360px;
    max-width: calc(100vw - 40px);
    padding: 20px;
    z-index: 1;
    background-color: var(--color-white);
    box-shadow: var(--box-shadow);
    border-radius: 4px;
    opacity: 0;
    transition: opacity .2s ease, top .2s ease;

    &.entering { opacity: 1; top: 30px; }
    &.entered { opacity: 1; top: 30px; }
    &.exiting { opacity: 0; top: 20px; }
    &.exited { opacity: 0; top: 20px; }
    
    @media screen and (max-width: 320px) {
        max-width: calc(100vw - 20px);
        left: 0;
    }
`;

const Icons = styled.div`
    margin-top: 10px;
    margin-bottom: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid var(--color-stroke);
`;

const StyledLink = styled(Link)`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    background-color: ${({$twitter, $linkedIn}) => $twitter ? '#1DA1F2' : $linkedIn ? '#0077B5' : '#4267B2'};
    border-radius: 50%;
    
    &:not(:last-of-type) {
        margin-right: 5px;
    }
    
    &:hover {
        background-color: ${({$twitter, $linkedIn}) => $twitter ? '#1890D9' : $linkedIn ? '#016497' : '#365594'};
    }
`;

const Copy = styled.div`
    margin-top: 10px;
    display: flex;
    gap: 5px;
`;

const Input = styled(Text).attrs({
    as: 'input',
    $small: true,
})`
    outline: none;
    border: 1px solid var(--color-stroke); 
    padding: 6px 12px;
    border-radius: 4px;
    flex-grow: 1;
    min-width: 0;
    
    &:focus {
        border: 1px solid var(--color-dark-blue);
    }
`;

const CopyButton = styled.button`
    flex-shrink: 0;
    border: 1px solid var(--color-stroke);
    background: none;
    border-radius: 4px;
    color: var(--color-dark-text);
    display: inline-flex;
    gap: 5px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 6px 12px;
    font-weight: var(--fw-semi-bold);
    font-size: var(--fs-button-small);
    line-height: 1.5;
    min-width: 85px;
    
    &:hover {
        background-color: var(--color-transparent-button-hover);
    }

    @media screen and (max-width: 768px) {
        font-size: var(--fs-mobile-button-small);
    }
`;

export const ShareButton = ({slug}) => {
    const { t } = useTranslation();
    const insideRef = useRef(null);
    const nodeRef = useRef(null);
    const {footerData} = useRouteLoaderData("root");
    const knowledgeCenterLink = footerData?.knowledgecenter?.endsWith('/') ? footerData?.knowledgecenter?.slice(0, -1) : footerData?.knowledgecenter;

    const [show, setShow] = useState(false);
    const inside = useClickInside(insideRef);

    useEffect(() => {
        if(!inside) { setShow(false); }
    }, [inside]);

    const [copyInput, setCopyInput] = useState(`https://pollinatoracademy.eu${knowledgeCenterLink}/${slug}`);
    const [copyStatus, setCopyStatus] = useState('idle');

    const handleCopyButton = () => {
        navigator.clipboard.writeText(copyInput).then(
            () => setCopyStatus('success'), // Successfully copied to clipboard
            () => setCopyStatus('error') // No permission for copy to clipboard
        );
    }

    useEffect(() => {
        // If copy button changed, reset after 2 seconds
        if(copyStatus !== "idle") {
            const timeout = setTimeout(() => setCopyStatus("idle"), 2000);

            return () => clearTimeout(timeout);
        }
    }, [copyStatus])

    return (
        <Wrapper ref={insideRef}>

            <StyledShareButton onClick={(e) => { e.stopPropagation(); setShow(!show) }} ><StyledShareIcon /> {t('knowledgeCenter.share_button_label')}</StyledShareButton>

            <Transition nodeRef={nodeRef} in={show} timeout={200} unmountOnExit>
                {state => (
                    <Expanded ref={nodeRef} className={state} onClick={(e) => { e.stopPropagation(); }}>
                        <Heading6>{t('knowledgeCenter.share_social_media_title')}</Heading6>
                        <Icons>
                            <StyledLink to={`https://twitter.com/intent/tweet?text=https://pollinatoracademy.eu${knowledgeCenterLink}/${slug}`} target="_blank" rel="noreferrer" $twitter>
                                <Twitter />
                            </StyledLink>
                            <StyledLink to={`https://www.linkedin.com/sharing/share-offsite/?url=https://pollinatoracademy.eu${knowledgeCenterLink}/${slug}`} target="_blank" rel="noreferrer" $linkedIn>
                                <LinkedIn />
                            </StyledLink>
                            <StyledLink to={`https://www.facebook.com/sharer.php?u=https://pollinatoracademy.eu${knowledgeCenterLink}/${slug}`} target="_blank" rel="noreferrer">
                                <Facebook />
                            </StyledLink>
                        </Icons>

                        <Heading6>{t('knowledgeCenter.share_copy_link_title')}</Heading6>
                        <Copy>
                            <CopyButton onClick={handleCopyButton}>
                                {copyStatus === "success" ? (
                                    t('knowledgeCenter.share_copy_button_success')
                                ) : copyStatus === "error" ? (
                                    t('knowledgeCenter.share_copy_button_error')
                                ) : (
                                    <>
                                        <StyledCopyIcon /> {t('knowledgeCenter.share_copy_button_idle')}
                                    </>
                                )}
                            </CopyButton>
                            <Input type="text" value={copyInput} onChange={e => setCopyInput(e.target.value)} />
                        </Copy>
                    </Expanded>
                )}
            </Transition>
        </Wrapper>
    );
}