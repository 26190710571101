import {useEffect, useState} from 'react';

export const useClickInside = (ref) => {
    const [inside, setInside] = useState(false);

    useEffect(() => {
        function handleClickInside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                setInside(false);
            } else {
                setInside(true);
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickInside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickInside);
        };
    }, [ref]);

    return inside;
}