import {Container, Section} from "../../components/Utils/Utils";
import styled from "styled-components";
import {Heading2, Heading5} from "../../components/Heading/Heading";
import {HTMLText} from "../../components/Text/Text";
import {ReactComponent as ArrowRight} from "../../assets/icons/arrow-right.svg";
import {parseItalic} from '../../helpers/parseItalic';
import {CustomLink} from '../../components/CustomLink/CustomLink';

const TextBox = styled.div`
    margin-bottom: 30px;
    max-width: 768px;
    
    ${Heading2} { margin-bottom: 10px; }
`;

const Institutions = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 45px;

    @media screen and (max-width: 768px) {
        grid-template-columns: 1fr;
    }
`;

const StyledArrowRight = styled(ArrowRight)`
    width: 30px;
    height: 30px;
    background-color: var(--color-dark-blue);
    border-radius: 50%;
    padding: 4px;
    flex-shrink: 0;
`;

const Institution = styled(CustomLink)`
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;
    padding: 20px;
    border-bottom: 1px solid var(--color-light-blue);

    &:hover ${Heading5} {
        text-decoration: underline;
    }
    
    &:hover ${StyledArrowRight} {
        background-color: var(--color-dark-blue-80);
    }
`;

export const CountryBlock = ({data, ...props}) => {
    if(!data?.content || data?.content?.institutions?.length === 0) { return null; }

    return (
        <Section id={data.hashSlug ?? undefined} $scroll={Boolean(data.hashSlug)} $paddingTop $paddingBottom={data.isLast} {...props}>
            <Container>
                <TextBox>
                    <Heading2>{parseItalic(data.content.title)}</Heading2>
                    <HTMLText dangerouslySetInnerHTML={{__html: data.content.text}} $noMarginTop />
                </TextBox>

                <Institutions>
                    {data.content.institutions?.map(institution => (
                        <Institution key={institution.id} to={institution.link} type={institution.linkType}>
                            <Heading5>{parseItalic(institution.title)}</Heading5>
                            <StyledArrowRight/>
                        </Institution>
                    ))}
                </Institutions>
            </Container>
        </Section>
    )
}