import {Navigate, useRouteError, useRouteLoaderData, useSearchParams} from "react-router-dom";
import errorHeaderBanner from '../../assets/error-header-banner.jpg';
import {Blocks} from '../../components/Blocks/Blocks';
import {Helmet} from 'react-helmet-async';
import {ApiFailureFallbackLayout} from '../Root/Root';
import {useTranslation} from 'react-i18next';

const ErrorPage = () => {
    const { t } = useTranslation();
    const error = useRouteError();
    const {footerData} = useRouteLoaderData("root");
    const [searchParams,] = useSearchParams();
    const isCMSPreview = searchParams.get("CMSPreview") === "1";

    // If status === 401, return to login page
    if(error?.status === 401) {
        let redirectUrl = footerData?.trainerPortalLogin ?? "/";
        if(isCMSPreview) redirectUrl = `${redirectUrl}?CMSPreview=1`;

        return <Navigate to={redirectUrl} replace={true} />
    }

    let title = error?.status === 404 ? t('error.404_title') : t('error.fallback_title');
    let description = error?.status === 404 ? t('error.404_text') : t('error.fallback_text');

    const data = [
        {
            "type": "header-banner",
            "content": {
                "id": "1",
                "type": "small",
                "title": title,
                "image": errorHeaderBanner
            }
        },
        {
            "type": "text",
            "isLast": true,
            "content": {
                "id": "2",
                "numberOfColumns": "1",
                "firstColumnText": description,
                "firstColumnFirstLinkLabel": t('error.btn_home'),
                "firstColumnFirstLinkType": "internal",
                "firstColumnFirstLink": "/",
            }
        }
    ]

    return (
        <div>
            <Helmet>
                <title>Pollinator Academy &mdash; {title}</title>
            </Helmet>

            <Blocks data={data} />
        </div>
    );
}

export const ApiFailureFallback = () => {
    return (
        <ApiFailureFallbackLayout>
            <ErrorPage />
        </ApiFailureFallbackLayout>
    );
}

export default ErrorPage;