
import {Blocks} from '../../components/Blocks/Blocks';
import {Helmet} from 'react-helmet-async';
import {useLoaderData} from 'react-router-dom';

export async function homeLoader({request}) {
    const url = new URL(request.url);
    const lang = url.searchParams.get("lang");

    const res = await fetch(`${process.env.REACT_APP_API_BASE_URL}/v1/dynamic-page?url=home${lang ? `&lang=${lang}` : ''}`, {
        signal: request.signal
    });

    if(!res.ok) {
        throw new Response("Page is not found", { status: 404 });
    }

    const data = await res.json();

    return {homeData: data}
}

const Home = () => {
    const data = useLoaderData();

    return (
        <>
            <Helmet>
                <title>Pollinator Academy &mdash; Strengthening taxonomic capacity in Europe with tools and training.</title>
                <link rel="canonical" href="https://pollinatoracademy.eu/" />
            </Helmet>

            <Blocks data={data?.homeData?.blocks} />
        </>
    );
}

export default Home;