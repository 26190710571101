import styled from 'styled-components';
import Cookies from 'js-cookie';
import {useEffect, useRef, useState} from 'react';
import {Heading3, Heading6} from '../Heading/Heading';
import {Text} from '../Text/Text';
import {Transition} from 'react-transition-group';
import {createPortal} from 'react-dom';
import {useCookieBannerContext} from '../../contexts/CookieBannerContext';
import {Link, useLocation, useSearchParams} from 'react-router-dom';
import {ActionButton} from '../Button/Button';
import {ReactComponent as Chevron} from '../../assets/icons/chevron-left.svg';
import {Checkbox, CheckboxInput} from '../Filters/FilterCheckbox';
import {Trans, useTranslation} from 'react-i18next';

const StyledCookieBanner = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 620px;
    background-color: var(--color-white);
    box-shadow: var(--box-shadow);
    border-radius: 7px;
    padding: 30px;
    z-index: 102;
    opacity: 0;
    overflow-x: auto;
    max-height: 100%;
    transition: opacity .2s ease;

    &.entering { opacity: 1; }
    &.entered { opacity: 1; }
    &.exiting { opacity: 0; }
    &.exited { opacity: 0; }
    
    @media screen and (max-width: 768px) {
        padding: 20px;
    }
    
    @media screen and (max-width: 640px) {
        top: unset;
        bottom: 0;
        left: 0;
        transform: unset;
        width: 100%;
        max-width: unset;
        border-radius: 7px 7px 0 0;
        padding: 30px 20px;
    }
`;

const Heading = styled.div`
    display: inline-flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 12px;
    ${({$actAsButton}) => $actAsButton && `
        cursor: pointer;
        transform: translateX(-6px);
    `};

    @media screen and (max-width: 768px) {
        gap: 5px;
    }
`;

const StyledChevron = styled(Chevron)`
    width: 36px;
    height: 36px;
    transition: transform .2s ease;
    
    path {
        fill: var(--color-dark-blue);
    }
    
    ${Heading}:hover & {
        transform: translateX(-5px);
    }
    
    @media screen and (max-width: 768px) {
        width: 24px;
        height: 24px;
    }

    @media screen and (max-width: 768px) {
        width: 21px;
        height: 21px;
    }
`;

const StyledText = styled(Text)`
    margin-bottom: 10px;
    
    a {
        color: var(--color-dark-blue);
        font-weight: var(--fw-bold);

        &:hover {
            text-decoration: none;
        }
    }
`;

const Options = styled.div`
    padding-top: 30px;

    @media screen and (max-width: 768px) {
        padding-top: 20px;
    }
`;

const Option = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 15px;
    padding-bottom: 20px;
    border-bottom: 1px solid var(--color-knowledge-center-border);
    
    &:not(:last-of-type) {
        margin-bottom: 20px;
    }

    @media screen and (max-width: 768px) {
        padding-bottom: 15px;
    }
`;

const ButtonWrapper = styled.div`
    display: flex;
    gap: 10px;
    margin-top: 40px;
    flex-wrap: wrap;

    @media screen and (max-width: 768px) {
        margin-top: 30px;
    }
`;

const Underlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    background-color: var(--color-dark-blue);
    height: 100%;
    width: 100%;
    z-index: 101;
    opacity: 0;
    transition: opacity .2s ease;

    &.entering { opacity: .25; }
    &.entered { opacity: .25; }
    &.exiting { opacity: 0; }
    &.exited { opacity: 0; }
`;

const CookieBanner = ({data}) => {
    const { t } = useTranslation();
    const cookieNodeRef = useRef(null);
    const underlayNodeRef = useRef(null);
    const [searchParams,] = useSearchParams();

    const {isOpen, openCookieBanner, closeCookieBanner} = useCookieBannerContext();
    const [isCustomizing, setIsCustomizing] = useState(false);
    const [checkboxValue, setCheckboxValue] = useState(Cookies.get('pollinator-academy_cookie-consent') === "1");

    const handleAcceptAll = () => {
        Cookies.set('pollinator-academy_cookie-consent', 1, {expires: 365, secure: true, sameSite: 'none'});
        closeCookieBanner();
    }

    const handleAcceptSelection = () => {
        Cookies.set('pollinator-academy_cookie-consent', checkboxValue ? 1 : 2, {expires: 365, secure: true, sameSite: 'none'});
        closeCookieBanner();
    }

    // Keep menu closed when viewing the cookie policy via the cookie banner and open when navigating
    const {search} = useLocation();
    let params = new URLSearchParams(search);

    useEffect(() => {
        if(!isOpen && !Boolean(Cookies.get('pollinator-academy_cookie-consent')) && params?.get("via") !== "cookie-banner") {
            openCookieBanner();
        }
    //eslint-disable-next-line
    }, [search]);

    if(searchParams.get("CMSPreview") === "1") return null;

    return createPortal(
        <>
            <Transition nodeRef={cookieNodeRef} in={isOpen} timeout={200} unmountOnExit onExited={() => setIsCustomizing(false)}>
                {state => (
                    <StyledCookieBanner ref={cookieNodeRef} className={state}>
                        {!isCustomizing ? (
                            <>
                                <Heading>
                                    <Heading3>{t('cookieBanner.title')}</Heading3>
                                </Heading>

                                <StyledText>{t('cookieBanner.text')}</StyledText>
                                {data && <StyledText><Trans i18nKey="cookieBanner.read_more_text">Read more in our <Link to={`${data}?via=cookie-banner`} onClick={closeCookieBanner}>cookie policy</Link>.</Trans></StyledText>}

                                <ButtonWrapper>
                                    <ActionButton key="accept-all-cookies" onClick={handleAcceptAll}>{t('cookieBanner.btn_accept_all')}</ActionButton>
                                    <ActionButton key="customize" $variant="outline" onClick={() => setIsCustomizing(true)}>{t('cookieBanner.btn_customize')}</ActionButton>
                                </ButtonWrapper>
                            </>
                        ) : (
                            <>
                                <Heading onClick={() => setIsCustomizing(false)} $actAsButton>
                                    <StyledChevron />
                                    <Heading3>{t('cookieBanner.manage_title')}</Heading3>
                                </Heading>

                                <Text>{t('cookieBanner.manage_text')}</Text>

                                <Options>
                                    <Option>
                                        <Checkbox htmlFor="functional-and-analytical">
                                            <CheckboxInput type="checkbox" id="functional-and-analytical" name="functional-and-analytical" defaultChecked={true} disabled />
                                        </Checkbox>
                                        <label>
                                            <Heading6>{t('cookieBanner.manage_option1_title')}</Heading6>
                                            <Text $small>{t('cookieBanner.manage_option1_text')}</Text>
                                        </label>
                                    </Option>

                                    <Option>
                                        <Checkbox htmlFor="third-party-embeds">
                                            <CheckboxInput type="checkbox" id="third-party-embeds" name="third-party-embeds" checked={checkboxValue} onChange={(e) => setCheckboxValue(e.target.checked)} />
                                        </Checkbox>
                                        <label htmlFor="third-party-embeds">
                                            <Heading6>{t('cookieBanner.manage_option2_title')}</Heading6>
                                            <Text $small>{t('cookieBanner.manage_option2_text')}</Text>
                                        </label>
                                    </Option>
                                </Options>

                                <ButtonWrapper>
                                    <ActionButton key="accept-selection" onClick={handleAcceptSelection}>{t('cookieBanner.manage_btn_accept_selection')}</ActionButton>
                                    <ActionButton key="go-back"  $variant="outline" onClick={() => setIsCustomizing(false)}>{t('cookieBanner.manage_btn_go_back')}</ActionButton>
                                </ButtonWrapper>
                            </>
                        )}
                    </StyledCookieBanner>
                )}
            </Transition>

            <Transition nodeRef={underlayNodeRef} in={isOpen} timeout={200} unmountOnExit>
                {state => (
                    <Underlay ref={underlayNodeRef} className={state} />
                )}
            </Transition>
        </>, document.body
    );
}

export default CookieBanner;