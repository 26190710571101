import React, {useEffect, useRef, useState} from "react";
import styled from "styled-components";
import { ReactComponent as Logo } from '../../assets/logo-white-text.svg';
import { ReactComponent as LogoBlue } from '../../assets/logo-blue-text.svg';
import { ReactComponent as Hamburger } from '../../assets/icons/hamburger.svg';
import {Container} from '../Utils/Utils';
import {Link, useMatches} from 'react-router-dom';
import {useToggleMenu} from '../../hooks/useToggleMenu';
import {NavBarItem} from './NavBarItem';
import {createPortal} from 'react-dom';
import NavBarHamburger from './NavBarHamburger';
import {navigateEvent} from '../../events/navigation';
import {NavBarLanguage} from './NavBarLanguage';
import {useCurrentLangParam} from '../../hooks/useCurrentLangParam';
import {NavBarFlyOut} from './NavBarFlyOut';

const StyledNavBar = styled.header`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
    background-color: ${props => props.$scrolled ? 'var(--color-dark-blue)' : props.$onWhite ? 'var(--color-white)' : 'transparent'};
    ${props => (props.$scrolled) && 'box-shadow: var(--box-shadow);'};
    transition: background-color .25s ease-in-out;
`;

const Inner = styled.div`
    display: flex;
    gap: 5px;
    justify-content: space-between;
    border-bottom: 1px solid transparent;
    ${({$showBorder, $onWhite}) => $showBorder && `border-color: ${$onWhite ? 'rgba(230, 230, 230, 0.5)' : 'rgba(255, 255, 255, 0.1)'};`};
    transition: border-color .25s ease-in-out;

    @media screen and (max-width: 1024px) {
        height: 58px;
        align-items: center;
    }
`;

export const LogoBox = styled(Link)`
    padding: 10px 0;
    min-height: 62px;
    display: flex;
`;

export const StyledLogo = styled(Logo)`
    width: 100%;
    max-width: 280px;
    height: auto;
    vertical-align: middle;
    
    @media screen and (max-width: 768px) {
        max-width: 220px;
    }
`;

const StyledLogoBlue = styled(LogoBlue)`
    width: 100%;
    max-width: 280px;
    height: auto;
    vertical-align: middle;
    
    @media screen and (max-width: 768px) {
        max-width: 220px;
    }
`;

const LinkBox = styled.nav`
    display: flex;
    gap: 25px;
    margin-bottom: -1px;
    flex-shrink: 0;

    @media screen and (max-width: 1024px) {
        display: none;
    }
`;

const MobileBox = styled.div`
    display: none;
    align-self: center;

    @media screen and (max-width: 1024px) {
        display: flex;
        gap: 5px;
    }
`;

const StyledHamburger = styled(Hamburger)`
    width: 28px;
    height: 28px;

    path {
        stroke: ${props => props.$onWhite ? 'var(--color-dark-blue)' : 'var(--color-white)'};
    }
`;

export const MenuButton = styled.button`
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 2px 4px;
    background: none;
    appearance: none;
    border: none;

    &:hover {
        border-radius: 3px;
    }
`;

export const MenuText = styled.span`
    color: ${props => props.$onWhite ? 'var(--color-dark-blue)' : 'var(--color-white)'};
    font-family: var(--font-headers);
    font-weight: var(--fw-semi-bold);
    font-size: 14px;
    text-transform: uppercase;
    margin-left: 3px;
    user-select: none;
`;

const NavBar = ({data}) => {
    const {currentLang} = useCurrentLangParam();

    // When you mouseenter a NavBarItem with items, open the flyout menu (passed props to NavBarItem
    const ref = useRef(null);
    const [open, setOpen] = useState(false);
    const [activeFlyOut, setActiveFlyOut] = useState(null);
    let timeout = null;
    const handleNavBarItemEnter = () => {
        clearTimeout(timeout)
        timeout = setTimeout(() => setOpen(true), 70);
    }

    // When you mouseleave the navigation, close the flyout menu
    useEffect(() => {
        const node = ref.current;
        let timeout = null;

        const handleLeave = () => {
            clearTimeout(timeout);
            timeout = setTimeout(() => setOpen(false), 70);
        }

        node.addEventListener("mouseleave", handleLeave);

        return () => {
            node.removeEventListener("mouseleave", handleLeave);
        };
    }, [ref])

    // Different style on knowledge-center detail page
    const matches = useMatches();
    const isKnowledgeCenterDetailPage = matches?.find(item => item?.id === "dynamicPage")?.data?.data?.pageType === "knowledgecenter-page-item";
    const dynamicPageWithoutBlocks = matches?.find(item => item?.id === "dynamicPage")?.data?.data?.blocks?.length === 0;
    const isOnWhite = isKnowledgeCenterDetailPage || dynamicPageWithoutBlocks;

    // Handle open navbar on mobile
    const { isOpen, openMenu, closeMenu } = useToggleMenu();

    // Apply different style on scroll and when FlyOut is open
    const [scroll, setScroll] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 70) {
                setScroll(true);
            } else {
                setScroll(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    let shouldShowOnWhite = !scroll && !open && isOnWhite;

    return (
        <StyledNavBar ref={ref} $scrolled={scroll} $onWhite={shouldShowOnWhite}>
            <Container>
                <Inner $showBorder={!scroll || open} $onWhite={shouldShowOnWhite}>
                    <LogoBox to={currentLang ? `/${currentLang}` : '/'} onClick={() => {setOpen(false); navigateEvent("Logo", "Home");}}>
                        {shouldShowOnWhite ? <StyledLogoBlue /> : <StyledLogo />}
                    </LogoBox>
                    <LinkBox>
                        {data?.map(item => (
                            <NavBarItem key={item.id} item={item} handleNavBarItemEnter={handleNavBarItemEnter} activeFlyOut={activeFlyOut} setActiveFlyOut={setActiveFlyOut} open={open} setOpen={setOpen} onWhite={shouldShowOnWhite} />
                        ))}
                        <NavBarLanguage location="navBar" shouldShowOnWhite={shouldShowOnWhite} />
                    </LinkBox>
                    <MobileBox>
                        <NavBarLanguage location="navBar" shouldShowOnWhite={shouldShowOnWhite} />
                        <MenuButton onClick={openMenu}>
                            <StyledHamburger $onWhite={shouldShowOnWhite} />
                            <MenuText $onWhite={shouldShowOnWhite}>Menu</MenuText>
                        </MenuButton>
                    </MobileBox>
                </Inner>
            </Container>

            {activeFlyOut &&
                <NavBarFlyOut items={data.find(item => item.id === activeFlyOut).childs} open={open} setOpen={setOpen} parent={data.find(item => item.id === activeFlyOut).titleEn} />
            }

            {createPortal(
                <NavBarHamburger data={data} isOpen={isOpen} closeMenu={closeMenu} />,
                document.body
            )}
        </StyledNavBar>
    );
};

export default NavBar;