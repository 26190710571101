import Select from 'react-select';
import styled from 'styled-components';
import {disableFilterEvent, enableFilterEvent} from '../../events/knowledgeCenter';
import {useTranslation} from 'react-i18next';

const SelectWrapper = styled.div`
    .pollinator-academy-select {
        
        &__value-container {
            font-size: var(--fs-body-default);
            padding: 5px 10px;

            @media screen and (max-width: 768px) {
                font-size: var(--fs-mobile-body-default);
            }
        }
        
        &__input-container { color: var(--color-body-text); }
        
        &__placeholder { color: var(--color-placeholder); }
        
        &__dropdown-indicator { transition: unset; }
        
        &__multi-value {
            color: var(--color-dark-text);
            background-color: var(--color-yellow);
            font-size: var(--fs-select-label);
            font-weight: var(--fw-semi-bold);

            @media screen and (max-width: 768px) {
                font-size: var(--fs-mobile-select-label);
            }
            
            &__remove {
                cursor: pointer;

                &:hover {
                    color: var(--color-dark-text);
                }
            }
            
            &__label { color: var(--color-dark-text); }
        }
        
        &__indicator-separator { background-color: var(--color-white); }
        
        &__menu {
            font-size: var(--fs-body-small);
            color: var(--color-body-text);
            
            @media screen and (max-width: 768px) {
                font-size: var(--fs-mobile-body-small);
            }
        }
    }
`;

// Customize React Select theme colors and some styles
const selectTheme = (theme) => ({
    ...theme,
    colors: {
        ...theme.colors,
        primary: 'var(--color-dark-blue)',
        primary75: 'var(--color-dark-blue)',
        primary50: 'var(--color-light-blue)',
        primary25: 'var(--color-light-blue-40)',
        danger: 'var(--color-yellow)',
        dangerLight: 'var(--color-yellow)',
        neutral0: 'var(--color-white)',
        neutral5: 'var(--color-stroke)',
        neutral10: 'var(--color-stroke)',
        neutral20: 'var(--color-stroke)',
        neutral30: 'var(--color-stroke)',
        neutral40: 'var(--color-stroke)',
        neutral50: 'var(--color-stroke)',
        neutral60: 'var(--color-dark-blue)',
        neutral70: 'var(--color-dark-blue)',
        neutral80: 'var(--color-dark-blue)',
        neutral90: 'var(--color-dark-blue)',
    }
})

const selectStyle = {
    control: (styles, state) => ({
        ...styles,
        border: state.isFocused ? '1px solid var(--color-dark-blue)' : '1px solid var(--color-stroke)',
        outline: 'none',
        minHeight: '42px',
        boxShadow: 'none',
        transition: 'unset',
        ':hover': {
            border: state.isFocused ? '1px solid var(--color-dark-blue)' : '1px solid var(--color-stroke)',
        }
    }),
}

export const FilterSelect = ({filter, toggleFn}) => {
    const { t } = useTranslation();

    const options = filter.filterItems.map(item => ({
        value: item.id,
        label: item.title,
        labelEn: item.titleEn
    }));

    const defaultValues = filter.filterItems.filter(item => item.active === true).map(item => ({
        value: item.id,
        label: item.title
    }));

    return (
        <SelectWrapper>
            <Select
                className="pollinator-academy-select"
                classNamePrefix="pollinator-academy-select"
                isMulti
                isClearable={false}
                options={options}
                value={defaultValues}
                theme={selectTheme}
                styles={selectStyle}
                placeholder={t('knowledgeCenter.input_select')}
                noOptionsMessage={() => t('knowledgeCenter.input_no_options')}
                onChange={(value, action) => {
                    switch (action.action) {
                        case 'select-option': // Selecting option from list
                            toggleFn(filter.id, action.option.value, true);
                            enableFilterEvent(filter.titleEn, action.option.labelEn);
                            break;
                        case 'pop-value': // Removing option with backspace
                        case 'remove-value': // Removing option with cross button
                            toggleFn(filter.id, action.removedValue.value, false);
                            disableFilterEvent(filter.titleEn, action.removedValue.labelEn);
                            break;
                        default:
                            return undefined
                    }
                }}
            />
        </SelectWrapper>
    );
}