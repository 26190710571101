import {Outlet, ScrollRestoration, useLoaderData, useLocation, useNavigation, useSearchParams} from 'react-router-dom';
import nProgress from 'nprogress';
import {useEffect} from 'react';
import styled from 'styled-components';
import GlobalStyle from '../../globalStyles';
import NavBar from '../../components/NavBar/NavBar';
import {Footer} from '../../components/Footer/Footer';
import CookieBanner from '../../components/CookieBanner/CookieBanner';
import {useTranslation} from 'react-i18next';

const StyledLayout = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
`;

export async function rootLoader({request}) {
    const url = new URL(request.url);
    const lang = url.searchParams.get("lang");

    // Fetch navigation
    const navigationPromise = fetch(`${process.env.REACT_APP_API_BASE_URL}/v1/navigation${lang ? `?lang=${lang}` : ''}`, {
        signal: request.signal
    }).then((res) => {
        if(!res.ok) {
            throw new Response("Bad request", { status: 400 });
        }

        return res.json();
    });

    // Fetch footer
    const footerPromise = fetch(`${process.env.REACT_APP_API_BASE_URL}/v1/footer${lang ? `?lang=${lang}` : ''}`, {
        signal: request.signal
    }).then((res) => {
        if(!res.ok) {
            throw new Response("Bad request", { status: 400 });
        }

        return res.json();
    });

    const [navigationData, footerData] = await Promise.all([navigationPromise, footerPromise]);

    return ({navigationData, footerData});
}

const Root = () => {
    const data = useLoaderData();
    const location = useLocation();
    const navigation = useNavigation();

    // NProgress bar (loadingbar on page change)
    nProgress.configure({
        showSpinner: false,
        template: '<div class="bar" role="bar"></div>',
        minimum: 0.3,
        speed: 400,
    });

    // Normal navigation in data router is idle -> loading -> idle, so start nProgress if state changed to "loading"
    if(navigation.state === "loading") {
        nProgress.start();
    }

    if(navigation.state === "idle") {
        nProgress.done();
    }

    // When the location changed, complete the nProgress
    useEffect(() => {
        nProgress.done();
    }, [location])

    // Change i18n language on first render
    const {i18n} = useTranslation();
    let [searchParams,] = useSearchParams();

    useEffect(() => {
        if(searchParams.get('lang')) i18n.changeLanguage(searchParams.get('lang')?.toLowerCase());
        //eslint-disable-next-line
    }, []);

    return (
        <StyledLayout>
            <GlobalStyle />
            <ScrollRestoration />

            <NavBar data={data.navigationData} />
            <Outlet />
            <Footer data={data.footerData} />
            <CookieBanner data={data.footerData?.cookiePolicy} />
        </StyledLayout>
    );
}

export const ApiFailureFallbackLayout = ({children}) => {
    return (
        <StyledLayout>
            <GlobalStyle />
            <ScrollRestoration />
            {children}
        </StyledLayout>
    );
}

export default Root;