import styled from 'styled-components';
import {Heading4} from '../Heading/Heading';
import {Text} from '../Text/Text';
import {ReactComponent as ExternalLink} from '../../assets/icons/external-link.svg';
import {ReactComponent as Bulb} from '../../assets/icons/bulb.svg';
import {Fragment} from 'react';
import {LabelsRow} from '../LabelsRow/LabelsRow';
import {Button} from '../Button/Button';
import {clickOnKnowledgeCenterItemEvent} from '../../events/knowledgeCenter';
import {useTranslation} from 'react-i18next';

const StyledKnowledgeCenterFeaturedItem = styled.article`
    display: flex;
    gap: 20px;
    padding: 30px;
    margin-bottom: 30px;
    text-decoration: none;
    color: inherit;
    background-color: #DDE8EF;
    border-radius: 5px;

    &:not(:last-of-type) {
        border-bottom: 1px solid var(--color-knowledge-center-border);
        padding-bottom: 30px;
        margin-bottom: 30px;
    }
    
    @media screen and (max-width: 768px) {
        flex-direction: column;
        margin-left: -20px;
        width: 100vw;
        border-radius: 0;
        padding: 30px 20px;
    }

    @media screen and (max-width: 320px) {
        margin-left: -10px;
        padding: 20px 10px;
    }
`;

const Inner = styled.div`
    width: 100%;
`;

const Info = styled(Text)`
    color: var(--color-dark-blue);
    font-weight: var(--fw-bold);
    margin: 2px 0;
`;

const StyledText = styled(Text)`
    color: var(--color-dark-blue);
`;

const StyledExternalLink = styled(ExternalLink)`
    width: 18px;
    height: 18px;
    vertical-align: middle;
    margin-left: 2px;
    transform: translateY(-2px);
    transition: .1s transform ease;
    
    path { stroke: var(--color-dark-blue); }

    ${StyledKnowledgeCenterFeaturedItem}:hover & {
        transform: translate(1px, -3px);
    }
`;

const StyledBulb = styled(Bulb)`
    flex-shrink: 0;
    width: 48px;
    height: 48px;
    
    path { stroke: var(--color-dark-blue) }
`;

const Flex = styled.div`
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 20px;
    
    @media screen and (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
    }
`;

export const KnowledgeCenterFeaturedItem = ({data}) => {
    const { t } = useTranslation();
    let isExternal = data.linkType === "external";
    let isDetailPage = data.linkType === "knowledge-center-detail";

    const infoObj = {
        ...(data?.author && {"author": data?.author}),
        ...(data?.displayPublicationDate && {"displayPublicationDate": data?.displayPublicationDate}),
        ...(data?.ISSN && {"ISSN": `ISSN: ${data?.ISSN}`}),
        ...(data?.ISBN && {"ISBN": `ISBN: ${data?.ISBN}`}),
        ...(data?.DOI && {"DOI": `DOI: ${data?.DOI}`}),
    };

    return (
        <StyledKnowledgeCenterFeaturedItem>
            <StyledBulb />
            <Inner>
                <Heading4 $truncate $clamp="2">{data.title} {isExternal && <StyledExternalLink />}</Heading4>
                {Object.keys(infoObj)?.length > 0 &&
                    <Info>
                        {Object.entries(infoObj).map(([key, value], index, {length}) => (
                            <Fragment key={index}>
                                {value}{length - 1 !== index && ", "}
                            </Fragment>
                        ))}
                    </Info>
                }
                {data.description && <StyledText $truncate $clamp="2">{data.description}</StyledText>}
                <Flex>
                    <LabelsRow data={data} isFeatured />
                    <Button to={isDetailPage ? `/${data.slug}` : data.link} type={data.linkType} onClick={() => clickOnKnowledgeCenterItemEvent(data.linkType, data.title)}>{t('knowledgeCenter.featured_item_learn_more')}</Button>
                </Flex>
            </Inner>
        </StyledKnowledgeCenterFeaturedItem>
    );
}