import HeaderBanner from '../../blocks/HeaderBanner/HeaderBanner';
import {TextBlock} from '../../blocks/TextBlock/TextBlock';
import {ContentItem} from '../../blocks/ContentItem/ContentItem';
import {Partners} from '../../blocks/Partners/Partners';
import {Banner} from '../../blocks/Banner/Banner';
import {DirectToLinks} from '../../blocks/DirectToLinks/DirectToLinks';
import {MediaBanner} from '../../blocks/MediaBanner/MediaBanner';
import {PDFViewer} from '../../blocks/PDFViewer/PDFViewer';
import {InfoColumns} from '../../blocks/InfoColumns/InfoColumns';
import {ContactForm} from "../../blocks/Form/ContactForm";
import {CountryBlock} from "../../blocks/CountryBlock/CountryBlock";
import {LinkGroup} from "../../blocks/LinkGroup/LinkGroup";

const blockComponents = {
    "banner": Banner,
    "contact-form": ContactForm,
    "content-item": ContentItem,
    "country": CountryBlock,
    "direct-to-link": DirectToLinks,
    "document": PDFViewer,
    "header-banner": HeaderBanner,
    "info-columns": InfoColumns,
    "trainer-portal-info-columns" : InfoColumns,
    "link-group": LinkGroup,
    "media-banner": MediaBanner,
    "partner-slider": Partners,
    "text": TextBlock,
}

// Display a block individually
const Block = ({ blockData, pageType }) => {
    // Prepare the component
    const BlockComponent = blockComponents[blockData.type]

    if (!BlockComponent) { return null }

    // Display the block
    return <BlockComponent data={blockData} $pageType={pageType} />
}

// Display the list of blocks
export const Blocks = ({ data, pageType = "dynamic-page" }) => {
    if(!data) { return null }

    return (
        <>
            {data.map((block, index) => (
                <Block key={`${block.type}${index}`} blockData={block} pageType={pageType} />
            ))}
        </>
    )
}