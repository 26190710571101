import styled from 'styled-components';
import {truncateMixin} from '../Text/Text';

export const PageTitle = styled.h1`
    color: ${props => props.$white ? 'var(--color-white)' : props.$dark ? 'var(--color-dark-text)' : 'var(--color-dark-blue)'};
    font-family: var(--font-headers);
    font-weight: var(--fw-extra-bold);
    font-size: var(--fs-page-title);
    line-height: 1.25;
    margin: 0;
    overflow-wrap: anywhere;
    ${({ $truncate }) => $truncate && truncateMixin};

    @media screen and (max-width: 768px) {
        font-size: var(--fs-tablet-page-title);
    }

    @media screen and (max-width: 475px) {
        font-size: var(--fs-mobile-page-title);
    }
`;

export const Heading1 = styled(PageTitle)`
    font-size: var(--fs-heading-1);

    @media screen and (max-width: 768px) {
        font-size: var(--fs-tablet-heading-1);
    }

    @media screen and (max-width: 475px) {
        font-size: var(--fs-mobile-heading-1);
    }
`;

export const Heading2 = styled(PageTitle).attrs({
    as: 'h2'
})`
    font-size: var(--fs-heading-2);

    @media screen and (max-width: 768px) {
        font-size: var(--fs-tablet-heading-2);
    }

    @media screen and (max-width: 475px) {
        font-size: var(--fs-mobile-heading-2);
    }
`;

export const BlockHeading2 = styled(Heading2)`
    max-width: 768px;
    text-align: center;
    margin: 0 auto 10px;
`;

export const Heading3 = styled(PageTitle).attrs({
    as: 'h3'
})`
    font-size: var(--fs-heading-3);

    @media screen and (max-width: 768px) {
        font-size: var(--fs-tablet-heading-3);
    }

    @media screen and (max-width: 475px) {
        font-size: var(--fs-mobile-heading-3);
    }
`;

export const Heading4 = styled(PageTitle).attrs({
    as: 'h4'
})`
    font-size: var(--fs-heading-4);

    @media screen and (max-width: 475px) {
        font-size: var(--fs-mobile-heading-4);
    }
`;

export const Heading5 = styled(PageTitle).attrs({
    as: 'h5'
})`
    font-size: var(--fs-heading-5);

    @media screen and (max-width: 475px) {
        font-size: var(--fs-mobile-heading-5);
    }
`;

export const Heading6 = styled(PageTitle).attrs({
    as: 'h6'
})`
    font-size: var(--fs-heading-6);

    @media screen and (max-width: 475px) {
        font-size: var(--fs-mobile-heading-6);
    }
`;