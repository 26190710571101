import {ConditionalWrap, Container, Section} from "../../components/Utils/Utils";
import {Heading2} from "../../components/Heading/Heading";
import styled from "styled-components";
import {Text} from "../../components/Text/Text";
import {ReactComponent as ExternalLink} from "../../assets/icons/external-link.svg";
import {ReactComponent as Chevron} from '../../assets/icons/chevron-right.svg';
import {parseItalic} from '../../helpers/parseItalic';
import {CustomLink} from '../../components/CustomLink/CustomLink';

const StyledColumns = styled.div`
    column-count: 3;
    column-gap: 50px;

    @media screen and (max-width: 1024px) {
        column-count: 2;
    }

    @media screen and (max-width: 560px) {
        column-count: 1;
    }
`;

const LinkList = styled.div`
    &:not(:last-of-type) {
        margin-bottom: 60px;

        @media screen and (max-width: 1024px) {
            margin-bottom: 40px;
        }
    }
`;

const ListHeading = styled.div`
    padding-bottom: 30px;
    margin-bottom: 30px;
    border-bottom: 1px solid var(--color-stroke);
    
    ${Heading2} {
        line-height: 1;
    }

    @media screen and (max-width: 560px) {
        padding-bottom: 20px;
        margin-bottom: 20px;
    }
`;

const List = styled.ul`
    list-style-type: none;
    margin: 0;
    padding: 0;
`;

const ListItem = styled(Text).attrs({
    as: "li"
})`
    display: flex;
    
    &:not(:last-of-type) {
        margin-bottom: 10px;
    }
`;

const StyledLink = styled(CustomLink)`
    display: inline-flex;
    align-items: center;
    text-decoration: none;
    color: var(--color-dark-blue);
    gap: 5px;
    
    &:hover {
        text-decoration: underline;
    }
`;

const StyledItem = styled(StyledLink).attrs({
    as: 'div'
})`
    color: var(--color-dark-text);
    
    &:hover {
        text-decoration: none;
    }
`;

const StyledExternalLink = styled(ExternalLink)`
    width: 18px;
    height: 18px;
    vertical-align: text-top;
    transition: .1s transform ease;
    
    ${StyledLink}:hover & {
        transform: translate(2px, -2px);
    }

    path {
        stroke: var(--color-dark-blue);
        stroke-width: 1.6;
    }
`;

const StyledChevron = styled(Chevron)`
    width: 18px;
    height: 18px;
    vertical-align: text-top;
    
    path {
        fill: ${({$isLink}) => $isLink ? 'var(--color-dark-blue)' : 'var(--color-body-text)'};
    }
`;

export const LinkGroup = ({data, ...props}) => {
    if(!data?.content || data?.content?.linkLists?.length === 0) { return null; }

    return (
        <Section id={data.hashSlug ?? undefined} $scroll={Boolean(data.hashSlug)} $paddingTop $paddingBottom={data.isLast} {...props}>
            <Container>
                <StyledColumns>
                    {data.content.linkLists.map(list => (
                        <LinkList key={list.id}>
                            <ListHeading>
                                <Heading2>{parseItalic(list.title)}</Heading2>
                            </ListHeading>
                            <List>
                                {list.links?.map(item => (
                                    <ListItem key={item.id}>
                                        <ConditionalWrap
                                            condition={item.link}
                                            wrap={children => (
                                                <StyledLink to={item.link} type={item.linkType}>
                                                    {children}
                                                </StyledLink>
                                            )}
                                            fallback={children => <StyledItem>{children}</StyledItem>}
                                        >
                                            <StyledChevron $isLink={item.link} />
                                            {parseItalic(item.linkLabel)}
                                            {item.linkType === 'external' && <StyledExternalLink />}
                                        </ConditionalWrap>
                                    </ListItem>
                                ))}
                            </List>
                        </LinkList>
                    ))}
                </StyledColumns>
            </Container>
        </Section>
    )
}