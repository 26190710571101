import {createGlobalStyle} from "styled-components";
import normalize from "styled-normalize";

const GlobalStyle = createGlobalStyle`
    ${normalize}
    
    * { 
        box-sizing: border-box;
    }

    :root {
        /* Color properties */
        --color-white: #fff;
        --color-black: #000;
        
        --color-yellow: #EAC500;
        --color-yellow-80: #D5B300;
        
        --color-dark-blue-40: #3D6279;
        --color-dark-blue: #1B4965;
        --color-dark-blue-60: #1E445B;
        --color-dark-blue-80: #13374D;
        
        --color-light-blue-40: #EBF3F4;
        --color-light-blue: #C6DBE0;
        
        --color-background: #FEFEFE;
        --color-stroke: #E9E9E9;
        --color-knowledge-center-border: #E3E3E3;
        --color-transparent-button-hover: #FBFBFB;
        --color-placeholder: #AAAAAA; 
        
        --color-body-text: #585862;
        --color-dark-text: #262629;
        --color-caption-text: #B0B0BB;
        
        --color-error: #d9675f;
        
        /* Font properties */
        --font-headers: 'Inter', sans-serif;
        --font-main-text: 'Noto Sans', sans-serif;

        --fw-regular: 400;
        --fw-semi-bold: 600;
        --fw-bold: 700;
        --fw-extra-bold: 800;
        
        // Font sizes desktop (1024px)
        --fs-page-title: 5.6rem;
        --fs-heading-1: 4.2rem;
        --fs-heading-2: 3.2rem;
        --fs-heading-3: 2.6rem;
        --fs-heading-4: 2.2rem;
        --fs-heading-5: 1.8rem;
        --fs-heading-6: 1.6rem;
        --fs-body-large: 2.0rem;
        --fs-body-default: 1.6rem;
        --fs-body-small: 1.4rem;
        --fs-button-large: 1.6rem;
        --fs-button-default: 1.6rem;
        --fs-button-small: 1.4rem;
        --fs-select-label: 1.4rem;
        --fs-navigation: 1.6rem;
        --fs-sub-navigation-link: 2.2rem;

        // Font sizes tablet (768px)
        --fs-tablet-page-title: 4.5rem;
        --fs-tablet-heading-1: 3.4rem;
        --fs-tablet-heading-2: 2.8rem;
        --fs-tablet-heading-3: 2.0rem;
        --fs-tablet-heading-4: 1.8rem;

        // Font sizes mobile (475px)
        --fs-mobile-page-title: 3.0rem;
        --fs-mobile-heading-1: 2.8rem;
        --fs-mobile-heading-2: 2.4rem;
        --fs-mobile-heading-3: 1.8rem;
        --fs-mobile-heading-4: 1.8rem;
        --fs-mobile-heading-5: 1.6rem;
        --fs-mobile-heading-6: 1.6rem;
        --fs-mobile-body-large: 1.6rem;
        --fs-mobile-body-default: 1.6rem;
        --fs-mobile-body-small: 1.4rem;
        --fs-mobile-button-large: 1.6rem;
        --fs-mobile-button-default: 1.4rem;
        --fs-mobile-button-small: 1.4rem;
        --fs-mobile-select-label: 1.4rem;
        --fs-mobile-navigation: 2.0rem;
        --fs-mobile-sub-navigation-link: 1.8rem;
        
        /* Box shadow */
        --box-shadow: 0 4px 8px 0 rgba(0, 0, 0, .05); 
        --box-shadow-from-below: 0 -4px 8px 0 rgba(0, 0, 0, .10); 
        --box-shadow-pdf: 0 4px 8px 0 rgba(0, 0, 0, .25); 
    }

    html, body {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        margin: 0;
        padding: 0;
        font-size: 62.5%;
        font-family: var(--font-main-text), ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
        background-color: var(--color-background);
        line-height: 1.5;
        -webkit-text-size-adjust: 100%;
        tab-size: 4;
        font-feature-settings: normal;
    }

    em { font-style: italic; }
    
    /* NProgress navigation loader bar */
    #nprogress {
        pointer-events: none;
    }

    #nprogress .bar {
        background: var(--color-yellow);
        position: fixed;
        z-index: 1000;
        top: 0;
        left: 0;
        width: 100%;
        height: 3px;
    }
    
    .grecaptcha-badge {
        z-index: 9999;
    }
`

export default GlobalStyle;