import BG from '../assets/flags/BG.svg';
import CS from '../assets/flags/CS.svg';
import DA from '../assets/flags/DA.svg';
import DE from '../assets/flags/DE.svg';
import EL from '../assets/flags/EL.svg';
import EN from '../assets/flags/EN.svg';
import ES from '../assets/flags/ES.svg';
import ET from '../assets/flags/ET.svg';
import FI from '../assets/flags/FI.svg';
import FR from '../assets/flags/FR.svg';
import HU from '../assets/flags/HU.svg';
import IT from '../assets/flags/IT.svg';
import LT from '../assets/flags/LT.svg';
import LV from '../assets/flags/LV.svg';
import NB from '../assets/flags/NB.svg';
import NL from '../assets/flags/NL.svg';
import PL from '../assets/flags/PL.svg';
import PT from '../assets/flags/PT.svg';
import RO from '../assets/flags/RO.svg';
import RU from '../assets/flags/RU.svg';
import SK from '../assets/flags/SK.svg';
import SL from '../assets/flags/SL.svg';
import SV from '../assets/flags/SV.svg';
import TR from '../assets/flags/TR.svg';
import UK from '../assets/flags/UK.svg';
import bg_locale from '../locales/bg/translation.json';
import cs_locale from '../locales/cs/translation.json';
import da_locale from '../locales/da/translation.json';
import de_locale from '../locales/de/translation.json';
import el_locale from '../locales/el/translation.json';
import en_locale from '../locales/en/translation.json';
import es_locale from '../locales/es/translation.json';
import et_locale from '../locales/et/translation.json';
import fi_locale from '../locales/fi/translation.json';
import fr_locale from '../locales/fr/translation.json';
import hu_locale from '../locales/hu/translation.json';
import it_locale from '../locales/it/translation.json';
import lt_locale from '../locales/lt/translation.json';
import lv_locale from '../locales/lv/translation.json';
import nb_locale from '../locales/nb/translation.json';
import nl_locale from '../locales/nl/translation.json';
import pl_locale from '../locales/pl/translation.json';
import pt_locale from '../locales/pt-pt/translation.json';
import ro_locale from '../locales/ro/translation.json';
import ru_locale from '../locales/ru/translation.json';
import sk_locale from '../locales/sk/translation.json';
import sl_locale from '../locales/sl/translation.json';
import sv_locale from '../locales/sv/translation.json';
import tr_locale from '../locales/tr/translation.json';
import uk_locale from '../locales/uk/translation.json';

export const languagesData = [
    { language: "Bulgarian", code: "BG", image: BG, locale: bg_locale },
    { language: "Czech", code: "CS", image: CS, locale: cs_locale },
    { language: "Danish", code: "DA", image: DA, locale: da_locale },
    { language: "Dutch", code: "NL", image: NL, locale: nl_locale },
    { language: "English", code: "EN", image: EN, locale: en_locale },
    { language: "Estonian", code: "ET", image: ET, locale: et_locale },
    { language: "Finnish", code: "FI", image: FI, locale: fi_locale },
    { language: "French", code: "FR", image: FR, locale: fr_locale },
    { language: "German", code: "DE", image: DE, locale: de_locale },
    { language: "Greek", code: "EL", image: EL, locale: el_locale },
    { language: "Hungarian", code: "HU", image: HU, locale: hu_locale },
    { language: "Italian", code: "IT", image: IT, locale: it_locale },
    { language: "Latvian", code: "LV", image: LV, locale: lv_locale },
    { language: "Lithuanian", code: "LT", image: LT, locale: lt_locale },
    { language: "Norwegian", code: "NB", image: NB, locale: nb_locale },
    { language: "Polish", code: "PL", image: PL, locale: pl_locale },
    { language: "Portuguese", code: "PT-PT", image: PT, locale: pt_locale },
    { language: "Romanian", code: "RO", image: RO, locale: ro_locale },
    { language: "Russian", code: "RU", image: RU, locale: ru_locale },
    { language: "Slovak", code: "SK", image: SK, locale: sk_locale },
    { language: "Slovenian", code: "SL", image: SL, locale: sl_locale },
    { language: "Spanish", code: "ES", image: ES, locale: es_locale },
    { language: "Swedish", code: "SV", image: SV, locale: sv_locale },
    { language: "Turkish", code: "TR", image: TR, locale: tr_locale },
    { language: "Ukrainian", code: "UK", image: UK, locale: uk_locale },
]