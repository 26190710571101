import styled from 'styled-components';
import {defer, Form, useLoaderData, useLocation, useMatch, useNavigation, useOutletContext, useRouteLoaderData, useSearchParams} from 'react-router-dom';
import qs from 'qs';
import {parseParametersFromUrl} from './KnowledgeCenterPage';
import React, {useEffect, useState} from 'react';
import {Text} from '../../components/Text/Text';
import {Button} from '../../components/Button/Button';
import {KnowledgeCenterItem} from '../../components/KnowledgeCenter/KnowledgeCenterItem';
import {Heading6} from '../../components/Heading/Heading';
import {ReactComponent as SearchIcon} from '../../assets/icons/search.svg';
// import selectIcon from '../../assets/icons/select.svg';
import {Spinner} from '../../components/Spinner/Spinner';
import {searchFilterEvent} from '../../events/knowledgeCenter';
import {KnowledgeCenterFeaturedItem} from '../../components/KnowledgeCenter/KnowledgeCenterFeaturedItem';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import {useTranslation} from 'react-i18next';

const Items = styled.section`
    margin-top: 30px;
`;

const Empty = styled.article`
    padding: 80px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    
    ${Heading6} {
        margin-bottom: 5px;
    }
    
    ${Text} {
        margin-bottom: 20px;
        max-width: 250px;
    }
`;

const EmptySuggestion = styled.div`
    padding-top: 60px;
`;

const Searchbar = styled.div`
    display: flex;
    border: 1px solid var(--color-stroke);
    border-radius: 4px;
    margin-bottom: 20px;
    overflow: hidden;

    &:focus-within {
        border: 1px solid var(--color-dark-blue);
    }
    
    @media screen and (max-width: 768px) {
        margin-bottom: 10px;
    }
`;

const SearchbarInput = styled(Text).attrs({
    as: 'input'
})`
    flex-grow: 2;
    border: none;
    outline: none;
    padding: 10px 0 10px 10px;
    border-radius: 0;
    appearance: none;
    background-color: var(--color-white);

    &::placeholder {
        color: var(--color-placeholder);
    }

    &::-webkit-search-decoration, &::-webkit-search-cancel-button, &::-webkit-search-results-button, &::-webkit-search-results-decoration { display: none; }
`;

const SearchbarButton = styled.button`
    margin: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background-color: var(--color-dark-blue);
    color: var(--color-white);
    cursor: pointer;
    width: 44px;
    height: 44px;
    border-radius: 2px;
    
    &:hover {
        background-color: var(--color-dark-blue-80);
    }
    
    &:disabled {
        pointer-events: none;
    }
`;

// const Select = styled(Text).attrs({
//     as: 'select'
// })`
//     appearance: none;
//     background-color: var(--color-white);
//     background-image: url(${selectIcon});
//     background-repeat: no-repeat, repeat;
//     background-position: right 5px center, 0 0;
//     border: 1px solid var(--color-stroke);
//     border-radius: 4px;
//     outline: none;
//     padding: 10px 34px 10px 10px;
//     font-weight: bold;
//     color: var(--color-dark-text);
//
//     &:focus {
//         border: 1px solid var(--color-dark-blue);
//     }
//
//     &::placeholder {
//         color: var(--color-placeholder);
//     }
// `;
//
// const Sort = styled.div`
//     display: flex;
//     align-items: center;
//     gap: 10px;
//
//     @media screen and (max-width: 1024px) {
//         gap: 5px;
//     }
// `;
//
// const SortLabel = styled(Text).attrs({
//     $small: true,
//     as: "label",
// })`
//     @media screen and (max-width: 768px) {
//         display: none;
//     }
// `;

const Actions = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap-reverse;
`;

const ClearFilters = styled.div`
    flex-grow: 1;
`;

const Suggestion = styled.div``;

const ItemsWrapper = styled.div`
    position: relative;
`;

export const Overlay = styled.div`
    background: var(--color-background);
    opacity: .7;
    position: absolute;
    inset: 0;
`;

const SpinnerWrapper = styled.div`
    position: absolute;
    top: ${({$empty}) => $empty ? '116px' : '40px'};
    left: 50%;
    transform: translateX(-50%);
`;

const PaginationSpinner = styled.div`
    display: flex;
    justify-content: center;
    padding: 60px 0 20px;
`;

const StyledSpinner = styled(Spinner)`
    background: var(--color-background);
    box-shadow: 0 0 0 11px var(--color-background), 0 0 10px 4px var(--color-black);
`;

let abortController; // Create a new AbortController instance every navigation to cancel old filter calls

export async function knowledgeCenterItemsLoader({request, params}) {
    // Search params (q = for search, sort = for sorting)
    const url = new URL(request.url);
    const q = url.searchParams.get("q");
    const sort = url.searchParams.get("sort");
    const lang = url.searchParams.get("lang");

    // If there is a previous fetch pending, cancel it by calling abort() on the AbortController.
    if (abortController) {
        abortController.abort();
    }

    abortController = new AbortController();

    const parametersFromUrl = params["*"];
    const parsedParameters = parseParametersFromUrl(parametersFromUrl);

    const queryString = qs.stringify({
        filters: parsedParameters,
        q: q,
        sort: sort,
        lang: lang,
        page: {
            size: 20,
            number: 1
        }
    }, { encodeValuesOnly: true, skipNulls: true });

    const knowledgeCenterItemsRes = await fetch(`${process.env.REACT_APP_API_BASE_URL}/v1/knowledge-center-items?${queryString}`, {
        signal: abortController.signal
    }).then((res) => res.json());

    return defer({knowledgeCenterItemsRes, filters: parsedParameters, q, sort, lang})
}

export function KnowledgeCenterItems() {
    const { t } = useTranslation();
    const data = useLoaderData();
    const {footerData} = useRouteLoaderData("root");
    let [searchParams,] = useSearchParams();
    const navigation = useNavigation();

    const route = useMatch("/knowledge-center");
    const subRoute = useMatch(":prefix/knowledge-center");
    const match = route || subRoute;

    const [query, setQuery] = useState(data?.q);
    // const [sort, setSort] = useState(data?.sort);
    const searching = navigation?.location?.pathname?.includes("knowledge-center") && new URLSearchParams(navigation.location.search).has("q");
    const filtering = navigation?.location?.pathname?.includes("knowledge-center");
    // const submit = useSubmit();
    const location = useLocation();

    useEffect(() => {
        setQuery(data?.q);

        if(data?.q) {
            searchFilterEvent(data?.q);
        }
    }, [data?.q]);

    // useEffect(() => {
    //     setSort(data?.sort);
    // }, [data?.sort]);

    const {removeTagcloudItemsExceptActive, setSpecifyItems} = useOutletContext();

    useEffect(() => {
        if(data?.knowledgeCenterItemsRes?.specify) {
            setSpecifyItems(data.knowledgeCenterItemsRes.specify);
        }

        if(!data?.knowledgeCenterItemsRes?.specify) {
            removeTagcloudItemsExceptActive();
        }

    // eslint-disable-next-line
    }, [data?.knowledgeCenterItemsRes]);

    return (
        <>
            <Form id="search-form" role="search" action={location.pathname} preventScrollReset={true}>
                <Searchbar>
                    <SearchbarInput
                        id="q"
                        aria-label="Search in the knowledge center"
                        placeholder={t('knowledgeCenter.search')}
                        type="search"
                        name="q"
                        value={query || ""}
                        disabled={searching}
                        onChange={(e) => {
                            setQuery(e.target.value);
                        }}
                    />

                    {searchParams.get('lang') && <input type="hidden" name="lang" defaultValue={searchParams.get('lang')} />}

                    <SearchbarButton type="submit" disabled={searching}><SearchIcon /></SearchbarButton>
                </Searchbar>

                {data?.knowledgeCenterItemsRes?.items?.length > 0 &&
                    <Actions>
                        <Text>{t('knowledgeCenter.result', {count: data?.knowledgeCenterItemsRes?.count})}</Text>

                        <ClearFilters>
                            {(!match || data?.q) && (
                                <Button to={footerData?.knowledgecenter} preventScrollReset={true} hideArrow $variant="outline">{t('knowledgeCenter.btn_clear_filters')}</Button>
                            )}
                        </ClearFilters>

                        <Suggestion>
                            {footerData?.suggestion && <Button to={footerData?.suggestion} hideArrow suggestion $variant="outline">{t('knowledgeCenter.btn_make_a_suggestion')}</Button>}
                        </Suggestion>

                        {/*<Sort>*/}
                        {/*    <SortLabel htmlFor="sort">Sort by</SortLabel>*/}
                        {/*    <Select*/}
                        {/*        id="sort"*/}
                        {/*        aria-label="Sort the knowledge center"*/}
                        {/*        name="sort"*/}
                        {/*        value={sort || ""}*/}
                        {/*        disabled={searching}*/}
                        {/*        onChange={(e) => {*/}
                        {/*            setSort(e.target.value);*/}
                        {/*            submit(e.currentTarget.form, { preventScrollReset: true });*/}
                        {/*        }}*/}
                        {/*    >*/}
                        {/*        <option value="publicationDate">Publication date (newest first)</option>*/}
                        {/*        <option value="-publicationDate">Publication date (oldest first)</option>*/}
                        {/*    </Select>*/}
                        {/*</Sort>*/}
                    </Actions>
                }
            </Form>

            <KnowledgeCenterPaginatedItems data={data} filtering={filtering} t={t} footerData={footerData} />
        </>
    );
}

function KnowledgeCenterPaginatedItems({data, filtering, t, footerData}) {
    const [count, setCount] = useState(data?.knowledgeCenterItemsRes?.count);
    const [paginatedData, setPaginatedData] = useState(data?.knowledgeCenterItemsRes?.items);
    const [loading, setLoading] = useState(false);
    const [hasNextPage, setHasNextPage] = useState(count > paginatedData.length);
    const [error, setError] = useState(false);
    const [page, setPage] = useState(2);

    // Reset states when filtering
    useEffect(() => {
        setCount(data?.knowledgeCenterItemsRes?.count);
        setPaginatedData(data?.knowledgeCenterItemsRes?.items);
        setHasNextPage(data?.knowledgeCenterItemsRes?.count > data?.knowledgeCenterItemsRes?.items.length);
        setPage(2);
        //eslint-disable-next-line
    }, [data?.filters, data?.q, data?.sort])

    const queryString = qs.stringify({
        filters: data?.filters,
        q: data?.q,
        sort: data?.sort,
        lang: data?.lang,
        page: {
            size: 20,
            number: page
        }
    }, { encodeValuesOnly: true, skipNulls: true });

    async function loadMore() {
        setLoading(true);

        try {
            const fetchedData = await fetch(`${process.env.REACT_APP_API_BASE_URL}/v1/knowledge-center-items?${queryString}`).then((res) => {
                if (!res.ok) {
                    throw new Response("Bad request", { status: 400 });
                }

                return res.json();
            })

            setPaginatedData([...paginatedData, ...fetchedData.items]);
            setPage(page + 1);

            if (count <= [...paginatedData, ...fetchedData.items].length) setHasNextPage(false);
        } catch (err) {
            setError(err);
        } finally {
            setLoading(false);
        }
    }

    const [sentryRef] = useInfiniteScroll({
        loading,
        hasNextPage,
        onLoadMore: loadMore,
        disabled: !!error,
        rootMargin: '0px 0px 0px 0px',
    });

    return (
        <ItemsWrapper>
            {paginatedData?.length > 0
                ? (
                    <>
                        <Items>
                            {paginatedData?.map(item => (
                                (item.featured)
                                    ? <KnowledgeCenterFeaturedItem key={item.id} data={item} />
                                    : <KnowledgeCenterItem key={item.id} data={item} />
                            ))}
                        </Items>

                        {(loading || hasNextPage) && (
                            <PaginationSpinner ref={sentryRef}>
                                <Spinner />
                            </PaginationSpinner>
                        )}
                    </>
                ) : (
                    <KnowledgeCenterItemsBoundary status="empty" t={t} footerData={footerData} />
                )
            }

            {filtering &&
                <>
                    <Overlay/>
                    <SpinnerWrapper $empty={data?.knowledgeCenterItemsRes?.items?.length <= 0}>
                        <StyledSpinner />
                    </SpinnerWrapper>
                </>
            }
        </ItemsWrapper>
    );
}

export function KnowledgeCenterItemsBoundary({ t, status = "error", footerData}) {
    return (
        <Empty>
            <Heading6>{status === "empty" ? t('knowledgeCenter.empty_notice_title') : t('knowledgeCenter.error_title')}</Heading6>
            <Text>{status === "empty" ? t('knowledgeCenter.empty_notice_text') : t('knowledgeCenter.error_text')}</Text>
            <Button to={footerData?.knowledgecenter} preventScrollReset={true} hideArrow $variant="outline">{status === "empty" ? t('knowledgeCenter.btn_clear_filters') : t('knowledgeCenter.btn_error')}</Button>
            {status === "empty" && (
                <EmptySuggestion>
                    <Heading6>{t('knowledgeCenter.empty_suggest_title')}</Heading6>
                    <Text>{t('knowledgeCenter.empty_suggest_text')}</Text>
                    {footerData?.suggestion && <Button to={footerData?.suggestion} hideArrow suggestion $variant="outline">{t('knowledgeCenter.btn_make_a_suggestion')}</Button>}
                </EmptySuggestion>
            )}
        </Empty>
    );
}