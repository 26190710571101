import {Blocks} from '../../components/Blocks/Blocks';
import {createSearchParams, Outlet, useLoaderData, useMatch, useNavigate, useRouteLoaderData, useSearchParams} from 'react-router-dom';
import {Helmet} from 'react-helmet-async';
import styled from 'styled-components';
import qs from 'qs';
import {useImmer} from 'use-immer';
import {FiltersAside} from '../../components/Filters/FiltersAside';
import {Container, Section} from '../../components/Utils/Utils';
import {useEffect, useState} from 'react';

const Flex = styled.section`
    display: flex;
    gap: 30px;
    width: 100%;
    
    @media screen and (max-width: 768px) {
        flex-direction: column;
    }
`;

const SideBarBox = styled.aside`
    flex: 1 1 330px;
    padding-bottom: 60px;
    padding-right: 30px;
    border-right: 1px solid var(--color-knowledge-center-border);
    
    @media screen and (max-width: 768px) {
        flex-basis: 0;
        padding-bottom: 0;
        padding-right: 0;
        border: none;
    }
`;

const ItemsGridBox = styled.section`
    flex: 1 1 100%;
`;

// Parse the URL parameters to a QueryString to build the correct API endpoint when navigating to the (filtered) knowledge center.
export function parseParametersFromUrl(params) {
    const parametersFromUrl = params;

    if(parametersFromUrl) {
        const keyValuePairs = parametersFromUrl.split("/");

        return keyValuePairs.reduce((acc, keyValuePair) => {
            const [key, valueString] = keyValuePair.split(":");

            acc[key] = valueString.split(",");

            return acc;
        }, {});
    }
}

export async function knowledgeCenterPageLoader({request, params}) {
    const url = new URL(request.url);
    const lang = url.searchParams.get("lang");

    // Initial loader for the '/knowledge-center' page. It fetches the '/filters' endpoint to know which filters are active.
    // This loader gets called only when you first load the '/knowledge-center' page, it doesn't revalidate.
    const parametersFromUrl = params["*"];
    const parsedParameters = parseParametersFromUrl(parametersFromUrl);

    const queryString = qs.stringify({filters: parsedParameters}, { encodeValuesOnly: true });

    let filtersEndpoint;
    if(queryString) {
        filtersEndpoint = `${process.env.REACT_APP_API_BASE_URL}/v1/knowledge-center-filters?${queryString}${lang ? `&lang=${lang}` : ''}`
    } else {
        filtersEndpoint = `${process.env.REACT_APP_API_BASE_URL}/v1/knowledge-center-filters${lang ? `?lang=${lang}` : ''}`
    }

    const [knowledgeCenterPageData, knowledgeCenterFilters] = await Promise.all([
        // Fetch knowledge center page blocks
        fetch(`${process.env.REACT_APP_API_BASE_URL}/v1/dynamic-page?url=knowledge-center${lang ? `&lang=${lang}` : ''}`, {
            signal: request.signal
        }).then((res) => {
            if(!res.ok) {
                throw new Response("Page is not found", { status: 404 });
            }

            return res.json();
        }),

        // Fetch knowledge center filters
        fetch(filtersEndpoint, {
            signal: request.signal
        }).then((res) => {
            if(!res.ok) {
                throw new Response("Page is not found", { status: 404 });
            }

            return res.json();
        }),
    ])

    return ({knowledgeCenterPageData, filters: knowledgeCenterFilters});
}

export default function KnowledgeCenterPage() {
    const data = useLoaderData();
    const {footerData} = useRouteLoaderData("root");
    let [searchParams,] = useSearchParams();
    const [selectedFilters, updateSelectedFilters] = useImmer(data.filters);
    const [specifyItems, setSpecifyItems] = useState([]);

    // Update selected filters when data.filters changes (switching language)
    useEffect(() => {
        updateSelectedFilters(data.filters);
    }, [data.filters, updateSelectedFilters]);

    const [navigateLink, setNavigateLink] = useState("initial");
    const navigate = useNavigate();

    // If pathname matches, set all filters to active=false (visually clearing filters)
    const route = useMatch("/knowledge-center");
    const subRoute = useMatch(":prefix/knowledge-center");
    const match = route || subRoute;
    useEffect(() => {
        if(!!match) {
            updateSelectedFilters(draft => {
                draft.forEach(filter => {
                    filter.filterItems.forEach(item => {
                        if (item.active) {
                            item.active = false;
                        }
                    });
                });
            });

            setNavigateLink("initial")
        }
    }, [match, updateSelectedFilters]);

    function handleToggle(categoryId, filterId, active) {
        updateSelectedFilters(draft => {
            // Find the filter object with the specified ID
            const filter = draft.find(a =>
                a.id === categoryId
            );

            // Find the value object with the specified ID and update its "active" property
            // eslint-disable-next-line
            filter.filterItems.find(a => {
                if(a.id === filterId) {
                    a.active = active;
                }
            });

            // Reduce the selectedFilters object
            const reducedObject = draft.reduce((acc, cur) => {
                acc[cur.slug] = cur.filterItems
                    .filter(value => value.active)
                    .map(value => value.slug);
                return acc;
            }, {});

            // Create a nice URL from the reduced Object and parse it to the following format: filterCategory:filter,filter/filterCategory:filter,filter
            const reducedString = Object.entries(reducedObject)
                .filter(([, values]) => values.length > 0)
                .map(([key, values]) => `${key}:${values.join(",")}`)
                .join("/");

            if(reducedString) {
                setNavigateLink(`filter/${reducedString}`);
            } else {
                setNavigateLink(reducedString);
            }
        });
    }

    // Add tagcloud items to selectedFilters when specifyItems changes (specifyItems are sent with results and not with filters)
    useEffect(() => {
        updateSelectedFilters(draft => {
            // Find the filter object with the specified ID
            const filter = draft.find(a =>
                a.type === "tagcloud"
            );

            filter.filterItems = specifyItems;
        });
    }, [specifyItems, updateSelectedFilters]);

    function removeTagcloudItemsExceptActive() {
        updateSelectedFilters(draft => {
            // Find the filter object with the specified ID
            const filter = draft.find(a =>
                a.type === "tagcloud"
            );

            const activeItems = filter?.filterItems?.filter(item => item.active);

            if (filter?.filterItems) {
                filter.filterItems = activeItems;
            }
        });
    }

    useEffect(() => {
        if(navigateLink !== "initial") {
            // Use the `navigate` function from React Router to navigate to the new URL, but keep search params
            navigate({
                pathname: navigateLink,
                search: createSearchParams({
                    ...(searchParams.get('q') && {q: searchParams.get('q')}),
                    ...(searchParams.get('lang') && {lang: searchParams.get('lang')}),
                    // ...(searchParams.get('sort') && {sort: searchParams.get('sort')}),
                }).toString()
            }, {preventScrollReset: true});
        }
    // eslint-disable-next-line
    }, [navigateLink]);

    // Set isLast to false
    const lastBlockOfArray = data?.knowledgeCenterPageData?.blocks.find(block => block === data?.knowledgeCenterPageData?.blocks[data?.knowledgeCenterPageData?.blocks.length -1]);

    if(lastBlockOfArray) {
        lastBlockOfArray.isLast = false;
    }

    return (
        <>
            <Helmet>
                <title>Pollinator Academy &mdash; {data?.knowledgeCenterPageData?.title}</title>
                <link rel="canonical" href={`https://pollinatoracademy.eu${footerData?.knowledgecenter}`} />
            </Helmet>

            <Blocks data={data?.knowledgeCenterPageData?.blocks} pageType={data?.knowledgeCenterPageData?.pageType} />

            <Section $paddingTop $paddingBottom $pageType={data?.knowledgeCenterPageData?.pageType}>
                <Container>
                    <Flex>
                        <SideBarBox>
                            <FiltersAside filters={selectedFilters} toggleFn={handleToggle} />
                        </SideBarBox>
                        <ItemsGridBox>
                            <Outlet context={{removeTagcloudItemsExceptActive, setSpecifyItems}} />
                        </ItemsGridBox>
                    </Flex>
                </Container>
            </Section>
        </>
    );
};