import styled from 'styled-components';
import {Heading5} from '../../components/Heading/Heading';
import {Text} from '../../components/Text/Text';
import CheckboxInactive from '../../assets/icons/checkbox-inactive.svg';
import CheckboxActive from '../../assets/icons/checkbox-active.svg';

export const Form = styled.form`
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 40px;
    background-color: var(--color-white);
    border-radius: 10px;
    box-shadow: var(--box-shadow);
    overflow: hidden;

    @media screen and (max-width: 1024px) {
        padding: 20px;
    }
`;

export const BasicField = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
`;

export const CheckboxField = styled.div`
    display: flex;
    gap: 17px;
    align-items: center;
`;

export const LabelBox = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
`;

export const Label = styled(Heading5).attrs({
    as: "label",
})``;

export const CheckboxLabel = styled(Text).attrs({
    as: "label",
})`
    cursor: pointer;
`;

export const Required = styled(Text)`
    font-style: italic;
`;

export const Input = styled(Text)`
    padding: ${({$icon}) => $icon ? '12px 12px 12px 48px' : '12px'};
    border: 1px solid ${({$error}) => $error ? 'var(--color-error)' : 'var(--color-stroke)'};
    border-radius: 4px;

    ${({$icon}) => $icon && `
        background-image: url(${$icon});
        background-size: 24px;
        background-position-x: 12px;
        background-position-y: 50%;
        background-repeat: no-repeat;
    `}

    ${({$textarea}) => $textarea && `
        resize: vertical;
    `}

    :focus {
        outline: none !important;
        border: 1px solid var(--color-dark-blue);
    }

    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: var(--color-placeholder);
        opacity: 1; /* Firefox */
    }
`;

export const CheckboxInput = styled.input`
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 33px;
    height: 33px;
    flex-shrink: 0;
    content: url(${CheckboxInactive});
    cursor: pointer;

    &:checked{
        content: url(${CheckboxActive});
        border: 1px solid var(--color-dark-blue);
        border-radius: 4px;
    }

    :focus {
        outline: none !important;
        border: 1px solid var(--color-dark-blue);
    }
`;

export const SubmitBox = styled.div`
    margin-left: auto;
`;

export const ErrorText = styled(Text)`
    color: var(--color-error);
`;