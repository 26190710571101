import styled from 'styled-components';
import {ReactComponent as Filter} from '../../assets/icons/filter.svg';
import {useToggleMenu} from '../../hooks/useToggleMenu';
import {FilterSelect} from './FilterSelect';
import {FilterCheckbox} from './FilterCheckbox';
import {Button, buttonStyles} from '../Button/Button';
import {Heading4} from '../Heading/Heading';
import {MenuButton, MenuText} from '../NavBar/NavBar';
import {StyledClose} from '../NavBar/NavBarHamburger';
import React from 'react';
import {FilterGroup} from './FilterGroup';
import {FilterTagcloud} from './FilterTagcloud';

const StyledFiltersAside = styled.section``;

const FilterButton = styled.button`
    display: none;

    @media screen and (max-width: 768px) {
        ${buttonStyles};
        
        //border-radius: 5px;
        //background-color: var(--color-yellow);
        //color: var(--color-dark-text);
        //path { fill: var(--color-dark-text); }
        //display: inline-flex;
        //gap: 10px;

        &:hover {
            background-color: var(--color-yellow-80);
        }
    }
`;

const Content = styled.section`
    
    @media screen and (max-width: 768px) {
        position: fixed;
        top: 0;
        right: ${props => props.$isOpen ? '0' : '100%'};
        background-color: var(--color-white);
        min-height: 100vh;
        height: 100%;

        @supports (min-height: -webkit-fill-available) {
            min-height: -webkit-fill-available;
        }
        
        width: 100%;
        z-index: 101;
        transition: right .2s ease;
    }
`;

const Inner = styled.div`
    display: flex;
    flex-direction: column;
    gap: 30px;
    
    @media screen and (max-width: 768px) {
        position: relative;
        overflow-y: auto;
        padding: 30px 20px 200px;
        height: 100%;   
        gap: 10px;
    }
    
    @media screen and (max-width: 320px) {
        padding: 30px 10px 200px;
    }
`;

const MobileHeader = styled.div`
    display: none;

    @media screen and (max-width: 768px) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: var(--color-dark-blue);
        padding: 13px 20px;
    }
    
    @media screen and (max-width: 320px) {
        padding: 13px 10px;
    }
`;

const MobileFixedButtonWrapper = styled.div`
    display: none;

    @media screen and (max-width: 768px) {
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        right: ${props => props.$isOpen ? '0' : '100%'};
        bottom: 0;
        width: 100%;
        padding: 20px;
        background-color: var(--color-white);
        box-shadow: var(--box-shadow-from-below);
        transition: right .2s ease;
    }
`;

export const FiltersAside = ({filters, toggleFn}) => {
    const { isOpen, openMenu, closeMenu } = useToggleMenu();

    return (
        <StyledFiltersAside>
            <FilterButton onClick={openMenu}><Filter /> Filter</FilterButton>

            <Content $isOpen={isOpen}>
                <MobileHeader>
                    <Heading4 $white>Filter</Heading4>

                    <MenuButton onClick={closeMenu}>
                        <StyledClose />
                        <MenuText>Close</MenuText>
                    </MenuButton>
                </MobileHeader>

                <Inner>
                    {filters.map(filter => (
                        <FilterGroup key={filter.id} title={filter.title} $tagcloud={filter.type === "tagcloud"}>
                            {filter.type === "checkbox" && (
                                <FilterCheckbox filter={filter} toggleFn={toggleFn} />
                            )}

                            {filter.type === "select" && (
                                <FilterSelect filter={filter} toggleFn={toggleFn} />
                            )}

                            {filter.type === "tagcloud" && (
                                <FilterTagcloud filter={filter} toggleFn={toggleFn} />
                            )}
                        </FilterGroup>
                    ))}

                    <MobileFixedButtonWrapper $isOpen={isOpen}>
                        <Button as='button' onClick={closeMenu} $variant="outline" hideArrow>Show results</Button>
                    </MobileFixedButtonWrapper>
                </Inner>
            </Content>
        </StyledFiltersAside>
    );
}