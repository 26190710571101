import styled from 'styled-components';
import {Heading5} from '../Heading/Heading';
import {Text} from '../Text/Text';
import {ReactComponent as ExternalLink} from '../../assets/icons/external-link.svg';
import {Fragment} from 'react';
import {LabelsRow} from '../LabelsRow/LabelsRow';
import {CustomLink} from '../CustomLink/CustomLink';
import {clickOnKnowledgeCenterItemEvent} from '../../events/knowledgeCenter';

const StyledKnowledgeCenterItem = styled(CustomLink)`
    display: block;
    padding: 0 10px 10px 10px;
    text-decoration: none;
    color: inherit;

    &:not(:last-of-type) {
        border-bottom: 1px solid var(--color-knowledge-center-border);
        padding-bottom: 30px;
        margin-bottom: 30px;
    }
    
    @media screen and (max-width: 768px) {
        padding: 0 0 10px 0;
    }
`;

const StyledHeading5 = styled(Heading5)`
    ${StyledKnowledgeCenterItem}:hover & {
        text-decoration: underline;
    }
`;

const Info = styled(Text)`
    color: var(--color-dark-blue);
    font-weight: var(--fw-bold);
    margin: 2px 0;
`;

const StyledExternalLink = styled(ExternalLink)`
    width: 18px;
    height: 18px;
    vertical-align: middle;
    margin-left: 2px;
    transform: translateY(-2px);
    transition: .1s transform ease;
    
    path { stroke: var(--color-dark-blue); }

    ${StyledKnowledgeCenterItem}:hover & {
        transform: translate(1px, -3px);
    }
`;

export const KnowledgeCenterItem = ({data}) => {
    let isExternal = data.linkType === "external";
    let isDetailPage = data.linkType === "knowledge-center-detail";

    const infoObj = {
        ...(data?.author && {"author": data?.author}),
        ...(data?.displayPublicationDate && {"displayPublicationDate": data?.displayPublicationDate}),
        ...(data?.ISSN && {"ISSN": `ISSN: ${data?.ISSN}`}),
        ...(data?.ISBN && {"ISBN": `ISBN: ${data?.ISBN}`}),
        ...(data?.DOI && {"DOI": `DOI: ${data?.DOI}`}),
    };

    return (
        <StyledKnowledgeCenterItem to={isDetailPage ? `/${data.slug}` : data.link} type={data.linkType} onClick={() => clickOnKnowledgeCenterItemEvent(data.linkType, data.title)}>
            <StyledHeading5 $truncate $clamp="2">{data.title} {isExternal && <StyledExternalLink />}</StyledHeading5>
            {Object.keys(infoObj)?.length > 0 &&
                <Info>
                    {Object.entries(infoObj).map(([key, value], index, {length}) => (
                        <Fragment key={index}>
                            {value}{length - 1 !== index && ", "}
                        </Fragment>
                    ))}
                </Info>
            }
            {data.description && <Text $truncate $clamp="3">{data.description}</Text>}
            <LabelsRow data={data} />
        </StyledKnowledgeCenterItem>
    );
}