import {Heading5} from '../Heading/Heading';
import styled from 'styled-components';
import {ReactComponent as Chevron} from '../../assets/icons/chevron-down.svg';
import {useState} from 'react';


const StyledFilterGroup = styled.div`
    user-select: none;
    width: 100%;
    ${({$tagcloud}) => $tagcloud && `
        border-top: 1px solid var(--color-stroke);
        padding-top: 30px;    
    `};
    
    ${Heading5} { margin-bottom: 10px; }
    
    @media screen and (max-width: 768px) {
        ${({$tagcloud}) => $tagcloud && `
            border-top: unset;
            padding-top: 0;    
        `};  
    }
`;

const Header = styled.div`

    @media screen and (max-width: 768px) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        border-bottom: 1px solid var(--color-stroke);
        padding: 10px 0;
    }
`;

const StyledChevron = styled(Chevron)`
    display: none;
    
    @media screen and (max-width: 768px) {
        display: block;
        ${props => props.$open && `transform: rotate(180deg)`};
        path { fill: var(--color-dark-blue); }
    }
`;

const Content = styled.article`

    @media screen and (max-width: 768px) {
        margin: 20px 0;
        
        &.closed { display: none; }
    }
`;

export const FilterGroup = ({title, children, ...props}) => {
    const [open, setOpen] = useState(false);

    const handleAccordion = () => {
        setOpen(!open);
    }

    return (
        <StyledFilterGroup {...props}>
            <Header onClick={handleAccordion}>
                <Heading5>{title}</Heading5>
                <StyledChevron $open={open} />
            </Header>

            <Content className={open ? 'open' : 'closed'}>
                {children}
            </Content>
        </StyledFilterGroup>
    );
}