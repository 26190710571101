import styled, { css } from "styled-components";
import bulletListIcon from '../../assets/icons/bullet-list.svg';
import bulletListWhiteIcon from '../../assets/icons/bullet-list-white.svg';

export const truncateMixin = css`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  ${({ $clamp = 1 }) => `-webkit-line-clamp: ${$clamp};`}
`;

export const Text = styled.p`
    font-size: ${props => props.$large ? 'var(--fs-body-large)' : props.$small ? 'var(--fs-body-small)' : 'var(--fs-body-default)'};
    color: ${props => props.$white ? 'var(--color-white)' : props.$dark ? 'var(--color-dark-text)' : props.$blue ? 'var(--color-dark-blue)' : 'var(--color-body-text)'};
    line-height: 1.5;
    margin: 0;
    overflow-wrap: anywhere;
    ${({ $truncate }) => $truncate && truncateMixin};
    ${({ $preWrap }) => $preWrap && 'white-space: pre-wrap;'};
    
    @media screen and (max-width: 768px) {
        font-size: ${props => props.$large ? 'var(--fs-mobile-body-large)' : props.$small ? 'var(--fs-mobile-body-small)' : 'var(--fs-mobile-body-default)'};
    }
`;

export const BlockText = styled(Text)`
    max-width: 500px;
    text-align: center;
    margin: 0 auto;
`;

export const HTMLText = styled.div`
    font-size: ${props => props.$large ? 'var(--fs-body-large)' : props.$small ? 'var(--fs-body-small)' : 'var(--fs-body-default)'};
    color: ${props => props.$white ? 'var(--color-white)' : props.$dark ? 'var(--color-dark-text)' : 'var(--color-body-text)'};
    line-height: 1.5;
    margin: 0;
    
    h3 {
        font-family: var(--font-headers);
        font-weight: var(--fw-bold);
        font-size: var(--fs-heading-3);
        color: ${props => props.$white ? 'var(--color-white)' : props.$dark ? 'var(--color-dark-text)' : 'var(--color-dark-blue)'};
        line-height: 1.25;
        margin: 16px 0 0;
        overflow-wrap: anywhere;

        @media screen and (max-width: 768px) {
            font-size: var(--fs-tablet-heading-3);
        }

        @media screen and (max-width: 475px) {
            font-size: var(--fs-mobile-heading-3);
        }
        
        & + * {
            margin-top: 8px;
        }
    }
    
    ul {
        padding: 0;
        list-style-type: none;
    }
    
    ol {
        padding-left: 20px;
    }
    
    ul li {
        background: ${({$white}) => $white ? `url(${bulletListWhiteIcon})` : `url(${bulletListIcon})`} left 3px no-repeat;
        padding: 2px 0 2px 30px;
    }
    
    strong {
        font-weight: var(--fw-bold);
    }
    
    em {
        font-style: italic;
    }
    
    a {
        color: inherit;
        
        &:hover {
            color: ${({$white}) => $white ? 'var(--color-yellow)' : 'var(--color-dark-blue)'};
        }
    }

    & > *:last-child {
        margin-bottom: 0;
    }

    ${({$noMarginTop}) => $noMarginTop && css`
        & > *:first-child {
            margin-top: 0;
        }
    `};

    @media screen and (max-width: 768px) {
        font-size: ${props => props.$large ? 'var(--fs-mobile-body-large)' : props.$small ? 'var(--fs-mobile-body-small)' : 'var(--fs-mobile-body-default)'};
    }
`;