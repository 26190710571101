import styled from 'styled-components';
import React, {useEffect, useRef} from 'react';
import {NavLink} from 'react-router-dom';
import {ReactComponent as Chevron} from '../../assets/icons/chevron-down.svg';
import {navigateEvent} from '../../events/navigation';
import {useCurrentLangParam} from '../../hooks/useCurrentLangParam';

const StyledNavBarItem = styled.div``;

const StyledNavLink = styled(NavLink)`
    color: ${props => props.$onWhite ? 'var(--color-body-text)' : 'var(--color-white)'};
    font-family: var(--font-headers);
    font-size: var(--fs-navigation);
    text-decoration: none;
    display: flex;
    gap: 5px;
    align-items: center;
    height: 100%;
    border-bottom: 2px solid transparent;
    transition: color .2s ease;

    &.active, ${StyledNavBarItem}:hover & {
        border-bottom-color: ${props => props.$onWhite ? 'var(--color-body-text)' : 'var(--color-white)'};
    }

    ${props => props.$open && `border-bottom-color: ${props.$onWhite ? 'var(--color-body-text)' : 'var(--color-white)'};`};
`;

const StyledChevron = styled(Chevron)`
    flex-shrink: 0;
    width: 21px;
    height: 21px;
    transition: .3s ease transform;
    
    path {
        fill: ${props => props.$onWhite ? 'var(--color-body-text)' : 'var(--color-white)'};
        transition: fill .2s ease;
    }

    ${({$open}) => $open && 'transform: rotate(-180deg);'};
`;

export const NavBarItem = ({item, handleNavBarItemEnter, activeFlyOut, setActiveFlyOut, onWhite, open, setOpen}) => {
    let hasItems = item.childs?.length > 0;
    let itemUrl = item?.url.charAt(item?.url?.length - 1) === '/' ? item?.url?.slice(0, -1) : item?.url; // Trim ending slash, so NavLink matches nested routes (for knowledge center detail page)
    const {currentLang} = useCurrentLangParam();

    // On mouseenter, open the parent flyout and set this item to be active
    const ref = useRef(null);
    useEffect(() => {
        const node = ref.current;

        const handleEnter = () => {
            if(hasItems) {
                handleNavBarItemEnter();
                setActiveFlyOut(item.id);
            }
        }

        node.addEventListener("mouseenter", handleEnter);

        return () => {
            node.removeEventListener("mouseenter", handleEnter);
        };
        //eslint-disable-next-line
    }, [ref])

    return (
        <StyledNavBarItem ref={ref}>
            <StyledNavLink to={itemUrl + currentLang} onClick={() => { setOpen(false); navigateEvent(item.titleEn, item.titleEn); }} $onWhite={onWhite} $open={(activeFlyOut === item.id) && open}>
                {item.title} {hasItems && <StyledChevron $open={(activeFlyOut === item.id) && open} $onWhite={onWhite} />}
            </StyledNavLink>
        </StyledNavBarItem>
    );
};