import styled from 'styled-components';
import {useCallback, useRef, useState} from 'react';
import {Swiper} from 'swiper/react';
import {Navigation} from 'swiper';
import {ReactComponent as ArrowLeft} from '../../assets/icons/arrow-left.svg';
import {ReactComponent as ArrowRight} from '../../assets/icons/arrow-right.svg';
import 'swiper/css';

const SwiperWrapper = styled.div`
    position: relative;
    
    @media screen and (max-width: 1440px) {
        margin: 0 auto;
        max-width: calc(100% - 120px);
    }

    @media screen and (max-width: 1024px) {
        margin: unset;
        max-width: unset;
    }

    .swiper {
        padding: 0 8px 8px 8px;

        @media screen and (max-width: 1024px) {
            padding: 0 20px 8px 20px;
        }

        @media screen and (max-width: 320px) {
            padding: 0 10px 8px 10px;
        }
    }
    
    .swiper-slide {
        width: 100%;
        height: auto;
    }
`;

const SwiperButtonWrapper = styled.div`
    height: calc(100% - 48px);
    position: absolute;
    bottom: 8px;
    ${({$type}) => $type === "previous" ? 'left: -60px;' : 'right: -60px;'};
    justify-content: ${({$type}) => $type === "previous" ? 'flex-start' : 'flex-end'};
    z-index: 1;
    display: flex;
    align-items: center;
    width: 10%;
    pointer-events: none;
    
    @media screen and (max-width: 1024px) {
        display: none;
    }
`;

const SwiperButton = styled.button`
    background-color: var(--color-yellow);
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: none;
    outline: none;
    cursor: pointer;
    pointer-events: auto;
    
    svg {
        width: 18px;
        height: 18px;
        
        path { fill: var(--color-dark-text); }
    }
    
    &:hover {
        background-color: var(--color-light-blue);
    }
    
    &:disabled {
        opacity: .25;
        pointer-events: none;
    }
`;

// NOTE: In Swiper's breakpoints the duplicate entries like spaceBetween are needed, otherwise it's the initial value till the breakpoint
export const InfoColumnsSwiper = ({isHorizontal, children}) => {
    const swiperElRef = useRef(null);
    const [disablePrev, setDisablePrev] = useState(false);
    const [disableNext, setDisableNext] = useState(false);

    const handlePrev = useCallback(() => {
        if (!swiperElRef?.current) return;

        swiperElRef.current.swiper.slidePrev();
    }, []);

    const handleNext = useCallback(() => {
        if (!swiperElRef?.current) return;

        swiperElRef.current.swiper.slideNext();
    }, []);

    return (
        <SwiperWrapper>
            <Swiper
                ref={swiperElRef}
                spaceBetween={10}
                slidesPerView={1.2}
                breakpoints={{
                    321: {
                        spaceBetween: 20,
                        slidesPerView: 1.4,
                    },
                    476: {
                        spaceBetween: 20,
                        slidesPerView: isHorizontal ? 1.1 : 1.6,
                    },
                    561: {
                        spaceBetween: 20,
                        slidesPerView: isHorizontal ? 1.1 : 1.8,
                    },
                    651: {
                        spaceBetween: 20,
                        slidesPerView: isHorizontal ? 1.1 : 2.2,
                    },
                    769: {
                        spaceBetween: 30,
                        slidesPerView: isHorizontal ? 1.4 : 2.6,
                    },
                    1025: {
                        spaceBetween: 30,
                        slidesPerView: isHorizontal ? 2 : 3.3,
                    },
                }}
                onSlideChange={(swiper) => {
                    swiper.isBeginning ? setDisablePrev(true) : setDisablePrev(false);
                    swiper.isEnd ? setDisableNext(true) : setDisableNext(false);
                }}
                onSwiper={(swiper) => {
                    swiper.isBeginning ? setDisablePrev(true) : setDisablePrev(false);
                }}
                modules={[Navigation]}
            >
                {children}
            </Swiper>

            <SwiperButtonWrapper $type="previous">
                <SwiperButton onClick={handlePrev} disabled={disablePrev}><ArrowLeft /></SwiperButton>
            </SwiperButtonWrapper>
            <SwiperButtonWrapper $type="next">
                <SwiperButton onClick= {handleNext} disabled={disableNext}><ArrowRight /></SwiperButton>
            </SwiperButtonWrapper>
        </SwiperWrapper>
    );
}