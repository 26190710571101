import {ConditionalWrap, Container, Section} from '../../components/Utils/Utils';
import {Image, StyledHorizontalInner} from '../ContentItem/ContentItem';
import styled from 'styled-components';
import {Text} from '../../components/Text/Text';
import {SketchfabIframe, HorizontalIframe, HorizontalOuter} from '../../components/AspectRatio/AspectRatio';
import {useCookieBannerContext} from '../../contexts/CookieBannerContext';
import {ShouldAllowCookies} from '../../components/CookieBanner/ShouldAllowCookies';
import {useIsFullscreenEnabled} from '../../hooks/useIsFullscreenEnabled';
import {Fullscreen} from '../../components/Fullscreen/Fullscreen';
import {useEffect, useRef} from 'react';

const Caption = styled(Text)`
    color: var(--color-caption-text);
    margin: 10px 0 0 0;
    ${({$indent}) => $indent && 'padding-left: 10px;'};
    
    @media screen and (max-width: 768px) {
        padding: 0;
    }
`;

export const MediaBanner = ({data, ...props}) => {
    const {allowedToLoadEmbed} = useCookieBannerContext();
    const {isFullscreenEnabled} = useIsFullscreenEnabled();
    const sketchfabRef = useRef(null);

    useEffect(() => {
        if(data?.content?.type === "sketchfab" && sketchfabRef?.current) {
            const client = new window.Sketchfab('1.12.1', sketchfabRef.current);
            const sketchfabUID = data?.content?.sketchfabUID;

            client.init(sketchfabUID, {
                success: function onSuccess( api ) {
                    api.start();
                    api.addEventListener( 'viewerready', function() {
                        let speed = data?.content?.speed ?? 1;

                        api.setSpeed(speed);
                    });
                },
                error: function onError() {
                    console.log('Sketchfab Viewer API error');
                },
                autostart: 1,
                dnt: 1,
            });
        }
    }, [allowedToLoadEmbed, data?.content?.type, data?.content?.sketchfabUID, data?.content?.speed]);

    if(!data?.content) { return null; }

    let isFullWidth = data.content.widthType === "fullWidth";
    let isColumnWidth = data.content.widthType === "columnWidth";
    let isCenterWidth = data.content.widthType === "centerWidth";

    return (
        <Section id={data.hashSlug ?? undefined} $scroll={Boolean(data.hashSlug)} $paddingTop={!isFullWidth} $paddingBottom={!isFullWidth && data.isLast} $marginTop={!data.removeSpacing && isFullWidth} {...props}>
            <ConditionalWrap condition={isColumnWidth || isCenterWidth} wrap={children => <Container $small={isCenterWidth}>{children}</Container>}>
                {data.content.type === 'image' && (
                    <Image src={data.content.image} alt={data.content.source || data.content.title} $isFullWidth={isFullWidth} />
                )}

                {data.content.type === 'embedcode' && (
                    <HorizontalOuter>
                        {allowedToLoadEmbed
                            ? (
                                <ConditionalWrap condition={isFullscreenEnabled} wrap={children => <Fullscreen>{children}</Fullscreen>}>
                                    <StyledHorizontalInner dangerouslySetInnerHTML={{__html: data.content.embedCode}} />
                                </ConditionalWrap>
                            )
                            : <ShouldAllowCookies />
                        }
                    </HorizontalOuter>
                )}

                {data.content.type === 'embedurl' && (
                    allowedToLoadEmbed ? (
                        <HorizontalIframe src={data.content.embedURL} title={data.content.title} />
                    ) : (
                        <HorizontalOuter>
                            <ShouldAllowCookies />
                        </HorizontalOuter>
                    )
                )}

                {data.content.type === 'sketchfab' && (
                    allowedToLoadEmbed ? (
                        <SketchfabIframe ref={sketchfabRef} title={data.content.title} />
                    ) : (
                        <HorizontalOuter>
                            <ShouldAllowCookies />
                        </HorizontalOuter>
                    )
                )}

                {(allowedToLoadEmbed && data.content.source) && <Caption $small $indent={isFullWidth}>{data.content.source}</Caption>}
            </ConditionalWrap>
        </Section>
    );
}
