import styled from 'styled-components';
import {ReactComponent as Globe} from '../../assets/icons/globe.svg';
import {ReactComponent as Chevron} from '../../assets/icons/chevron-down.svg';
import {useEffect, useRef, useState} from 'react';
import {Transition} from 'react-transition-group';
import {useClickInside} from '../../hooks/useClickInside';
import {Text} from '../Text/Text';
import {useSearchParams} from 'react-router-dom';
import {translateEvent} from '../../events/translate';
import {languagesData} from '../../constants/languages';
import {useTranslation} from 'react-i18next';

const StyledNavBarLanguage = styled.div`
    align-self: center;
    position: relative;
    ${({$inHamburger}) => $inHamburger && 'margin-top: 50px'};
    ${({$inHamburger, $active}) => ($inHamburger && $active) && 'padding-bottom: 140px;'}; // (dropdown's height - 200) + 20
    ${({$inNavBar}) => $inNavBar && `
        @media screen and (max-width: 560px) {
            display: none;
        }
    `};
`;

const SelectButton = styled.div`
    border-radius: 5px;
    background-color: ${({$active}) => $active ? 'rgba(255, 255, 255, .1)' : 'transparent'};
    border: 1px solid ${({$onWhite}) => $onWhite ? 'var(--color-stroke)' : 'rgba(230, 230, 230, .4)'};
    display: inline-flex;
    gap: 2px;
    align-items: center;
    cursor: pointer;
    padding: 8px;
    line-height: 1.5;
    ${({$inHamburger}) => $inHamburger && 'width: 100%'};
    transition: border-color .2s ease;
    
    path { fill: var(--color-white); }
    
    &:hover {
        background-color: rgba(255, 255, 255, .1);
    }
    
    @media screen and (max-width: 768px) {
        padding: 7px 12px;
    }
`;

const StyledGlobe = styled(Globe)`
    width: 24px;
    height: 24px;
    flex-shrink: 0;

    path { 
        fill: ${({$onWhite}) => $onWhite ? 'var(--color-body-text)' : 'var(--color-white)'};
        transition: fill .2s ease;
    }
`;

const StyledChevron = styled(Chevron)`
    width: 21px;
    height: 21px;
    flex-shrink: 0;
    transition: .1s ease transform;
    margin-left: auto;

    path { 
        fill: ${({$onWhite}) => $onWhite ? 'var(--color-body-text)' : 'var(--color-white)'};
        transition: fill .2s ease;
    }
    
    @media screen and (max-width: 768px) {
        width: 20px;
        height: 20px;
    }

    ${({$active}) => $active && 'transform: rotate(-180deg);'};
`;

const Dropdown = styled.div`
    position: absolute;
    top: 35px;
    right: 0;
    width: ${({$inHamburger}) => $inHamburger ? '100%' : '220px'};
    height: 320px;
    max-height: calc(100vh - 60px);
    overflow-y: auto;
    padding: 8px;
    z-index: 1;
    background-color: var(--color-white);
    box-shadow: var(--box-shadow);
    border-radius: 4px;
    border: 1px solid var(--color-stroke);
    opacity: 0;
    transition: opacity .2s ease, top .2s ease;

    &.entering { opacity: 1; top: 45px; }
    &.entered { opacity: 1; top: 45px; }
    &.exiting { opacity: 0; top: 35px; }
    &.exited { opacity: 0; top: 35px; }
`;

const DropdownItem = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    border-radius: 5px;
    padding: 4px 8px;
    cursor: pointer;
    background-color: ${({$active}) => $active ? 'var(--color-light-blue-40)' : 'transparent'};
    
    &:not(:last-of-type) {
        margin-bottom: 4px;
    }
    
    &:hover {
        background-color: var(--color-light-blue-40);
    }
    
    &:active {
        background-color: var(--color-light-blue-40);
    }

    ${({$active}) => $active && `
        ${Text} { font-weight: var(--fw-bold); }
    `};
`;

const Flag = styled.img`
    width: ${({$selected}) => $selected ? '20px' : '30px'};
    height: ${({$selected}) => $selected ? '20px' : '30px'};
    ${({$selected}) => $selected && 'margin: 2px;'}
    border-radius: 50%;
    border: 1px solid var(--color-stroke);
    object-fit: cover;
`;

const HamburgerText = styled(Text).attrs({
    $white: true,
    $large: true,
})`
    margin-left: 12px;
    font-weight: var(--fw-semi-bold);
    font-size: ${props => props.$large ? 'var(--fs-button-large)' : props.$small ? 'var(--fs-button-small)' : 'var(--fs-button-default)'};
    line-height: 1.5;

    @media screen and (max-width: 768px) {
        font-size: ${props => props.$large ? 'var(--fs-mobile-button-large)' : props.$small ? 'var(--fs-mobile-button-small)' : 'var(--fs-mobile-button-default)'};
    }
`;

export const NavBarLanguage = ({location, shouldShowOnWhite, closeMobileMenu}) => {
    const inNavBar = location === "navBar";
    const inHamburger = location === "hamburger";
    const insideRef = useRef(null);
    const nodeRef = useRef(null);
    const [showLanguages, setShowLanguages] = useState(false);
    const inside = useClickInside(insideRef);

    useEffect(() => {
        if(!inside) { setShowLanguages(false); }
    }, [inside]);

    let [searchParams, setSearchParams] = useSearchParams();
    const [chosenLang, setChosenLang] = useState(searchParams?.get("lang") ?? false)

    const {t, i18n} = useTranslation();
    const chooseLanguage = (langCode) => {
        setShowLanguages(false);
        setChosenLang(langCode);
        setSearchParams(searchParams => {
            searchParams.set("lang", langCode);
            return searchParams;
        });
        i18n.changeLanguage(langCode?.toLowerCase());
        translateEvent(langCode);

        if(inHamburger) {
            closeMobileMenu();
        }
    }

    useEffect(() => {
        setChosenLang(searchParams?.get("lang") ?? false)
    }, [searchParams])

    const activeLanguageData = languagesData?.find(item => item.code === chosenLang);

    if(!languagesData) { return null; }

    return (
        <StyledNavBarLanguage ref={insideRef} $inNavBar={inNavBar} $inHamburger={inHamburger} $active={showLanguages}>
            <SelectButton onClick={() => setShowLanguages(!showLanguages)} $active={showLanguages} $inHamburger={inHamburger} $onWhite={shouldShowOnWhite}>
                {(activeLanguageData && chosenLang !== 'EN')
                    ? <Flag src={activeLanguageData?.image} $selected $onWhite={shouldShowOnWhite} />
                    : <StyledGlobe $onWhite={shouldShowOnWhite} />
                }

                {inHamburger && (
                    <HamburgerText>
                        {(activeLanguageData && chosenLang !== 'EN') ? activeLanguageData?.language : t('navbar.translate')}
                    </HamburgerText>
                )}

                <StyledChevron $active={showLanguages} $inHamburger={inHamburger} $onWhite={shouldShowOnWhite} />
            </SelectButton>

            <Transition nodeRef={nodeRef} in={showLanguages} timeout={200} unmountOnExit>
                {state => (
                    <Dropdown ref={nodeRef} className={state} $inHamburger={inHamburger}>
                        {languagesData.map(item => (
                            <DropdownItem key={item.code} onClick={() => chooseLanguage(item.code)} $active={(item.code === chosenLang) || (!activeLanguageData && item.code === "EN")}>
                                <Flag src={item.image} alt={item.language} />
                                <Text $small>{item.language}</Text>
                            </DropdownItem>
                        ))}
                    </Dropdown>
                )}
            </Transition>
        </StyledNavBarLanguage>
    );
}