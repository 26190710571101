import styled from 'styled-components';
import Email from "../../assets/icons/email.svg";
import Name from "../../assets/icons/name.svg";
import {Container, Section} from '../../components/Utils/Utils';
import {Heading2} from "../../components/Heading/Heading";
import {HTMLText, Text} from "../../components/Text/Text";
import {SubmitButton} from "../../components/Button/Button";
import {Link} from "react-router-dom";
import React, {useState} from "react";
import {GoogleReCaptchaProvider, useGoogleReCaptcha} from "react-google-recaptcha-v3";
import {Spinner} from '../../components/Spinner/Spinner';
import {parseItalic} from '../../helpers/parseItalic';
import {useCurrentLangParam} from '../../hooks/useCurrentLangParam';
import {Trans, useTranslation} from 'react-i18next';
import * as S from './Form.elements';

const Flex = styled.div`
    display: flex;
    gap: 80px;

    @media screen and (max-width: 1024px) {
        flex-direction: column;
        gap: 40px;
    }
`;

const TextBox = styled.div`
    flex: 1;
    ${Heading2} { margin-bottom: 10px; }
`;

const StyledLink = styled(Link)`
    color: inherit;
    text-decoration: underline;
    
    &:hover {
        color: var(--color-dark-blue);
    }
`;

const Overlay = styled.div`
    background: var(--color-background);
    opacity: .7;
    position: absolute;
    inset: 0;
`;

const SpinnerWrapper = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

const StyledSpinner = styled(Spinner)`
    background: var(--color-background);
    box-shadow: 0 0 0 11px var(--color-background), 0 0 10px 4px var(--color-black);
`;

export const ContactForm = ({ data }) => {
    if(!data?.content) { return null; }

    return (
        <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITEKEY}>
            <ContactFormComponent data={data}/>
        </GoogleReCaptchaProvider>
    );
}

const ContactFormComponent = ({ data }) => {
    const { t } = useTranslation();
    const {currentLang} = useCurrentLangParam();
    const [status, setStatus] = useState('idle');
    const [error, setError] = useState(false);
    const { executeRecaptcha } = useGoogleReCaptcha();

    async function handleSubmit(e) {
        // Prevent the browser from reloading the page
        e.preventDefault();

        if (!executeRecaptcha) {
            return;
        }

        const token = await executeRecaptcha('submit');

        // Read the form data
        const form = e.target;

        const body = JSON.stringify({
            "title": form.title.value,
            "email": form.email.value,
            "subject": form.subject.value,
            "message": form.message.value,
            "type": data?.content?.type,
            "token": token,
        });

        setStatus('submitting');

        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/v1/contact`, {method: form.method, body: body});
            if (!response.ok) {
                throw new Error();
            }
            setStatus('success');
        } catch (err) {
            setStatus('idle');
            setError(true);
        }
    }

    return (
        <Section id={data.hashSlug ?? undefined} $scroll={Boolean(data.hashSlug)} $backgroundColor={data.backgroundColor} $paddingTop $paddingBottom={data.isLast || (data.backgroundColor === 'lightBlue' || data.backgroundColor === 'darkBlue')} $marginTop={!data.removeSpacing && (data.backgroundColor === 'lightBlue' || data.backgroundColor === 'darkBlue')}>
            <Container>
                <Flex>
                    <TextBox>
                        <Heading2 $white={data.backgroundColor === 'darkBlue'}>{parseItalic(data.content.title)}</Heading2>
                        <HTMLText dangerouslySetInnerHTML={{__html: data.content.text}} $white={data.backgroundColor === 'darkBlue'} />
                    </TextBox>

                    <S.Form method="post" onSubmit={handleSubmit} $backgroundColor={data.backgroundColor}>
                        {error
                            ? <TextBox>
                                <Heading2>{t('blocks.contact_error_title')}</Heading2>
                                <Text>{t('blocks.contact_error_text')}</Text>
                            </TextBox>
                            : <>
                                {status === 'success' ? (
                                    <TextBox>
                                        <Heading2>{t('blocks.contact_success_title')}</Heading2>
                                        <Text>{t('blocks.contact_success_text')}</Text>
                                    </TextBox>
                                ) : (
                                    <>
                                        <S.BasicField>
                                            <S.LabelBox>
                                                <S.Label htmlFor="title">{t('blocks.contact_name')}</S.Label>
                                                <S.Required $small>{t('blocks.contact_required')}</S.Required>
                                            </S.LabelBox>
                                            <S.Input $icon={Name} as="input" type="text" id="title" name="title" placeholder={t('blocks.contact_name')} required/>
                                        </S.BasicField>

                                        <S.BasicField>
                                            <S.LabelBox>
                                                <S.Label htmlFor="email">{t('blocks.contact_email')}</S.Label>
                                                <S.Required $small>{t('blocks.contact_required')}</S.Required>
                                            </S.LabelBox>
                                            <S.Input $icon={Email} as="input" type="email" id="email" name="email" placeholder={t('blocks.contact_email')} required/>
                                        </S.BasicField>

                                        <S.BasicField>
                                            <S.LabelBox>
                                                <S.Label htmlFor="subject">{t('blocks.contact_subject')}</S.Label>
                                                <S.Required $small>{t('blocks.contact_required')}</S.Required>
                                            </S.LabelBox>
                                            <S.Input as="input" type="text" id="subject" name="subject" placeholder={t('blocks.contact_subject')} required/>
                                        </S.BasicField>

                                        <S.BasicField>
                                            <S.LabelBox>
                                                <S.Label as="label" htmlFor="message">{t('blocks.contact_message')}</S.Label>
                                                <S.Required $small>{t('blocks.contact_required')}</S.Required>
                                            </S.LabelBox>
                                            <S.Input $textarea as="textarea" rows="7" id="message" name="message" placeholder={t('blocks.contact_message')} required/>
                                        </S.BasicField>

                                        <S.CheckboxField>
                                            <S.CheckboxInput type="checkbox" id="checkbox" name="checkbox" required/>
                                            <S.CheckboxLabel htmlFor="checkbox"><Trans i18nKey="blocks.contact_agree">I agree to the <StyledLink to={data.content.termsAndConditions + currentLang} target="_blank" rel="noreferrer">terms and conditions</StyledLink> and the <StyledLink to={data.content.privacyPolicy + currentLang} target="_blank" rel="noreferrer">privacy policy</StyledLink>.</Trans></S.CheckboxLabel>
                                        </S.CheckboxField>

                                        <S.SubmitBox>
                                            <SubmitButton value={t('blocks.contact_submit')} disabled={status === 'submitting'} />
                                        </S.SubmitBox>
                                    </>
                                )}
                            </>
                        }

                        {status === 'submitting' &&
                            <>
                                <Overlay/>
                                <SpinnerWrapper>
                                    <StyledSpinner />
                                </SpinnerWrapper>
                            </>
                        }
                    </S.Form>
                </Flex>
            </Container>
        </Section>
    )
}