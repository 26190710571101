import styled from 'styled-components';
import {Heading1, PageTitle} from '../../components/Heading/Heading';
import {Text} from '../../components/Text/Text';
import {Button} from '../../components/Button/Button';
import {ConditionalWrap, Container} from '../../components/Utils/Utils';
import {parseItalic} from '../../helpers/parseItalic';

const StyledHeaderBanner = styled.section`
    background-color: var(--color-light-blue-40);
    ${props => props.$backgroundImage && `background-image: linear-gradient(rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), url(${props.$backgroundImage});`};
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
`;

const Inner = styled.div`
    padding-top: ${props => props.$type === 'big' ? '180px' : '160px'};
    padding-bottom: ${props => props.$type === 'big' ? '160px' : '130px'};

    @media screen and (max-width: 768px) {
        padding-top: ${props => props.$type === 'big' ? '160px' : '130px'};
        padding-bottom: ${props => props.$type === 'big' ? '140px' : '90px'};
    }

    @media screen and (max-width: 475px) {
        padding-top: ${props => props.$type === 'big' ? '120px' : '110px'};
        padding-bottom: ${props => props.$type === 'big' ? '100px' : '60px'};
    }
`;

const StyledPageTitle = styled(PageTitle)`
    max-width: 640px;
    margin-bottom: 10px;
`;

const StyledHeading1 = styled(Heading1)`
    max-width: 740px;
`;

const StyledText = styled(Text)`
    max-width: 400px;
    margin-bottom: 40px;
    
    @media screen and (max-width: 475px) {
        margin-bottom: 30px;
    }
`;

const Buttons = styled.div`
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
`;

const HeaderBanner = ({data}) => {
    if(!data?.content) { return null; }

    let isBigType = data.content.type === 'big';

    return (
        <StyledHeaderBanner id={data.hashSlug ?? undefined} $backgroundImage={data.content.image}>
            <Container>
                <Inner $type={data.content.type}>
                    {isBigType ? <StyledPageTitle $white>{parseItalic(data.content.title)}</StyledPageTitle> : <StyledHeading1 $white>{parseItalic(data.content.title)}</StyledHeading1>}
                    {(isBigType && data.content.subtitle) && <StyledText $large $white>{data.content.subtitle}</StyledText>}
                    {(isBigType && (data.content.firstLink || data.content.secondLink)) &&
                        <ConditionalWrap condition={data.content.firstLink && data.content.secondLink} wrap={children => <Buttons>{children}</Buttons>}>
                            {data.content.firstLink && <Button to={data.content.firstLink} type={data.content.firstLinkType} $variant="yellow">{data.content.firstLinkLabel}</Button>}
                            {data.content.secondLink && <Button to={data.content.secondLink} type={data.content.secondLinkType} $variant="transparent">{data.content.secondLinkLabel}</Button>}
                        </ConditionalWrap>
                    }
                </Inner>
            </Container>
        </StyledHeaderBanner>
    );
}

export default HeaderBanner;