import {ConditionalWrap, Container, Section} from '../../components/Utils/Utils';
import {BlockHeading2} from '../../components/Heading/Heading';
import {BlockText, Text} from '../../components/Text/Text';
import styled from 'styled-components';
import {useRef} from 'react';
import {ReactComponent as ExternalLink} from '../../assets/icons/external-link.svg';
// import {ReactComponent as ArrowLeft} from '../../assets/icons/arrow-left.svg';
// import {ReactComponent as ArrowRight} from '../../assets/icons/arrow-right.svg';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from "swiper";
import 'swiper/css';
import {parseItalic} from '../../helpers/parseItalic';
import {CustomLink} from '../../components/CustomLink/CustomLink';

const SwiperWrapper = styled.div`
    ${({$padding}) => $padding && 'padding-top: 70px;'};
    position: relative;

    .swiper-wrapper {
        transition-timing-function: linear;
    }

    .swiper-slide {
        max-width: 240px;
        width: 100%;
    }

    @media screen and (max-width: 768px) {
        ${({$padding}) => $padding && 'padding-top: 50px;'};
        
        .swiper-slide {
            max-width: 175px;
        }
    }

    @media screen and (max-width: 475px) {
        ${({$padding}) => $padding && 'padding-top: 30px;'};
        
        .swiper-slide {
            max-width: 145px;
        }
    }
`;

const StyledLink = styled(CustomLink)`
    color: inherit;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    
    &:hover ${Text} {
        text-decoration: underline;
    }
`;

const StyledPartner = styled(StyledLink).attrs({
    as: 'div'
})`
    &:hover ${Text} {
        text-decoration: none;
    }
`;

const StyledExternalLink = styled(ExternalLink)`
    width: 18px;
    height: 18px;
    vertical-align: text-top;
    transition: .1s transform ease;
    
    path { 
        stroke: ${({$white}) => $white ? 'var(--color-white)' : 'var(--color-body-text)'};
        stroke-width: 1.5;
    }

    ${StyledLink}:hover & {
        transform: translate(2px, -2px);
    }
`;

const Image = styled.img`
    display: block;
    max-width: 150px;
    width: 100%;
    height: 100px;
    object-fit: contain;
    margin-bottom: 20px;

    @media screen and (max-width: 768px) {
        max-width: 130px;
        height: 90px;
    }
    
    @media screen and (max-width: 475px) {
        max-width: 110px;
        height: 80px;
    }
`;

// const Button = styled.button`
//     background-color: var(--color-yellow);
//     width: 30px;
//     height: 30px;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     border-radius: 50%;
//     border: none;
//     outline: none;
//     cursor: pointer;
//     pointer-events: auto;
//
//     svg {
//         width: 18px;
//         height: 18px;
//
//         path { fill: var(--color-dark-text); }
//     }
//
//     &:hover {
//         background-color: var(--color-light-blue);
//     }
// `;

const ButtonWrapper = styled.div`
    height: 100%;
    position: absolute;
    top: 0;
    ${({$type}) => $type === "previous" ? 'left: 0;' : 'right: 0;'};
    background: linear-gradient(
        ${({$type}) => $type === "previous" ? '-90deg' : '90deg'}, 
        ${({$backgroundColor}) => $backgroundColor === 'lightBlue' ? 'rgba(235,243,244,0) 0%, rgba(235,243,244,1) 90%' : $backgroundColor === 'darkBlue' ? 'rgba(27,73,101,0) 0%, rgba(27,73,101,1) 90%' : 'rgba(254,254,254,0) 0%, rgba(254,254,254,1) 90%'}
    );
    
    justify-content: ${({$type}) => $type === "previous" ? 'flex-start' : 'flex-end'};
    z-index: 1;
    display: flex;
    align-items: center;
    width: 10%;
    pointer-events: none;
`;

const Grid = styled.div`
    ${({$padding}) => $padding && 'padding-top: 70px;'};
    display: grid;
    grid-template-columns: repeat(${({$columns}) => $columns <= 5 ? $columns : 5}, 1fr);
    gap: 30px;
    
    @media screen and (max-width: 1024px) {
        grid-template-columns: repeat(${({$columns}) => $columns <= 4 ? $columns : 4}, 1fr);
    }

    @media screen and (max-width: 768px) {
        ${({$padding}) => $padding && 'padding-top: 50px;'};
        grid-template-columns: repeat(${({$columns}) => $columns <= 3 ? $columns : 3}, 1fr);
    }

    @media screen and (max-width: 475px) {
        ${({$padding}) => $padding && 'padding-top: 30px;'};
        grid-template-columns: repeat(${({$columns}) => $columns <= 2 ? $columns : 2}, 1fr);
    }

    @media screen and (max-width: 320px) {
        grid-template-columns: repeat(${({$columns}) => $columns <= 1 ? $columns : 1}, 1fr);
    }
`;

export const Partners = ({data, ...props}) => {
    const swiperElRef = useRef(null);

    // const handlePrev = useCallback(() => {
    //     if (!swiperElRef?.current) return;
    //
    //     swiperElRef.current.swiper.slidePrev();
    // }, []);
    //
    // const handleNext = useCallback(() => {
    //     if (!swiperElRef?.current) return;
    //
    //     swiperElRef.current.swiper.slideNext();
    // }, []);

    if(!data?.content || data?.content?.partners?.length === 0) { return null; }

    let isSwiper = data?.content?.partners?.length >= 6;

    return (
        <Section id={data.hashSlug ?? undefined} $scroll={Boolean(data.hashSlug)} $backgroundColor={data.backgroundColor} $paddingTop $paddingBottom={data.isLast || data.backgroundColor !== 'white'} $marginTop={!data.removeSpacing && data.backgroundColor !== 'white'} {...props}>
            <Container>
                {data.title && <BlockHeading2 $white={data.backgroundColor === 'darkBlue'}>{parseItalic(data.title)}</BlockHeading2>}
                {data.subtitle && <BlockText $white={data.backgroundColor === 'darkBlue'} $large>{data.subtitle}</BlockText>}

                {isSwiper ? (
                    <SwiperWrapper $padding={!!data.title || !!data.subtitle}>
                        <Swiper
                            ref={swiperElRef}
                            spaceBetween={10}
                            loop={true}
                            // slidesPerGroup={1}
                            // slidesPerGroupAuto={true}
                            centeredSlides={true}
                            slidesPerView={"auto"}
                            breakpoints={{
                                476: {
                                    spaceBetween: 20,
                                },
                                769: {
                                    spaceBetween: 30,
                                },
                            }}
                            threshold={10}
                            speed={2500}
                            autoplay={{
                                delay: 1,
                                disableOnInteraction: false,
                            }}
                            modules={[Autoplay]}
                        >
                            {data?.content?.partners?.map(item => (
                                <SwiperSlide key={item.id}>
                                    <ConditionalWrap
                                        condition={!!item.link}
                                        wrap={children => (
                                            <StyledLink to={item.link} type={item.linkType}>
                                                {children}
                                            </StyledLink>
                                        )}
                                        fallback={children => <StyledPartner>{children}</StyledPartner>}
                                    >
                                        {item.image && <Image src={item.image} alt={item.title} />}
                                        {item.title && <Text $small $white={data.backgroundColor === 'darkBlue'}>{parseItalic(item.title)} {item.link && <StyledExternalLink $white={data.backgroundColor === 'darkBlue'} />}</Text>}
                                    </ConditionalWrap>
                                </SwiperSlide>
                            ))}
                        </Swiper>

                        <ButtonWrapper $type="previous" $backgroundColor={data.backgroundColor}>
                            {/*<Button onClick={handlePrev}><ArrowLeft /></Button>*/}
                        </ButtonWrapper>
                        <ButtonWrapper $type="next" $backgroundColor={data.backgroundColor}>
                            {/*<Button onClick={handleNext}><ArrowRight /></Button>*/}
                        </ButtonWrapper>
                    </SwiperWrapper>
                ) : (
                    <Grid $columns={data?.content?.partners?.length} $padding={!!data.title || !!data.subtitle}>
                        {data?.content?.partners?.map(item => (
                            <ConditionalWrap
                                key={item.id}
                                condition={!!item.link}
                                wrap={children => (
                                    <StyledLink to={item.link} type={item.linkType}>
                                        {children}
                                    </StyledLink>
                                )}
                                fallback={children => <StyledPartner>{children}</StyledPartner>}
                            >
                                {item.image && <Image src={item.image} alt={item.title} />}
                                {item.title && <Text $small $white={data.backgroundColor === 'darkBlue'}>{parseItalic(item.title)} {item.link && <StyledExternalLink $white={data.backgroundColor === 'darkBlue'} />}</Text>}
                            </ConditionalWrap>
                        ))}
                    </Grid>
                )}
            </Container>
        </Section>
    );
}