import styled from 'styled-components';
import {ConditionalWrap, Container, Section} from '../../components/Utils/Utils';
import {Heading2} from '../../components/Heading/Heading';
import {HTMLText, Text} from '../../components/Text/Text';
import {Button} from '../../components/Button/Button';
import {HorizontalIframe, HorizontalInner, HorizontalOuter, SquareIframe, SquareImage, SquareInner, SquareOuter} from '../../components/AspectRatio/AspectRatio';
import {parseItalic} from '../../helpers/parseItalic';
import {useCookieBannerContext} from '../../contexts/CookieBannerContext';
import {ShouldAllowCookies} from '../../components/CookieBanner/ShouldAllowCookies';
import {useIsFullscreenEnabled} from '../../hooks/useIsFullscreenEnabled';
import {Fullscreen} from '../../components/Fullscreen/Fullscreen';
import {useEffect, useRef, useState} from 'react';

const Flex = styled.div`
    display: flex;
    gap: 80px;
    align-items: ${({$alignment}) => $alignment === "start" ? 'flex-start' : $alignment === "end" ? 'flex-end' : 'center'};
    flex-direction: ${({$direction}) => $direction === 'textLeft' ? 'row-reverse' : 'row'};

    @media screen and (max-width: 1024px) {
        gap: 40px;
    }

    @media screen and (max-width: 768px) {
        flex-direction: column-reverse;
    }
    
    ${({$isBig}) => $isBig && `
        @media screen and (max-width: 768px) {
            padding-top: 40px;
            padding-bottom: 40px;
        }
        
        @media screen and (max-width: 475px) {
            padding-top: 20px;
            padding-bottom: 20px;
        }
    `}
`;

const ContentBox = styled.article`
    flex: 1 1 ${({$isBig}) => $isBig ? '52%' : '0'};
    width: 100%;
    position: relative;
`;

const TextBox = styled.article`
    flex: 1 1 ${({$isBig}) => $isBig ? '48%' : '0'};
    ${({$direction}) => $direction === 'textLeft' && 'padding: 60px 0 60px max(calc((min(2048px, 100vw) - 1280px) / 2), 20px);'};
    ${({$direction}) => $direction === 'textRight' && 'padding: 60px max(calc((min(2048px, 100vw) - 1280px) / 2), 20px) 60px 0;'};
    ${({$captionHeight}) => $captionHeight && `padding-bottom: ${$captionHeight}px`};
    
    @media screen and (max-width: 768px) {
        padding: 0;
    }
`;

export const Image = styled.img`
    width: 100%;
    max-width: ${({$variant}) => $variant === '75' ? '100%' : $variant === '50' ? '400px' : $variant === '25' ? '200px' : '100%'};
    ${({$variant}) => ($variant === '50' || $variant === '25') && 'margin: 0 auto;'};
    height: auto;
    object-fit: cover;
    display: block;
    border-radius: ${({$isFullWidth}) => $isFullWidth ? '0' : '4px'};
`;

const Buttons = styled.div`
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    margin-top: 40px;

    @media screen and (max-width: 768px) {
        margin-top: 20px;
    }
`;

const Caption = styled(Text)`
    color: ${({$onYellow}) => $onYellow ? 'var(--color-dark-text)' : 'var(--color-caption-text)'};
    margin: ${({$variant}) => ($variant === '50' || $variant === '25') ? '10px auto' : '10px 0'};
    ${({$direction}) => $direction === 'textRight' && 'padding-left: 10px;'};
    max-width: ${({$variant}) => $variant === '75' ? '100%' : $variant === '50' ? '400px' : $variant === '25' ? '200px' : '100%'};
    
    @media screen and (max-width: 768px) {
        padding: 0;
    }
`;

export const StyledHorizontalInner = styled(HorizontalInner)`
    iframe {
        width: 100%;
        height: 100%;
        border-radius: 4px;
    }

    // Sketchfab adds a div around the iframe
    & > div {
        height: 100%;
    }
`;

const StyledSquareInner = styled(SquareInner)`
    iframe {
        width: 100%;
        height: 100%;
    }

    // Sketchfab adds a div around the iframe
    & > div {
        height: 100%;
    }
`;

export const ContentItem = ({data, ...props}) => {
    const {allowedToLoadEmbed} = useCookieBannerContext();
    const {isFullscreenEnabled} = useIsFullscreenEnabled();
    const captionRef = useRef(null);
    const [captionHeight, setCaptionHeight] = useState(null);

    let isBigVariant = data?.content?.variant === '100';
    let direction = data?.content?.direction;
    let alignment = data?.content?.alignment;

    useEffect(() => {
        if(captionRef.current && alignment === "end") setCaptionHeight(captionRef.current.clientHeight + 20);

        return () => setCaptionHeight(null);
    }, [captionRef, alignment]);

    if(!data?.content) { return null; }

    return (
        <Section id={data.hashSlug ?? undefined} $scroll={Boolean(data.hashSlug)} $backgroundColor={data.content.backgroundColor} $marginTop={!data.removeSpacing && isBigVariant} $paddingTop={!isBigVariant} $paddingBottom={(!isBigVariant && data.content.backgroundColor !== 'white') || (data.content.backgroundColor === 'white' && data.isLast)} {...props}>
            <Container $big={isBigVariant}>
                <Flex $direction={direction} $alignment={alignment} $isBig={isBigVariant}>
                    <ContentBox $isBig={isBigVariant}>
                        {data.content.type === 'image' && (
                            isBigVariant ? (
                                <SquareImage src={data.content.image} alt={data.content.source || data.content.title} />
                            ) : (
                                <Image src={data.content.image} alt={data.content.source || data.content.title} $variant={data.content.variant} />
                            )
                        )}

                        {data.content.type === 'embedcode' && (
                            isBigVariant ? (
                                <SquareOuter>
                                    {allowedToLoadEmbed
                                        ? (
                                            <ConditionalWrap condition={isFullscreenEnabled} wrap={children => <Fullscreen>{children}</Fullscreen>}>
                                                <StyledSquareInner dangerouslySetInnerHTML={{__html: data.content.embedCode}} />
                                            </ConditionalWrap>
                                        )
                                        : <ShouldAllowCookies />
                                    }
                                </SquareOuter>
                            ) : (
                                <HorizontalOuter $variant={data.content.variant} >
                                    {allowedToLoadEmbed
                                        ? (
                                            <ConditionalWrap condition={isFullscreenEnabled} wrap={children => <Fullscreen>{children}</Fullscreen>}>
                                                <StyledHorizontalInner dangerouslySetInnerHTML={{__html: data.content.embedCode}} />
                                            </ConditionalWrap>
                                        )
                                        : <ShouldAllowCookies />
                                    }
                                </HorizontalOuter>
                            )
                        )}

                        {data.content.type === 'embedurl' && (
                            isBigVariant ? (
                                allowedToLoadEmbed ? (
                                    <SquareIframe src={data.content.embedURL} title={data.content.title} />
                                ) : (
                                 <SquareOuter>
                                     <ShouldAllowCookies />
                                 </SquareOuter>
                                )
                            ) : (
                                allowedToLoadEmbed ? (
                                    <HorizontalIframe src={data.content.embedURL} title={data.content.title} $variant={data.content.variant} />
                                ) : (
                                    <HorizontalOuter $variant={data.content.variant}>
                                        <ShouldAllowCookies />
                                    </HorizontalOuter>
                                )
                            )
                        )}

                        {(allowedToLoadEmbed && data.content.source) && <Caption ref={captionRef} $small $direction={isBigVariant ? direction : undefined} $onYellow={data.content.backgroundColor === 'yellow'} $variant={data.content.variant}>{data.content.source}</Caption>}
                    </ContentBox>
                    <TextBox $isBig={isBigVariant} $direction={isBigVariant ? direction : undefined} $captionHeight={captionHeight}>
                        <Heading2 $white={data.content.backgroundColor === 'darkBlue'} $dark={data.content.backgroundColor === 'yellow'}>{parseItalic(data.content.title)}</Heading2>
                        <HTMLText dangerouslySetInnerHTML={{__html: data.content.text}} $white={data.content.backgroundColor === 'darkBlue'} $dark={data.content.backgroundColor === 'yellow'} />
                        {(data.content.firstLink || data.content.secondLink) &&
                            <Buttons>
                                {data.content.firstLink && <Button to={data.content.firstLink} type={data.content.firstLinkType} $variant={data.content.backgroundColor === 'yellow' ? 'blue' : 'yellow'}>{data.content.firstLinkLabel}</Button>}
                                {data.content.secondLink && <Button to={data.content.secondLink} type={data.content.secondLinkType} $variant={data.content.backgroundColor === 'darkBlue' ? 'transparent' : 'outline'}>{data.content.secondLinkLabel}</Button>}
                            </Buttons>
                        }
                    </TextBox>
                </Flex>
            </Container>
        </Section>
    );
}