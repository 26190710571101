import styled, {css} from 'styled-components';
import {ReactComponent as ArrowRight} from '../../assets/icons/arrow-right.svg';
import {ReactComponent as SuggestionIcon} from '../../assets/icons/suggestion.svg';
import {CustomLink} from '../CustomLink/CustomLink';

const StyledArrowRight = styled(ArrowRight)`
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    
    ${props => props.$variant === 'alt' && `
        width: 30px;
        height: 30px;
        background-color: var(--color-yellow);
        border-radius: 50%;
        padding: 4px; 
    `};

    @media screen and (max-width: 768px) {
        width: 20px;
        height: 20px;

        ${props => props.$variant === 'alt' && `
            width: 30px;
            height: 30px;
        `}
    }
`;

const StyledSuggestionIcon = styled(SuggestionIcon)`
    width: 24px;
    height: 24px;
    flex-shrink: 0;

    @media screen and (max-width: 768px) {
        width: 20px;
        height: 20px;
    }
`;

const buttonVariants = (variant = 'yellow') => ({
    yellow: css`
        background-color: var(--color-yellow);
        color: var(--color-dark-text);
        border: 1px solid var(--color-yellow);
        path { fill: var(--color-dark-text); }

        &:hover {
            background-color: var(--color-yellow-80);
            border: 1px solid var(--color-yellow-80);
        }
    `,
    blue: css`
        background-color: var(--color-dark-blue);
        color: var(--color-white);
        border: 1px solid var(--color-dark-blue);
        path { fill: var(--color-white); }

        &:hover {
            background-color: var(--color-dark-blue-80);
            border: 1px solid var(--color-dark-blue-80);
        }
    `,
    transparent: css`
        background-color: transparent;
        color: var(--color-white);
        border: 1px solid rgba(230, 230, 230, .4);
        path { fill: var(--color-white); }

        &:hover {
            background-color: rgba(255, 255, 255, .1);
        }
    `,
    outline: css`
        background-color: transparent;
        color: var(--color-dark-blue);
        border: 1px solid var(--color-dark-blue);
        path { fill: var(--color-dark-blue); }

        &:hover {
            color: var(--color-white);
            background-color: var(--color-dark-blue);
            path { fill: var(--color-white); }
        }
    `,
    alt: css`
        background-color: transparent;
        color: ${({$white}) => $white ? 'var(--color-white)' : 'var(--color-dark-blue)'};
        path { fill: var(--color-dark-blue); }
        
        &:hover {
            ${StyledArrowRight} {
                background-color: var(--color-light-blue);
            }
        }
    `,
}[variant]);

export const buttonStyles = css`
    border-radius: 5px;
    ${({$variant}) => buttonVariants($variant)};
    display: inline-flex;
    gap: 10px;
    align-items: center;
    cursor: pointer;
    padding: ${({$variant}) => $variant === "alt" ? '0' : '10px 20px'};
    font-weight: var(--fw-semi-bold);
    font-size: ${props => props.$large ? 'var(--fs-button-large)' : props.$small ? 'var(--fs-button-small)' : 'var(--fs-button-default)'};
    text-decoration: none;
    line-height: 1.5;
    max-width: fit-content;

    @media screen and (max-width: 768px) {
        font-size: ${props => props.$large ? 'var(--fs-mobile-button-large)' : props.$small ? 'var(--fs-mobile-button-small)' : 'var(--fs-mobile-button-default)'};
        padding: ${({$variant}) => $variant === "alt" ? '0' : '7px 12px'};
    }
`

const StyledButton = styled(CustomLink)`
    ${buttonStyles};
`;

const StyledSubmit = styled.input`
    ${buttonStyles};
    
    &:disabled {
        opacity: .75;
        pointer-events: none;
    }
`;

export const Button = ({to = "/", type = "internal", hideArrow = false, suggestion = false, children, ...props}) => {
    return (
        <StyledButton to={to} type={type} {...props}>
            {suggestion && <StyledSuggestionIcon />}
            {children}
            {!hideArrow && <StyledArrowRight {...props} />}
        </StyledButton>
    );
}

export const SubmitButton = ({value, ...props}) => {
    return (
        <StyledSubmit type="submit" value={value} {...props} />
    );
}

export const ActionButton = ({children, ...props}) => {
    return (
        <StyledButton as="button" {...props}>
            {children}
        </StyledButton>
    );
}