import {Container, Section} from '../../components/Utils/Utils';
import {Heading2} from '../../components/Heading/Heading';
import {HTMLText} from '../../components/Text/Text';
import {Button} from '../../components/Button/Button';
import styled from 'styled-components';
import {parseItalic} from '../../helpers/parseItalic';

const StyledContainer = styled(Container)`
    ${({$paddingForBoxShadow}) => $paddingForBoxShadow && 'padding-bottom: 8px;'};
    
    @media screen and (max-width: 475px) {
        padding: 0;
    }
`;

const Flex = styled.div`
    background-color: ${({$backgroundColor}) =>
        $backgroundColor === 'lightBlue' ? 'var(--color-light-blue-40)' :
        $backgroundColor === 'darkBlue' ? 'var(--color-dark-blue)' :
        $backgroundColor === 'yellow' ? 'var(--color-yellow)' : 'var(--color-white)'
    };
    padding: 40px;
    border-radius: 5px;
    display: flex;
    gap: 80px;
    
    ${({$backgroundColor}) => $backgroundColor === 'white' && 'box-shadow: var(--box-shadow);'};
    
    @media screen and (max-width: 768px) {
        padding: 30px;
        flex-direction: column;
        gap: 40px;
    }

    @media screen and (max-width: 475px) {
        padding: 20px;
        border-radius: 0;
        ${({$backgroundColor}) => $backgroundColor === 'white' && 'box-shadow: none;'};
    }
`;

const StyledHeading2 = styled(Heading2)`
    margin-bottom: 10px;
`;

const StyledText = styled(HTMLText)`
    max-width: 768px;
`;

const TextBox = styled.article`
    flex: 1 1 0;
    padding-bottom: ${({$hasButton}) => $hasButton ? '40px' : '10px'};

    @media screen and (max-width: 768px) {
        padding-bottom: 0;
    }
`;

const ButtonBox = styled.div`
    align-self: flex-end;
    flex-shrink: 0;
`;

export const Banner = ({data, ...props}) => {
    if(!data?.content) { return null; }

    return (
        <Section id={data.hashSlug ?? undefined} $scroll={Boolean(data.hashSlug)} $paddingTop $paddingBottom={data.isLast} {...props}>
            <StyledContainer $paddingForBoxShadow={data.content.backgroundColor === 'white'}>
                <Flex $backgroundColor={data.content.backgroundColor}>
                    <TextBox $hasButton={!!data.content.link}>
                        <StyledHeading2 $white={data.content.backgroundColor === 'darkBlue'} $dark={data.content.backgroundColor === 'yellow'}>{parseItalic(data.content.title)}</StyledHeading2>
                        <StyledText dangerouslySetInnerHTML={{__html: data.content.text}} $white={data.content.backgroundColor === 'darkBlue'} $dark={data.content.backgroundColor === 'yellow'} $noMarginTop />
                    </TextBox>
                    {data.content.link &&
                        <ButtonBox>
                            <Button to={data.content.link} type={data.content.linkType} $variant={data.content.backgroundColor === 'yellow' ? 'blue' : 'yellow'}>{data.content.linkLabel}</Button>
                        </ButtonBox>
                    }
                </Flex>
            </StyledContainer>
        </Section>
    );
}