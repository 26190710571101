import {sendCustomEvent} from "./base";

const KNOWLEDGE_CENTER_EVENT = "knowledge_center";
const KNOWLEDGE_CENTER_ITEM_CLICK_EVENT = "knowledge_center_item_click";

// KNOWLEDGE_CENTER_EVENT
export const enableFilterEvent = (category, label) => {
    sendCustomEvent(KNOWLEDGE_CENTER_EVENT, category, "Enable filter option", label);
}

export const disableFilterEvent = (category, label) => {
    sendCustomEvent(KNOWLEDGE_CENTER_EVENT, category, "Disable filter option", label);
}

export const searchFilterEvent = (label) => {
    sendCustomEvent(KNOWLEDGE_CENTER_EVENT, "Search", "Search in knowledge center", label);
}

// KNOWLEDGE_CENTER_ITEM_CLICK
const clickOnKnowledgeCenterItemCategories = ["external", "internal", "knowledge-center-detail"];

export const clickOnKnowledgeCenterItemEvent = (category, label) => {
    if(!clickOnKnowledgeCenterItemCategories.includes(category)) { return null; }

    sendCustomEvent(KNOWLEDGE_CENTER_ITEM_CLICK_EVENT, category, "Click on knowledge center item", label);
}