import styled from 'styled-components';
import {Label} from '../Label/Label';
import {ReactComponent as LockIcon} from '../../assets/icons/lock.svg';
import {ReactComponent as GlobeIcon} from '../../assets/icons/globe.svg';
import {Text} from '../Text/Text';

const StyledLabelsRow = styled.div`
    margin-top: ${({$isFeatured}) => $isFeatured ? '0' : '30px'};
    display: flex;
    justify-content: ${({$isFeatured}) => $isFeatured ? 'flex-start' : 'space-between'};
    ${({$gap}) => $gap && 'gap: 15px;'};
    flex-wrap: wrap-reverse;

    @media screen and (max-width: 475px) {
        flex-direction: column-reverse;
    }
`;

const LabelBox = styled.div`
    flex-shrink: 0;
`;

const MetaBox = styled.div`
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
`;

const StyledIcon = styled.div`
    svg {
        width: 18px;
        height: 18px;
        vertical-align: middle;

        path { fill: ${({$isFeatured}) => $isFeatured ? 'var(--color-dark-blue)' : 'var(--color-body-text)'}; }
    }
`;

const MetaLabel = styled.div`
    display: inline-flex;
    gap: 5px;
    align-items: center;
`;

export const LabelsRow = ({data, isFeatured}) => {
    if(!data.labelTitle && !data.paid && !data.language && !isFeatured) { return null; }

    return (
        <StyledLabelsRow $gap={data.labelTitle && (!!data.paid || data.language)} $isFeatured={isFeatured}>
            <LabelBox>
                {data.labelTitle && <Label icon={data.labelIcon} $isFeatured={isFeatured}>{data.labelTitle}</Label>}
            </LabelBox>
            <MetaBox>
                {!!data.paid &&
                    <MetaLabel>
                        <StyledIcon $isFeatured={isFeatured}><LockIcon /></StyledIcon>
                    </MetaLabel>
                }
                {data.language &&
                    <MetaLabel>
                        <StyledIcon $isFeatured={isFeatured}><GlobeIcon /></StyledIcon>
                        <Text $small $blue={isFeatured}>{data.language}</Text>
                    </MetaLabel>
                }
            </MetaBox>
        </StyledLabelsRow>
    );
}