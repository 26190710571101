import {ConditionalWrap, Container, Section} from '../../components/Utils/Utils';
import styled from 'styled-components';
import {BlockHeading2, Heading3, Heading5} from '../../components/Heading/Heading';
import {BlockText, HTMLText, Text} from '../../components/Text/Text';
import {Button} from '../../components/Button/Button';
import { SwiperSlide } from 'swiper/react';
import 'swiper/css';
import "swiper/css/navigation";
import {InfoColumnsSwiper} from './InfoColumnsSwiper';
import {parseItalic} from '../../helpers/parseItalic';
import {ReactComponent as GlobeIcon} from '../../assets/icons/globe.svg';
import {CustomLink} from '../../components/CustomLink/CustomLink';
import {BackButton} from '../../components/BackButton/BackButton';

const TitleContainer = styled(Container)`
    ${({$padding}) => $padding && 'padding-bottom: 40px;'};
`;

const StyledContainer = styled(Container)`
    @media screen and (max-width: 1024px) {
        ${({$isSwiper}) => $isSwiper && 'padding: 0;'};
    }
`;

const Grid = styled.div`
    display: grid;
    gap: 30px;
    padding-bottom: 8px;
    
    ${({$isHorizontal, $columns}) => $isHorizontal ? `
        grid-template-columns: repeat(${$columns <= 2 ? $columns : 2}, 1fr);
        
        @media screen and (max-width: 1024px) {
            grid-template-columns: 1fr;
        }
    ` : `
        grid-template-columns: repeat(${$columns <= 3 ? $columns : 3}, 1fr);
        
        @media screen and (max-width: 1024px) {
            grid-template-columns: repeat(${$columns <= 2 ? $columns : 2}, 1fr);
        }
    
        @media screen and (max-width: 560px) {
            grid-template-columns: 1fr;
        }
    `};
    
    @media screen and (max-width: 1024px) {
        gap: 20px;
    }
`;

const Item = styled.article`
    ${({$isTransparent}) => !$isTransparent && 'box-shadow: var(--box-shadow);'};
    border-radius: 4px;
    overflow: hidden;
    display: flex;
    flex-direction: ${({$isHorizontal}) => $isHorizontal ? 'row' : 'column'};
    height: 100%;
    
    @media screen and (max-width: 475px) {
        flex-direction: column;
    }
`;

const ImageBox = styled.div`
    ${({$isHorizontal}) => $isHorizontal && 'flex: 1 0 208px;'};
    
    @media screen and (max-width: 475px) {
        flex: unset;
    }
`;

const ContentBox = styled.div`
    padding: 20px;
    background-color: var(--color-white);
    flex: 1 1 100%;
    display: flex;
    flex-direction: column;
    
    ${({$isTransparent}) => $isTransparent && `
        background-color: transparent;
        text-align: center;
        flex: unset;
        
        @media screen and (max-width: 1440px) {
            padding: 20px 0 0 0;
        }
    `}
`;

const StyledHeading3 = styled(Heading3)`
    margin-bottom: 5px;
`;

const StyledHeading5 = styled(Heading5)`
    margin-bottom: 5px;
`;

const Subtitle = styled(Text)`
    margin-bottom: 5px;
`;

const ButtonWrapper = styled.div`
    ${({$isTransparent}) => !$isTransparent && `
        margin-left: auto;
        text-align: right;
    `};
`;

const InfoColumnsFooter = styled.div`
    padding-top: 16px;
    margin-top: auto;
    display: flex;
    flex-direction: ${({$isTransparent}) => $isTransparent ? 'column' : 'row'};
    ${({$isTransparent}) => $isTransparent && `
        align-items: center;
        text-align: center;
    `};
    justify-content: space-between;
    flex-wrap: wrap;
    ${({$addGap}) => $addGap && `
        gap: 5px;
        row-gap: 16px;
    `};
`;

const ImageOuter = styled.div`
    position: relative;
    width: 100%;
    aspect-ratio: ${({$isHorizontal}) => $isHorizontal ? '1 / 1' : '16 / 9'};

    @supports not (aspect-ratio: 16 / 9) {
        overflow: hidden;
        padding-top: ${({$isHorizontal}) => $isHorizontal ? '100%' : '56.25%'};
    }
    
    @media screen and (max-width: 475px) {
        aspect-ratio: 16 / 9;

        @supports not (aspect-ratio: 16 / 9) {
            padding-top: 100%;
        }
    }
`;

const ImageInner = styled.div`
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    ${({$isTransparent}) => $isTransparent && 'border-radius: 4px;'};
    background-color: var(--color-background);

    @supports not (aspect-ratio: 16 / 9) {
        position: absolute;
        inset: 0;
    }
`;

const SourceLanguage = styled.div`
    display: inline-flex;
    gap: 5px;
`;

const StyledGlobe = styled(GlobeIcon)`
    width: 18px;
    height: 18px;
    flex-shrink: 0;
    margin-top: 2px;
    
    path { fill: ${({$white}) => $white ? 'var(--color-white)' : 'var(--color-body-text)'}; }
`;

const StyledCustomLink = styled(CustomLink)`
    text-decoration: none;
    color: inherit;
    
    &:hover {
        text-decoration: underline;
    }
`;

const BackButtonContainer = styled(Container)`
    padding-bottom: 30px;
    
    @media screen and (max-width: 1024px) {
        padding-bottom: 20px;
    }
`;

export const InfoColumns = ({data, ...props}) => {
    if(!data?.content || data?.content?.infoColumns.length === 0) { return null; }

    let isHorizontal = data?.content?.cardType === 'horizontal';
    let isVertical = data?.content?.cardType === 'vertical';
    let isTransparent = data?.content?.cardType === 'transparent';

    let isSwiper = data?.content?.slider === 1 && (isHorizontal ? data?.content?.infoColumns?.length >= 3 : (isVertical || isTransparent) ? data?.content?.infoColumns?.length >= 4 : false);

    ConditionalWrap.displayName = 'ConditionalWrapSwiperSlide'; // Swiper's direct child needs to include SwiperSlide in the name

    return (
        <Section id={data.hashSlug ?? undefined} $scroll={Boolean(data.hashSlug)} $backgroundColor={data.backgroundColor} $paddingTop $paddingBottom={data.isLast || (data.backgroundColor === 'lightBlue' || data.backgroundColor === 'darkBlue')} $marginTop={!data.removeSpacing && (data.backgroundColor === 'lightBlue' || data.backgroundColor === 'darkBlue')} {...props}>
            {data?.content?.showBackButton &&
                <BackButtonContainer>
                    <BackButton location="trainer-portal" $white={data.backgroundColor === 'darkBlue'} />
                </BackButtonContainer>
            }
            <TitleContainer $padding={!!data.title || !!data.subtitle}>
                {data.title && <BlockHeading2 $white={data.backgroundColor === 'darkBlue'}>{parseItalic(data.title)}</BlockHeading2>}
                {data.subtitle && <BlockText $white={data.backgroundColor === 'darkBlue'} $large>{data.subtitle}</BlockText>}
            </TitleContainer>
            <StyledContainer $isSwiper={isSwiper}>
                <ConditionalWrap
                    condition={isSwiper}
                    wrap={children => <InfoColumnsSwiper isHorizontal={isHorizontal}>{children}</InfoColumnsSwiper>}
                    fallback={children => <Grid $columns={data?.content?.infoColumns?.length} $isHorizontal={isHorizontal}>{children}</Grid>}
                >
                    {data?.content?.infoColumns?.map(item => {
                        const isLink = Boolean(item.link);

                        return (
                            <ConditionalWrap
                                condition={isSwiper}
                                wrap={children => <SwiperSlide key={item.id}>{children}</SwiperSlide>}
                                key={item.id}
                            >
                                <Item $isHorizontal={isHorizontal} $isTransparent={isTransparent}>
                                    {item.image &&
                                        <ConditionalWrap condition={isLink} wrap={children => <CustomLink to={item.link} type={item.linkType}>{children}</CustomLink>}>
                                            <ImageBox $isHorizontal={isHorizontal}>
                                                <ImageOuter $isHorizontal={isHorizontal}>
                                                    <ImageInner as="img" src={item.image} alt={item.title} $isTransparent={isTransparent} />
                                                </ImageOuter>
                                            </ImageBox>
                                        </ConditionalWrap>
                                    }
                                    <ContentBox $isTransparent={isTransparent}>
                                        {isTransparent ? (
                                            <>
                                                {item.title &&
                                                    <ConditionalWrap condition={isLink} wrap={children => <StyledCustomLink to={item.link} type={item.linkType}>{children}</StyledCustomLink>}>
                                                        <StyledHeading3 $white={data.backgroundColor === 'darkBlue'}>{parseItalic(item.title)}</StyledHeading3>
                                                    </ConditionalWrap>
                                                }
                                                {item.subtitle && <Subtitle $small $white={data.backgroundColor === 'darkBlue'}>{item.subtitle}</Subtitle>}
                                                {item.text && <HTMLText dangerouslySetInnerHTML={{__html: item.text}} $white={data.backgroundColor === 'darkBlue'} $noMarginTop />}
                                            </>
                                        ) : (
                                            <>
                                                {item.title &&
                                                    <ConditionalWrap condition={isLink} wrap={children => <StyledCustomLink to={item.link} type={item.linkType}>{children}</StyledCustomLink>}>
                                                        <StyledHeading5 $truncate $clamp={2}>{parseItalic(item.title)}</StyledHeading5>
                                                    </ConditionalWrap>
                                                }
                                                {item.subtitle && <Subtitle $small $truncate $clamp={3}>{item.subtitle}</Subtitle>}
                                                {item.text && <HTMLText dangerouslySetInnerHTML={{__html: item.text}} $noMarginTop />}
                                            </>
                                        )}
                                        {(isLink || item.language) &&
                                            <InfoColumnsFooter $isTransparent={isTransparent} $addGap={isLink && item.language}>
                                                <SourceLanguage>
                                                    {item.language &&
                                                        <>
                                                            <StyledGlobe $white={isTransparent && data.backgroundColor === 'darkBlue'} />
                                                            <Text $small $white={isTransparent && data.backgroundColor === 'darkBlue'}>{item.language}</Text>
                                                        </>
                                                    }
                                                </SourceLanguage>
                                                <ButtonWrapper $isTransparent={isTransparent}>
                                                    {isLink &&
                                                        <Button to={item.link} type={item.linkType} $variant="alt" $large $white={isTransparent && (data.backgroundColor === 'darkBlue')}>{item.linkLabel}</Button>
                                                    }
                                                </ButtonWrapper>
                                            </InfoColumnsFooter>
                                        }
                                    </ContentBox>
                                </Item>
                            </ConditionalWrap>
                        )}
                    )}
                </ConditionalWrap>
            </StyledContainer>
        </Section>
    );
}