import {Container, Section} from '../../components/Utils/Utils';
import {Text} from '../../components/Text/Text';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import {ReactComponent as ExternalLink} from '../../assets/icons/external-link.svg';
import {Heading5} from '../../components/Heading/Heading';
import {Banner} from '../Banner/Banner';
import {Trans, useTranslation} from 'react-i18next';

const StyledPFViewer = styled.div`
    @media screen and (max-width: 768px) {
        display: none;
    }
`;

const Outer = styled.div`
    border-radius: 4px;
    position: relative;
    width: 100%;
    aspect-ratio: 5 / 3;
    background-color: var(--color-background);
    ${({$isSafari}) => $isSafari && 'box-shadow: var(--box-shadow-pdf);'};

    @supports not (aspect-ratio: 5 / 3) {
        overflow: hidden;
        padding-top: 60%;
    }
`;

const Inner = styled.object`
    border-radius: 4px;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border: none;

    @supports not (aspect-ratio: 5 / 3) {
        position: absolute;
        inset: 0;
    }
    
    iframe {
        border: none;
        width: 100%;
        height: 100%;
    }
`;

const Absolute = styled(Link)`
    position: absolute;
    right: 10px;
    bottom: 10px;
    background-color: var(--color-dark-blue);
    padding: 6px;
    border-radius: 4px;
    opacity: 0;
    transition: opacity .2s ease;
    
    &:hover {
        background-color: var(--color-dark-blue-80);
    }
    
    ${Outer}:hover & {
        opacity: 1;
    }
`;

const StyledExternalLink = styled(ExternalLink)`
    width: 22px;
    height: 22px;
`;

const StyledLink = styled(Link)`
    color: var(--color-dark-blue);
    
    &:hover {
        color: var(--color-dark-blue-80);
    }
`;

const SafariInformation = styled.div`
    margin-bottom: 20px;
`;

const MobileBanner = styled.div`
    display: none;

    @media screen and (max-width: 768px) {
        display: block;
    }
`;

export const PDFViewer = ({data, ...props}) => {
    const { t } = useTranslation();

    if(!data?.content || !data?.content?.document) { return null; }

    const isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));

    const mobileNotice = {
        "type": "banner",
        "isLast": data.isLast,
        "hashSlug": data.hashSlug,
        "content": {
            "id": "pdf-viewer",
            "backgroundColor": "yellow",
            "title": data.content.title || "PDF",
            "text": t('blocks.pdfviewer_mobile_notice'),
            "linkLabel": t('blocks.pdfviewer_btn_mobile_notice'),
            "linkType": "external",
            "link": data.content.document,
        }
    }

    return (
        <>
            <StyledPFViewer>
                <Section id={data.hashSlug ?? undefined} $scroll={Boolean(data.hashSlug)} $paddingTop $paddingBottom={data.isLast} {...props}>
                    <Container>
                        {isSafari &&
                            <SafariInformation>
                                <Heading5>{data.content.title}</Heading5>
                                <Text>{t('blocks.pdfviewer_safari_notice')}</Text>
                            </SafariInformation>
                        }
                        <Outer $isSafari={isSafari}>
                            <Inner type="application/pdf" data={data.content.document}>
                                {/* Using `object` to display the PDF embed, else fallback to iframe or notice the user */}
                                <iframe src={data.content.document} title="PDF" loading="lazy">
                                    <Text><Trans i18nKey="blocks.pdfviewer_fallback_notice">Doesn't the PDF viewer work? View the PDF <StyledLink to={data.content.document} target="_blank" rel="noreferrer">here</StyledLink>.</Trans></Text>
                                </iframe>
                            </Inner>
                            <Absolute to={data.content.document} target="_blank" rel="noreferrer" title={t('blocks.pdfviewer_new_tab_notice')}><StyledExternalLink /></Absolute>
                        </Outer>
                    </Container>
                </Section>
            </StyledPFViewer>

            <MobileBanner>
                <Banner data={mobileNotice} />
            </MobileBanner>
        </>
    );
}