// react-animate-height with changes for auto height transition
import React, {useEffect, useRef, useState} from 'react';

const isNumber = (n) => {
    const number = parseFloat(n);
    return !isNaN(number) && isFinite(number);
}

const isPercentage = (height) => {
    return (
        typeof height === 'string' &&
        height[height.length - 1] === '%' &&
        isNumber(height.substring(0, height.length - 1))
    );
}

const hideContent = (element, height) => {
    if (height === 0 && element?.style) {
        element.style.display = 'none';
    }
}

const showContent = (element, height) => {
    if (height === 0 && element?.style) {
        element.style.display = '';
    }
}

const ANIMATION_STATE_CLASSES = {
    animating: 'animating',
    animatingUp: 'animating--up',
    animatingDown: 'animating--down',
    animatingToHeightZero: 'animating--to-height-zero',
    animatingToHeightAuto: 'animating--to-height-auto',
    animatingToHeightSpecific: 'animating--to-height-specific',
    static: 'static',
    staticHeightZero: 'static--height-zero',
    staticHeightAuto: 'static--height-auto',
    staticHeightSpecific: 'static--height-specific',
};

function getStaticStateClasses(animationStateClasses, height) {
    return [
        animationStateClasses.static,
        height === 0 && animationStateClasses.staticHeightZero,
        typeof height === 'number' && height > 0
            ? animationStateClasses.staticHeightSpecific
            : null,
        height === 'auto' && animationStateClasses.staticHeightAuto,
    ].filter((v) => v).join(' ');
}

const propsToOmitFromDiv = [
    'animateOpacity',
    'animationStateClasses',
    'applyInlineTransitions',
    'children',
    'className',
    'contentClassName',
    'contentRef',
    'delay',
    'duration',
    'easing',
    'height',
    'onHeightAnimationEnd',
    'onHeightAnimationStart',
    'style',
];

export const NavBarAnimateHeight = React.forwardRef((componentProps, ref) => {
    const {
        animateOpacity = false,
        animationStateClasses = {},
        applyInlineTransitions = true,
        children,
        className = '',
        contentClassName,
        delay: userDelay = 0,
        duration: userDuration = 300,
        easing = 'ease',
        height,
        onHeightAnimationEnd,
        onHeightAnimationStart,
        style,
        contentRef,
    } = componentProps;

    const divProps = {...componentProps};
    propsToOmitFromDiv.forEach((propKey) => {
        delete divProps[propKey];
    });

    const prevHeight = useRef(height);
    const contentElement = useRef(null);
    const animationClassesTimeoutID = useRef();
    const timeoutID = useRef();
    const stateClasses = useRef({...ANIMATION_STATE_CLASSES, ...animationStateClasses});
    const isBrowser = typeof window !== 'undefined';
    const prefersReducedMotion = useRef(isBrowser && window.matchMedia ? window.matchMedia('(prefers-reduced-motion)').matches : false);
    const delay = prefersReducedMotion.current ? 0 : userDelay;
    const duration = prefersReducedMotion.current ? 0 : userDuration;
    let initHeight = height;
    let initOverflow = 'visible';

    if (typeof height === 'number') {
        initHeight = height < 0 ? 0 : height;
        initOverflow = 'hidden';
    } else if (isPercentage(initHeight)) {
        initHeight = height === '0%' ? 0 : height;
        initOverflow = 'hidden';
    }

    const [currentHeight, setCurrentHeight] = useState(initHeight);
    const [overflow, setOverflow] = useState(initOverflow);
    const [useTransitions, setUseTransitions] = useState(false);
    const [animationStateClassNames, setAnimationStateClassNames] =
    useState(getStaticStateClasses(stateClasses.current, height));

    useEffect(() => {
        hideContent(contentElement.current, currentHeight);
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (height !== prevHeight.current && contentElement.current) {
            showContent(contentElement.current, prevHeight.current);

            contentElement.current.style.overflow = 'hidden';
            const contentHeight = contentElement.current.offsetHeight;
            contentElement.current.style.overflow = '';

            const totalDuration = duration + delay;

            let newHeight;
            let timeoutHeight;
            let timeoutOverflow = 'hidden';
            let timeoutUseTransitions;

            const isCurrentHeightAuto = prevHeight.current === 'auto';

            if (typeof height === 'number') {
                newHeight = height < 0 ? 0 : height;
                timeoutHeight = newHeight;
            } else if (isPercentage(height)) {
                newHeight = height === '0%' ? 0 : height;
                timeoutHeight = newHeight;
            } else {
                newHeight = contentHeight;
                timeoutHeight = 'auto';
                timeoutOverflow = undefined;
            }

            if (isCurrentHeightAuto) {
                timeoutHeight = newHeight;
                newHeight = contentHeight;
            }

            const newAnimationStateClassNames = [
                stateClasses.current.animating,
                (prevHeight.current === 'auto' || height < prevHeight.current) &&
                stateClasses.current.animatingUp,
                (height === 'auto' || height > prevHeight.current) &&
                stateClasses.current.animatingDown,
                timeoutHeight === 0 && stateClasses.current.animatingToHeightZero,
                timeoutHeight === 'auto' &&
                stateClasses.current.animatingToHeightAuto,
                typeof timeoutHeight === 'number' && timeoutHeight > 0
                    ? stateClasses.current.animatingToHeightSpecific
                    : null,
            ].filter((v) => v).join(' ');

            const timeoutAnimationStateClasses = getStaticStateClasses(
                stateClasses.current,
                timeoutHeight
            );

            setCurrentHeight(newHeight);
            setOverflow('hidden');
            setUseTransitions(!isCurrentHeightAuto);
            setAnimationStateClassNames(newAnimationStateClassNames);

            clearTimeout(timeoutID.current);
            clearTimeout(animationClassesTimeoutID.current);

            if (isCurrentHeightAuto) {
                timeoutUseTransitions = true;

                timeoutID.current = setTimeout(() => {
                    setCurrentHeight(timeoutHeight);
                    setOverflow(timeoutOverflow);
                    setUseTransitions(timeoutUseTransitions);
                    onHeightAnimationStart?.(timeoutHeight);
                }, 50);

                animationClassesTimeoutID.current = setTimeout(() => {
                    setUseTransitions(false);
                    setAnimationStateClassNames(timeoutAnimationStateClasses);
                    hideContent(contentElement.current, timeoutHeight);
                    onHeightAnimationEnd?.(timeoutHeight);
                }, totalDuration);
            } else {
                onHeightAnimationStart?.(newHeight);

                timeoutID.current = setTimeout(() => {
                    setCurrentHeight(timeoutHeight);
                    setOverflow(timeoutOverflow);
                    setUseTransitions(false);
                    setAnimationStateClassNames(timeoutAnimationStateClasses);

                    if (height !== 'auto') {
                        hideContent(contentElement.current, newHeight);
                    }
                    onHeightAnimationEnd?.(newHeight);
                }, totalDuration);
            }
        }

        prevHeight.current = height;

        return () => {
            clearTimeout(timeoutID.current);
            clearTimeout(animationClassesTimeoutID.current);
        };
        // eslint-disable-next-line
    }, [height]);

    const componentStyle = {...style, height: currentHeight, overflow: overflow || style?.overflow};

    if (useTransitions && applyInlineTransitions) {
        componentStyle.transition = `height ${duration}ms ${easing} ${delay}ms`;

        if (style?.transition) {
            componentStyle.transition = `${style.transition}, ${componentStyle.transition}`;
        }

        componentStyle.WebkitTransition = componentStyle.transition;
    }

    const contentStyle = {};

    if (animateOpacity) {
        contentStyle.transition = `opacity ${duration}ms ${easing} ${delay}ms`;
        contentStyle.WebkitTransition = contentStyle.transition;

        if (currentHeight === 0) {
            contentStyle.opacity = 0;
        }
    }

    const hasAriaHiddenProp = typeof divProps['aria-hidden'] !== 'undefined';
    const ariaHidden = hasAriaHiddenProp ? divProps['aria-hidden'] : height === 0;

    return (
        <div ref={ref} className={`${animationStateClassNames} ${className}`} aria-hidden={ariaHidden} style={componentStyle} {...divProps}>
            <div
                className={contentClassName}
                style={contentStyle}
                ref={(el) => {
                    contentElement.current = el;

                    if (contentRef) {
                        contentRef.current = el;
                    }
                }}
            >
                {children}
            </div>
        </div>
    );
});