import {sendCustomEvent} from "./base";

const EVENT_NAME = "navigation_click";

export const navigateEvent = (category, label) => {
    sendCustomEvent(EVENT_NAME, category, "Navigate via topnav", label);
}

export const navigateFooterEvent = (category, label) => {
    sendCustomEvent(EVENT_NAME, category, "Navigate via footer", label);
}