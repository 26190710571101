import {useState, useRef} from 'react';

export const useToggleMenu = () => {
    const [isOpen, setIsOpen] = useState(false);
    const touchActionStyle = useRef("");
    const overflowStyle = useRef("");

    const openMenu = () => {
        setIsOpen(true);
        touchActionStyle.current = document.body.style.touchAction;
        document.body.style.touchAction = "none";
        overflowStyle.current = document.body.style.overflow;
        document.body.style.overflow = "hidden";
    }

    const closeMenu = () => {
        setIsOpen(false);
        document.body.style.touchAction = touchActionStyle.current;
        document.body.style.overflow = overflowStyle.current;
    }

    return { isOpen, openMenu, closeMenu };
}