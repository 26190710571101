import {useLocation} from 'react-router-dom';
import {Helmet} from 'react-helmet-async';
import {Blocks} from '../../components/Blocks/Blocks';

const BlocksPage = ({data}) => {
    const {pathname} = useLocation();

    return (
        <>
            <Helmet>
                <title>Pollinator Academy &mdash; {data?.title}</title>
                <link rel="canonical" href={`https://pollinatoracademy.eu${pathname}`} />
            </Helmet>

            <Blocks data={data?.blocks} />
        </>
    );
}

export default BlocksPage;