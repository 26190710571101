import styled from 'styled-components';
import React, {useState} from 'react';
import { ReactComponent as Close } from '../../assets/icons/close.svg';
import {NavBarAccordion} from './NavBarAccordion';
import {LogoBox, MenuButton, MenuText, StyledLogo} from './NavBar';
import {navigateEvent} from '../../events/navigation';
import {NavBarLanguage} from './NavBarLanguage';
import {useCurrentLangParam} from '../../hooks/useCurrentLangParam';
import {useTranslation} from 'react-i18next';

const StyledNavBarHamburger = styled.div`
    position: fixed;
    top: 0;
    right: ${props => props.$isOpen ? '0' : '100%'};
    background-color: var(--color-dark-blue);
    min-height: 100vh;
    height: 100%;

    @supports (min-height: -webkit-fill-available) {
        min-height: -webkit-fill-available;
    }
    
    width: 100%;
    z-index: 100;
    transition: right .2s ease;
`;

const Inner = styled.div`
    overflow-y: auto;
    padding: 0 20px 200px 20px;
    height: 100%;
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 58px;
`;

export const StyledClose = styled(Close)`
    width: 28px;
    height: 28px;
`;

const NavBarHamburger = ({data, isOpen, closeMenu}) => {
    const { t } = useTranslation();
    const {currentLang} = useCurrentLangParam();
    const [activeId, setActiveId] = useState(null);

    const closeMobileMenu = () => {
        closeMenu();
        setActiveId(null);
    }

    const onShow = (item) => {
        setActiveId(activeId === item.id ? null : item.id)
    }

    return (
        <StyledNavBarHamburger $isOpen={isOpen}>
            <Inner>
                <Header>
                    <LogoBox to={currentLang ? `/${currentLang}` : '/'} onClick={() => {closeMobileMenu(); navigateEvent("Logo", "Home")}}>
                        <StyledLogo />
                    </LogoBox>
                    <MenuButton onClick={closeMobileMenu}>
                        <StyledClose />
                        <MenuText $isOpen={isOpen}>{t('navbar.menu')}</MenuText>
                    </MenuButton>
                </Header>

                {data?.map(item => (
                    <NavBarAccordion key={item.id} item={item} toggleMenu={closeMobileMenu} isActive={activeId === item.id} setActiveId={setActiveId} onShow={() => onShow(item)}/>
                ))}

                <NavBarLanguage location="hamburger" closeMobileMenu={closeMobileMenu} />
            </Inner>
        </StyledNavBarHamburger>
    );
}

export default NavBarHamburger;