import styled from 'styled-components';
import {Text} from '../Text/Text';
import checkboxActive from '../../assets/icons/checkbox-active.svg';
import {disableFilterEvent, enableFilterEvent} from '../../events/knowledgeCenter';
import {parseItalic} from '../../helpers/parseItalic';

export const Label = styled(Text).attrs({
    as: "label",
    $small: true,
})`
    cursor: pointer;
`;

export const Checkbox = styled.label`
    display: flex;
    gap: 15px;
    align-items: center;
    cursor: pointer;
    
    &:not(:last-of-type) {
        margin-bottom: 10px;
    }
`;

export const CheckboxInput = styled.input`
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 22px;
    height: 22px;
    flex-shrink: 0;
    background-color: var(--color-white);
    border: 1px solid var(--color-stroke);
    border-radius: 4px;
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;

    &:checked {
        border: 1px solid var(--color-dark-blue);
        content: url(${checkboxActive});
    }

    &:checked + ${Label} {
        font-weight: var(--fw-semi-bold);
    }
    
    &:disabled {
        opacity: .65;
    }
    
    @media screen and (max-width: 768px) {
        width: 18px;
        height: 18px;
    }
`;

export const FilterCheckbox = ({filter, toggleFn}) => {
    const handleInputChange = (dataId, itemId, checkedBool) => {
        toggleFn(dataId, itemId, checkedBool);
    }

    return (
        <>
            {filter.filterItems.map(item => (
                <Checkbox key={item.id} htmlFor={item.title}>
                    <CheckboxInput className={item.active} type='checkbox' id={item.title} name={item.title} checked={item.active ?? false} onChange={(e) => {handleInputChange(filter.id, item.id, e.target.checked); e.target.checked ? enableFilterEvent(filter.titleEn, item.titleEn) : disableFilterEvent(filter.titleEn, item.titleEn)}}/>
                    <Label htmlFor={item.title}>{parseItalic(item.title)}</Label>
                </Checkbox>
            ))}
        </>
    );
}