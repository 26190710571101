import {useLoaderData} from 'react-router-dom';
import BlocksPage from '../BlocksPage/BlocksPage';
import KnowledgeCenterDetailPage from '../KnowledgeCenterDetailPage/KnowledgeCenterDetailPage';
import LoginPage from '../LoginPage/LoginPage';
import Cookies from 'js-cookie';

export async function dynamicPageLoader({request, params}) {
    const url = new URL(request.url);
    const lang = url.searchParams.get("lang");
    const accessToken = Cookies.get('pollinator-academy_token');

    const res = await fetch(`${process.env.REACT_APP_API_BASE_URL}/v1/dynamic-page?url=${params["*"]}${lang ? `&lang=${lang}` : ''}`, {
        ...(Boolean(accessToken) && {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        }),
        signal: request.signal
    });

    if(!res.ok) {
        // If status === 401, access token is invalid so clear it and throw to error boundary
        if(res.status === 401) {
            Cookies.remove('pollinator-academy_token');
            throw new Response("Not authorized", { status: 401 });
        }
        throw new Response("Page is not found", { status: 404 });
    }

    const data = await res.json();

    return {data}
}

const DynamicPage = () => {
    const {data} = useLoaderData();

    switch(data?.pageType) {
        case "trainer-portal-page":
        case "dynamic-page":
            return <BlocksPage data={data} />
        case "knowledgecenter-page-item":
            return <KnowledgeCenterDetailPage data={data} />
        case "login-page":
            return <LoginPage data={data} />
        default:
            throw new Response("Page is not found", { status: 404 });
    }
}

export default DynamicPage;