import {Blocks} from '../../components/Blocks/Blocks';
import {Helmet} from 'react-helmet-async';
import {LoginForm} from '../../blocks/Form/LoginForm';
import {Navigate, useLocation, useRouteLoaderData, useSearchParams} from 'react-router-dom';
import Cookies from 'js-cookie';

const LoginPage = ({data}) => {
    const {pathname} = useLocation();
    const {footerData} = useRouteLoaderData("root");
    const [searchParams,] = useSearchParams();
    const isCMSPreview = searchParams.get("CMSPreview") === "1";

    // If user has an accessToken, navigate to the trainerPortal page
    if(Boolean(Cookies.get('pollinator-academy_token')) && !isCMSPreview) {
        let redirectUrl = footerData?.trainerPortal ?? "/";
        if(isCMSPreview) redirectUrl = `${redirectUrl}?CMSPreview=1`;

        return <Navigate to={redirectUrl} replace={true} />
    }

    return (
        <>
            <Helmet>
                <title>Pollinator Academy &mdash; {data?.title}</title>
                <link rel="canonical" href={`https://pollinatoracademy.eu${pathname}`} />
            </Helmet>

            <Blocks data={data?.blocks} />

            <LoginForm />
        </>
    );
}

export default LoginPage;