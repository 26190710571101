import {useTranslation} from 'react-i18next';
import {Link, useLocation, useNavigate, useRouteLoaderData} from 'react-router-dom';
import styled from 'styled-components';
import {ReactComponent as ArrowLeft} from '../../assets/icons/arrow-left.svg';

const StyledArrowLeft = styled(ArrowLeft)`
    width: 30px;
    height: 30px;
    flex-shrink: 0;
    background-color: ${({$white}) => $white ? 'var(--color-yellow)' : 'var(--color-dark-blue)'};
    border-radius: 50%;
    padding: 4px;
    path { fill: ${({$white}) => $white ? 'var(--color-dark-blue)' : 'var(--color-white)'}; }
`;

const StyledBackButton = styled(Link)`
    border: none;
    background: none;
    color: ${({$white}) => $white ? 'var(--color-white)' : 'var(--color-dark-blue)'};
    display: inline-flex;
    gap: 10px;
    align-items: center;
    cursor: pointer;
    font-weight: var(--fw-semi-bold);
    font-size: var(--fs-button-default);
    text-decoration: none;
    line-height: 1.5;
    max-width: fit-content;
    padding: 0;
    text-align: left;

    &:hover {
        ${StyledArrowLeft} {
            background-color: ${({$white}) => $white ? 'var(--color-light-blue)' : 'var(--color-dark-blue-80)'};
        }
    }
    
    @media screen and (max-width: 768px) {
        font-size: var(--fs-mobile-button-default);
    }
`;

export const BackButton = ({location, ...props}) => {
    const {t} = useTranslation();
    const {footerData} = useRouteLoaderData("root");
    const {state} = useLocation();
    const navigate = useNavigate();

    return (
        <>
            {state?.fromInternalPage ? (
                <StyledBackButton as="button" title={t('knowledgeCenter.detail_btn_back')} onClick={() => navigate(-1)} {...props}><StyledArrowLeft {...props} /> {t('knowledgeCenter.detail_btn_back')}</StyledBackButton>
            ) : (
                <StyledBackButton to={location === 'trainer-portal' ? footerData?.trainerPortal : footerData?.knowledgecenter} {...props}><StyledArrowLeft {...props} /> {t('knowledgeCenter.detail_btn_back')}</StyledBackButton>
            )}
        </>
    );
}