import {Container} from '../Utils/Utils';
import {Heading2, Heading4, Heading6} from '../Heading/Heading';
import styled from 'styled-components';
import {ShareButton} from '../ShareButton/ShareButton';
import {HTMLText} from '../Text/Text';
import {Fragment} from 'react';
import {LabelsRow} from '../LabelsRow/LabelsRow';
import {Button} from '../Button/Button';
import {BackButton} from '../BackButton/BackButton';

const StyledKnowledgeCenterHeader = styled.section`
    padding-top: 130px;
    ${({$paddingBottom}) => $paddingBottom && 'padding-bottom: 80px;'};

    @media screen and (max-width: 768px) {
        padding-top: 110px;
        ${({$paddingBottom}) => $paddingBottom && 'padding-bottom: 60px;'};
    }
    
    @media screen and (max-width: 475px) {
        padding-top: 90px;
    }
`;

const Buttons = styled.div`
    padding-bottom: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    
    @media screen and (max-width: 768px) {
        padding-bottom: 30px;
    }

    @media screen and (max-width: 475px) {
        padding-bottom: 20px;
    }
    
    @media screen and (max-width: 320px) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 10px;
    }
`;

const Flex = styled.div`
    display: flex;
    gap: 80px;
    
    @media screen and (max-width: 1024px) {
        gap: 40px;
        flex-direction: column;
    }
`;

const Box = styled.article`
    flex: 1 1 0;
    
    ${Heading2}, ${Heading4} { margin-bottom: 10px; }
    ${Heading6} { margin-top: 15px; }
`;

const Abstract = styled(HTMLText)`
    border-top: 1px solid var(--color-stroke);
    margin-top: 20px;
    padding-top: 20px;
`;

const Image = styled.img`
    display: block;
    width: 100%;
    height: auto;
    object-fit: cover;
`;

const ButtonWrapper = styled.div`
    margin-top: 40px;
    
    @media screen and (max-width: 768px) {
        margin-top: 20px;
    }
`;

export const KnowledgeCenterHeader = ({data}) => {
    const infoObj = {
        ...(data?.author && {"author": data?.author}),
        ...(data?.displayPublicationDate && {"displayPublicationDate": data?.displayPublicationDate}),
        ...(data?.ISSN && {"ISSN": `ISSN: ${data?.ISSN}`}),
        ...(data?.ISBN && {"ISBN": `ISBN: ${data?.ISBN}`}),
        ...(data?.DOI && {"DOI": `DOI: ${data?.DOI}`}),
    };

    return (
        <StyledKnowledgeCenterHeader $paddingBottom={data?.blocks?.length === 0}>
            <Container>
                <Buttons>
                    <BackButton location="knowledge-center" />

                    <ShareButton slug={data.slug} />
                </Buttons>

                <Flex>
                    <Box>
                        <Heading2>{data.title}</Heading2>
                        {data.subtitle && <Heading4>{data.subtitle}</Heading4>}
                        {Object.keys(infoObj)?.length > 0 &&
                            <Heading6>
                                {Object.entries(infoObj).map(([key, value], index, {length}) => (
                                    <Fragment key={index}>
                                        {value}{length - 1 !== index && ", "}
                                    </Fragment>
                                ))}
                            </Heading6>
                        }
                        <LabelsRow data={data} />
                        {data.description && <Abstract dangerouslySetInnerHTML={{ __html: data.description }} $preWrap />}
                        {data.detailLink &&
                            <ButtonWrapper>
                                <Button to={data.detailLink} type={data.detailLinkType} $variant="yellow">{data.detailLinkLabel}</Button>
                            </ButtonWrapper>
                        }
                    </Box>
                    {data.image &&
                        <Box>
                            <Image src={data.image} alt={data.title} />
                        </Box>
                    }
                </Flex>
            </Container>
        </StyledKnowledgeCenterHeader>
    );
}