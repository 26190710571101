import styled from 'styled-components';
import {NavLink} from 'react-router-dom';
import {useRef, useState} from 'react';
import {Container} from '../Utils/Utils';
import {Text} from '../Text/Text';
import {navigateEvent} from '../../events/navigation';
import {useCurrentLangParam} from '../../hooks/useCurrentLangParam';
import {NavBarHeightTransition} from './NavBarHeightTransition';

const StyledNavBarFlyOut = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    transform: ${({$open}) => $open ? 'transform: translateY(0)' : 'translateY(-100%)'};
    opacity: ${({$open}) => $open ? 1 : 0};
    width: 100%;
    z-index: -1;
    background-color: var(--color-dark-blue);
    padding: 120px 0 60px 0;
    will-change: transform;
    transition: transform .3s linear, opacity .3s ease;
    ${({$open}) => $open && 'box-shadow: var(--box-shadow);'};
`;

const Item = styled(NavLink)`
    display: flex;
    align-items: center;
    gap: 16px;
    text-decoration: none;
    padding-right: 40px;
`;

const IconBox = styled.div`
    display: flex;
    align-items: center;
`;

const TextBox = styled.div``;

const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(${({$columns}) => $columns}, 1fr);
    gap: 40px;
    opacity: ${({$shouldShowGrid}) => $shouldShowGrid ? 1 : 0};
    transition: opacity .2s ease-in-out;

    // If there are 2 columns, give a border-right to each first item in the row
    ${({$columns}) => $columns === 2 && `
        ${Item}:nth-child(2n-1) {
            border-right: 1px solid rgba(255, 255, 255, 0.1);
        }
    `}

    // If there are 3 columns, give a border-right to each first and second item in the row
    ${({$columns}) => $columns === 3 && `
        ${Item}:not(:last-child):nth-child(3n-2), ${Item}:not(:last-child):nth-child(3n-1) {
            border-right: 1px solid rgba(255, 255, 255, 0.1);
        }
    `}

    // If there are 4 columns, give a border-right to each first, second, and third item in the row
    ${({ $columns }) => $columns === 4 && `
        ${Item}:not(:last-child):nth-child(4n-3), ${Item}:not(:last-child):nth-child(4n-2), ${Item}:not(:last-child):nth-child(4n-1) {
            border-right: 1px solid rgba(255, 255, 255, 0.1);
        }
    `}
`;

const SubNavigationLink = styled.p`
    color: var(--color-white);
    font-family: var(--font-headers);
    font-weight: var(--fw-bold);
    font-size: var(--fs-sub-navigation-link);
    margin: 0;

    ${Item}:hover &, ${Item}.active & {
        text-decoration: underline;
    }
`;

const Icon = styled.img`
    display: block;
    width: 48px;
    height: 48px;
    object-fit: contain;
    transition: .2s ease opacity;
`;

const HiddenIcon = styled.img`
    opacity: 0;
    width: 0;
    height: 0;
`;

const IconWrapper = styled.div`
    width: 48px;
    height: 48px;
    
     ${Icon} {
         opacity: ${({$load}) => $load ? 1 : 0};
     }
`;

export const NavBarFlyOut = ({items, open, setOpen, parent}) => {
    const {currentLang} = useCurrentLangParam();
    const [load, setLoad] = useState(false); // Smooth loading (when Image is loaded)
    const flyOutNodeRef = useRef(null);

    return (
        <StyledNavBarFlyOut ref={flyOutNodeRef} $open={open}>
            <Container>
                <NavBarHeightTransition>
                    <Grid $columns={items?.length <= 4 ? items.length : 4} $shouldShowGrid={open}>
                        {items?.map(item => (
                            <Item key={item.id} to={item.url + currentLang} onClick={() => {setOpen(false); navigateEvent(parent, item.titleEn)}}>
                                {item.icon &&
                                    <IconBox>
                                        <IconWrapper $load={load}>
                                            {load && <Icon src={item.icon} alt="" />}
                                        </IconWrapper>
                                        <HiddenIcon src={item.icon} alt="" onLoad={() => setLoad(true)} />
                                    </IconBox>
                                }
                                <TextBox>
                                    <SubNavigationLink>{item.title}</SubNavigationLink>
                                    {item.subtitle && <Text $white>{item.subtitle}</Text>}
                                </TextBox>
                            </Item>
                        ))}
                    </Grid>
                </NavBarHeightTransition>
            </Container>
        </StyledNavBarFlyOut>
    )
}