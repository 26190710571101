import styled from 'styled-components';
import {Heading2, Heading3} from '../../components/Heading/Heading';
import {HTMLText} from '../../components/Text/Text';
import {ConditionalWrap, Container, Section} from '../../components/Utils/Utils';
import {Button} from '../../components/Button/Button';
import {parseItalic} from '../../helpers/parseItalic';

const FlexOrColumn = styled.div`
    ${({$flex}) => $flex && (`
        display: flex;
        gap: 80px;
        
        @media screen and (max-width: 1024px) {
            gap: 40px;
        }
    
        @media screen and (max-width: 768px) {
            flex-direction: column;
        }
    `)}

    ${({$alignLeft}) => $alignLeft && 'max-width: 930px;'};
`;

const TextBox = styled.article`
    flex: 1 1 0;
`;

const Buttons = styled.div`
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    margin-top: 40px;
    
    @media screen and (max-width: 768px) {
        margin-top: 20px;
    }
`;

const StyledHeading2 = styled(Heading2)`
    text-align: ${({$alignment}) => $alignment === "center" ? 'center' : 'left'};
    margin-bottom: 16px;
    ${({$alignedColumn}) => $alignedColumn && 'max-width: 930px;'};
    
    @media screen and (max-width: 768px) {
        text-align: left;
    }
`;

export const TextBlock = ({data, ...props}) => {
    if(!data?.content) { return null; }

    let isTwoColumns = data.content.numberOfColumns === "2";

    return (
        <Section id={data.hashSlug ?? undefined} $scroll={Boolean(data.hashSlug)} $backgroundColor={data.backgroundColor} $paddingTop $paddingBottom={data.isLast || (data.backgroundColor === 'lightBlue' || data.backgroundColor === 'darkBlue')} $marginTop={!data.removeSpacing && (data.backgroundColor === 'lightBlue' || data.backgroundColor === 'darkBlue')} {...props}>
            <Container $small={!isTwoColumns && (!isTwoColumns && data.content.columnAlignment !== "left")}>
                {data.content.title &&
                    <StyledHeading2
                        $alignment={data.content.titleAlignment}
                        $white={data.backgroundColor === 'darkBlue'}
                        $alignedColumn={data.content.columnAlignment === "left" && !isTwoColumns}
                    >
                        {parseItalic(data.content.title)}
                    </StyledHeading2>
                }

                <FlexOrColumn $flex={isTwoColumns} $alignLeft={data.content.columnAlignment === "left" && !isTwoColumns}>
                    <ConditionalWrap condition={isTwoColumns} wrap={children => <TextBox>{children}</TextBox>}>
                        {data.content.firstColumnTitle && (
                            data.content.title
                                ? <Heading3 $white={data.backgroundColor === 'darkBlue'}>{parseItalic(data.content.firstColumnTitle)}</Heading3>
                                : <Heading2 $white={data.backgroundColor === 'darkBlue'}>{parseItalic(data.content.firstColumnTitle)}</Heading2>
                        )}
                        {data.content.firstColumnText && <HTMLText dangerouslySetInnerHTML={{__html: data.content.firstColumnText}} $white={data.backgroundColor === 'darkBlue'} />}
                        {(data.content.firstColumnFirstLink || data.content.firstColumnSecondLink) &&
                            <Buttons>
                                {data.content.firstColumnFirstLink && <Button to={data.content.firstColumnFirstLink} type={data.content.firstColumnFirstLinkType} $variant="yellow">{data.content.firstColumnFirstLinkLabel}</Button>}
                                {data.content.firstColumnSecondLink && <Button to={data.content.firstColumnSecondLink} type={data.content.firstColumnSecondLinkType} $variant={data.backgroundColor === 'darkBlue' ? 'transparent' : 'outline'}>{data.content.firstColumnSecondLinkLabel}</Button>}
                            </Buttons>
                        }
                    </ConditionalWrap>

                    {isTwoColumns &&
                        <TextBox>
                            {data.content.secondColumnTitle && (
                                data.content.title
                                    ? <Heading3 $white={data.backgroundColor === 'darkBlue'}>{parseItalic(data.content.secondColumnTitle)}</Heading3>
                                    : <Heading2 $white={data.backgroundColor === 'darkBlue'}>{parseItalic(data.content.secondColumnTitle)}</Heading2>
                            )}
                            {data.content.secondColumnText && <HTMLText dangerouslySetInnerHTML={{__html: data.content.secondColumnText}} $white={data.backgroundColor === 'darkBlue'} />}
                            {(data.content.secondColumnFirstLink || data.content.secondColumnSecondLink) &&
                                <Buttons>
                                    {data.content.secondColumnFirstLink && <Button to={data.content.secondColumnFirstLink} type={data.content.secondColumnFirstLinkType} $variant="yellow">{data.content.secondColumnFirstLinkLabel}</Button>}
                                    {data.content.secondColumnSecondLink && <Button to={data.content.secondColumnSecondLink} type={data.content.secondColumnSecondLinkType} $variant={data.backgroundColor === 'darkBlue' ? 'transparent' : 'outline'}>{data.content.secondColumnSecondLinkLabel}</Button>}
                                </Buttons>
                            }
                        </TextBox>
                    }
                </FlexOrColumn>
            </Container>
        </Section>
    );
}