import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import ReactGA from "react-ga4";
import './i18n';

// Initialize TST and PRD properties, for DEV it's a dummy measurement tag
ReactGA.initialize(process.env.REACT_APP_ENVIRONMENT_TYPE ==='test' ? "G-MK1G3DSVWG" : process.env.REACT_APP_ENVIRONMENT_TYPE ==='production' ? "G-7XNZM2Z0X3" : "G-0000000000");

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    // <React.StrictMode>
        <App/>
    // </React.StrictMode>
);