import Cookies from 'js-cookie';
import {createContext, useContext, useState} from "react";

export const CookieBannerContext = createContext(null);

export default function CookieBannerProvider({children}) {
    const consentCookie = Cookies.get('pollinator-academy_cookie-consent');
    const allowedToLoadEmbed = Boolean(consentCookie) && consentCookie === "1";
    const [isOpen, setIsOpen] = useState(!Boolean(consentCookie));

    const openCookieBanner = () => {
        setIsOpen(true);
    };

    const closeCookieBanner = () => {
        setIsOpen(false);
    };

    return (
        <CookieBannerContext.Provider value={{
            isOpen,
            openCookieBanner,
            closeCookieBanner,
            allowedToLoadEmbed,
        }}>
            {children}
        </CookieBannerContext.Provider>
    )
}

export const useCookieBannerContext = () => useContext(CookieBannerContext);